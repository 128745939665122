import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import * as jwt from "jsonwebtoken";
import ModalFooter from "./ModalFooter";
import { closeSessionExpiredModal } from "../store/ui";
import { signin } from "../store/auth/authSlice";
import Loader from "./Loader";

const SessionExpiredModal = (props) => {
  const navigate = useNavigate();

  const adminState = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [pwd, setPwd] = useState("");

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "password_text":
        setPwd(e.target.value);
        break;

      default:
        break;
    }
  };

  const signout = () => {
    localStorage.removeItem("fvAuth");
    localStorage.removeItem("fvApp");
    localStorage.removeItem("fvAccessToken");
    dispatch(closeSessionExpiredModal());
    navigate("/signin");
  };
  const callback = ()=>{
    if (adminState.sessionExpiredSource === undefined || adminState.sessionExpiredSource === '') {      
      navigate('/admin/dashboard')          
    } else {
      
      navigate(adminState.sessionExpiredSource)
    }
  }

  const connect = () => {
    let user = {
      email: jwt.decode(localStorage.getItem("fvAccessToken")).email,
      pwd,
    };

    setPwd("");
    dispatch(closeSessionExpiredModal());
    dispatch(signin({user, callback}));
  };

  return (
    <Modal centered show={adminState.sessionExpiredModal}>
      <Modal.Body style={{ textAlign: "center" }}>
        <h1>SESSION EXPIRÉE</h1>
        <p>
          veuillez saisir le mot de passe de l'utilisateur:{" "}
          <b style={{ fontWeight: "bold" }}>
            {jwt.decode(localStorage.getItem("fvAccessToken")).email}
          </b>
        </p>

        <input
          placeholder="Mot de passe"
          type="password"
          id="password_text"
          onChange={handleInputChange}
          className="form-control"
          value={pwd}
        />

        <div style={{ marginTop: 10 }}>
          <button
            style={{ backgroundColor: "#00387B", color: "#fff" }}
            disabled={pwd === "" ? true : false}
            onClick={() => connect()}
            class="btn"
          >
            {
              adminState.loading ? 
              <Loader />
              :"Connexion"
            }
            
          </button>
          <button class="btn" onClick={() => signout()}>
            Se Déconnecter
          </button>
          {/* <button class="btn" style={{ backgroundColor: '#00387B', color: '#fff' }} onClick={() => signout()} >Connexion</button> */}
        </div>
      </Modal.Body>

      <ModalFooter />
    </Modal>
    
  );
};

export default SessionExpiredModal;
