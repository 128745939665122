import * as constants from '../constants';

export function saveUser(user, callback, source) {
    return {
        type: constants.SAVE_USER,
        user,
        callback,
        source
    }
}

export function getUsers(code, page, perPage) {

    return {
        type: constants.GET_USERS,
        code, page, perPage
    }
}

export function getDashboardElements() {
    return {
        type: constants.GET_DASHBOARD_ELEMENTS
    }
}

export function getDashboardElementsSuccess(elementsCount) {
    return {
        type: constants.GET_DASHBOARD_ELEMENTS_SUCCESS,
        elementsCount
    }
}

export function updateUser(user, callback, source) {
    return {
        type: constants.UPDATE_USER,
        user,
        callback,
        source
    }
}

export function deleteUser(userId, pwd, code, isInscrit) {
    return {
        type: constants.DELETE_USER,
        userId,
        pwd,
        code,
        isInscrit
    }
}

export function printUsers(code) {
    return {
        type: constants.PRINT_USERS,
        code
    }
}

export function printPreviewUsers(code) {
    return {
        type: constants.PRINT_PREVIEW_USERS,
        code
    }
}

export function getRefereesSuccess(referees) {
    return {
        type: constants.GET_REFEREES_SUCCESS,
        referees
    }
}

export function getCoachsSuccess(coachs) {
    return {
        type: constants.GET_COACH_SUCCESS,
        coachs
    }
}

export function getPlayersSuccess(players) {
    return {
        type: constants.GET_PLAYERS_SUCCESS,
        players
    }
}

export function saveClub(club, callback) {
    return {
        type: constants.SAVE_CLUB,
        club,
        callback
    }
}

export function updateClub(club, callback) {
    return {
        type: constants.UPDATE_CLUB,
        club,
        callback
    }
}

export function deleteClub(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_CLUB,
        deleteItemId, deleteItemPwd
    }
}

export function getClubs() {
    return {
        type: constants.GET_CLUB
    }
}

export function getClubsByLigue(ligue) {
    return {
        type: constants.GET_CLUB_BY_LIGUE,
        ligue
    }
}

export function getClubsAuxiliaire(typeClub) {
    return {
        type: constants.GET_CLUB_AUXILIAIRE,
        typeClub
    }
}

export function getClubsSuccess(clubs) {
    return {
        type: constants.GET_CLUB_SUCCESS,
        clubs
    }
}

export function getRole(code) {
    return {
        type: constants.GET_ROLE,
        code
    }
}

export function getRoleSuccess(code) {
    return {
        type: constants.GET_ROLE_SUCCESS,
        code
    }
}

export function getRoles(source) {
    return {
        type: constants.GET_ROLES,
        source
    }
}

export function getRolesSuccess(roles) {
    return {
        type: constants.GET_ROLES_SUCCESS,
        roles
    }
}

export function saveRole(role, callback) {
    return {
        type: constants.SAVE_ROLE,
        role, callback
    }
}

export function deleteRole(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_ROLE,
        deleteItemId, deleteItemPwd
    }
}

export function updateRole(role, callback) {
    return {
        type: constants.UPDATE_ROLE,
        role, callback
    }
}

export function getGrade(userId) {
    return {
        type: constants.GET_GRADE,
        userId
    }
}

export function getGradeSuccess(grade) {
    return {
        type: constants.GET_GRADE_SUCCESS,
        grade
    }
}

export function getInscrits() {
    return {
        type: constants.GET_INSCRITS
    }
}

export function getInscritSuccess(inscrits) {
    return {
        type: constants.GET_INSCRITS_SUCCESS,
        inscrits
    }
}

export function getFederationSuccess(federations) {
    return {
        type: constants.GET_FEDERATION_SUCCESS,
        federations
    }
}

export function getLeagueSuccess(leagues) {
    return {
        type: constants.GET_LEAGUE_SUCCESS,
        leagues
    }
}

export function notifySuccess(notifyMessage) {
    return {
        type: constants.NOTIFY_SUCCESS,
        notifyMessage
    }
}

export function notifyError(notifyMessage) {
    return {
        type: constants.NOTIFY_ERROR,
        notifyMessage
    }
}

export function closeNotify() {
    return {
        type: constants.CLOSE_NOTIFY
    }
}

export function getLoggedInUser() {
    return {
        type: constants.GET_LOGGED_IN_USER
    }
}

export function getLoggedInUserSuccess(loggedInUser) {
    return {
        type: constants.GET_LOGGED_IN_USER_SUCCESS,
        loggedInUser
    }
}

export function openSessionExpiredModal(source) {
    return {
        type: constants.OPEN_SESSION_EXPIRED_MODAL,
        source
    }
}

export function closeSessionExpiredModal() {
    return {
        type: constants.CLOSE_SESSION_EXPIRED_MODAL
    }
}

export function showPasswordModal(deleteItemId,
    deleteSource) {
    return {
        type: constants.SHOW_PASSWORD_MODAL,
        deleteItemId,
        deleteSource
    }
}

export function hidePasswordModal() {
    return {
        type: constants.HIDE_PASSWORD_MODAL
    }
}

export function getRegions() {
    return {
        type: constants.GET_REGIONS
    }
}

export function getRegionSuccess(regions) {
    return {
        type: constants.GET_REGIONS_SUCCESS,
        regions
    }
}

export function getDepartements(regionId) {
    return {
        type: constants.GET_DEPARTEMENTS,
        regionId
    }
}

export function getDepartementSuccess(departements) {
    return {
        type: constants.GET_DEPARTEMENTS_SUCCESS,
        departements
    }
}

export function getArrondissements(departementId) {
    return {
        type: constants.GET_ARRONDISSEMENTS,
        departementId
    }
}

export function resetArrondissements() {
    return {
        type: constants.RESET_ARRONDISSEMENTS
    }
}

export function getArrondissementSuccess(arrondissements) {
    return {
        type: constants.GET_ARRONDISSEMENTS_SUCCESS,
        arrondissements
    }
}

export function getLigueUsers(ligueType) {
    return {
        type: constants.GET_LIGUE_USERS,
        ligueType
    }
}

export function getLigueUserSuccess(ligueUsers) {
    return {
        type: constants.GET_LIGUE_USERS_SUCCESS,
        ligueUsers
    }
}

export function getClubsAdmin() {
    return {
        type: constants.GET_CLUB_ADMIN
    }
}

export function getClubsAdminSuccess(clubAdmins) {
    return {
        type: constants.GET_CLUB_ADMIN_SUCCESS,
        clubAdmins
    }
}

export function getSubRoles(role) {
    return {
        type: constants.GET_SUB_ROLES,
        role
    }
}

export function getSubRolesSuccess(subRoles) {
    return {
        type: constants.GET_SUB_ROLES_SUCCESS,
        subRoles
    }
}

export function saveMatch(match, callback) {
    return {
        type: constants.SAVE_MATCH,
        match, callback
    }
}

export function getMatchs() {
    return {
        type: constants.GET_MATCHS,
    }
}

export function getMatchFormData() {
    return {
        type: constants.GET_MATCH_FORM_DATA,
    }
}

export function getMatchsByTournois(tournoisId) {
    return {
        type: constants.GET_MATCHS_BY_TOURNOIS,
        tournoisId
    }
}

export function deleteMatch(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_MATCH,
        deleteItemId, deleteItemPwd
    }
}

export function updateMatch(match, callback) {
    return {
        type: constants.UPDATE_MATCH,
        match, callback
    }
}

export function getMatchSuccess(matchs) {
    return {
        type: constants.GET_MATCH_SUCCESS,
        matchs
    }
}

export function saveTournois(tournois, callback) {
    return {
        type: constants.SAVE_TOURNOIS,
        tournois, callback
    }
}

export function getTournois() {
    return {
        type: constants.GET_TOURNOIS
    }
}

export function getTournoisAuxiliaire(typeTournois) {
    return {
        type: constants.GET_TOURNOIS_AUXILIAIRE,
        typeTournois
    }
}

export function deleteTournois(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_TOURNOIS,
        deleteItemId, deleteItemPwd
    }
}

export function updateTournois(tournois, callback) {
    return {
        type: constants.UPDATE_TOURNOIS,
        tournois, callback
    }
}

export function getTournoisSuccess(tournois) {
    return {
        type: constants.GET_TOURNOIS_SUCCESS,
        tournois
    }
}

export function saveMatriculeCode(matriculeCode, callback) {
    return {
        type: constants.SAVE_MATRICULE_CODE,
        matriculeCode, callback
    }
}

export function getMatriculeCodes() {
    return {
        type: constants.GET_MATRICULE_CODES,
    }
}

export function getMatriculeCodesSuccess(matriculeCodes) {
    return {
        type: constants.GET_MATRICULE_CODE_SUCCESS,
        matriculeCodes
    }
}

export function deleteMatriculeCode(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_MATRICULE_CODE,
        deleteItemId, deleteItemPwd
    }
}

export function updateMatriculeCode(matriculeCode, callback) {
    return {
        type: constants.UPDATE_MATRICULE_CODE,
        matriculeCode, callback
    }
}

export function savePermission(permission, callback) {
    return {
        type: constants.SAVE_PERMISSION,
        permission, callback
    }
}

export function getPermissions() {
    return {
        type: constants.GET_PERMISSIONS,
    }
}

export function getPermissionsSuccess(permissions) {
    return {
        type: constants.GET_PERMISSION_SUCCESS,
        permissions
    }
}

export function deletePermission(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_PERMISSION,
        deleteItemId, deleteItemPwd
    }
}

export function updatePermission(permission, callback) {
    return {
        type: constants.UPDATE_PERMISSION,
        permission, callback
    }
}

export function saveRolePermission(rolePermission, callback) {
    return {
        type: constants.SAVE_ROLE_PERMISSION,
        rolePermission, callback
    }
}

export function getRolePermissions(roleCode) {
    return {
        type: constants.GET_ROLE_PERMISSIONS,
        roleCode
    }
}

export function getRolePermissionsSuccess(rolePermissions) {
    return {
        type: constants.GET_ROLE_PERMISSION_SUCCESS,
        rolePermissions
    }
}

export function deleteRolePermission(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_ROLE_PERMISSION,
        deleteItemId, deleteItemPwd
    }
}

export function updateRolePermission(rolePermission, callback) {
    return {
        type: constants.UPDATE_ROLE_PERMISSION,
        rolePermission, callback
    }
}

export function showSignatureModal() {
    return {
        type: constants.SHOW_SIGNATURE_MODAL,
        
    }
}

export function hideSignatureModal() {
    return {
        type: constants.HIDE_SIGNATURE_MODAL,
        
    }
}

export function setSignature(signature) {
    return {
        type: constants.SET_SIGNATURE,
        signature
    }
}

export function savePlayerTransfer(playerTransfer, callback) {
    return {
        type: constants.SAVE_PLAYER_TRANSFER,
        playerTransfer, callback
    }
}

export function getPlayerTransfers(playerId) {
    return {
        type: constants.GET_PLAYER_TRANSFERS,
        playerId
    }
}

export function getPlayerTransfersSuccess(playerTransfers) {
    return {
        type: constants.GET_PLAYER_TRANSFER_SUCCESS,
        playerTransfers
    }
}

export function deletePlayerTransfer(deleteItemId,
    deleteItemPwd) {
    return {
        type: constants.DELETE_PLAYER_TRANSFER,
        deleteItemId, deleteItemPwd
    }
}

export function updatePlayerTransfer(playerTransfer, callback) {
    return {
        type: constants.UPDATE_PLAYER_TRANSFER,
        playerTransfer, callback
    }
}

// tormanent module 
// registering the function to store tormanent info
export const registerTormanent = (tournois) => {

}