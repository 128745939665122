import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Modal } from "react-bootstrap";
import { PDFExport } from "@progress/kendo-react-pdf";
// import MatchCalendaCard from "./matchCalendaCard";
// import { formatDate, getDay, ymd, time } from "../utilities/dateUtils";
import bg from "../../../assets/images/bg3.jpeg";
import { formatDate, getDay, ymd, time } from "../../../utilities/dateUtils";
import { getMatchAsync } from "../../../store/matchs/matchSlice";
import { closeNotify } from "../../../store/ui";
import { BackButton, Loader } from "../../../components";
import dayjs from "dayjs";

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const MatchAffiche = ({ match }) => {
  return (
    <div style={{ width: "100%", marginTop: "1rem" }}>
      <div className="flex-row-between" style={{ width: "100%" }}>
        <div className="flex-col-center" style={{ width: "30%" }}>
          <div
            style={{
              height: "50px",
              width: "50px",

              borderRadius: "50px",
              background: match?._participantOne?.picture
                ? `url(${match?._participantOne?.picture})`
                : "#fff",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
            className="flex-center">
            {!match?._participantOne?.picture ? (
              <span style={{ fontWeight: 700 }}>
                {match?._participantOne?.name
                  ?.split(" ")
                  .map((el) => el?.[0])
                  .join("")
                  .slice(0, 2)}
              </span>
            ) : null}
          </div>
          <div
            className="flex-row-center text-white"
            style={{ textAlign: "center", fontSize: "12px" }}>
            {match?._participantOne?.name}
          </div>
        </div>
        <div className="flex-col-center" style={{ width: "30%" }}>
          <span
            style={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "bold",
            }}>
            VS
          </span>
          <div
            style={{
              background: "rgb(25 160 194 / 74%)",
              padding: "0.5rem",
              borderRadius: "5px",
              color: "#fff",
            }}>
            {match?.timeMatch || time(match.dateMatch)}
          </div>
        </div>
        <div class="flex-col-center" style={{ width: "30%" }}>
          <div
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50px",
              background: match?._participantTwo?.picture
                ? `url(${match?._participantTwo?.picture})`
                : "#fff",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top center",
            }}
            className="flex-center">
            {!match?._participantTwo?.picture ? (
              <span style={{ fontWeight: 700 }}>
                {match?._participantTwo?.name
                  ?.split(" ")
                  .map((el) => el?.[0])
                  .join("")
                  .slice(0, 2)}
              </span>
            ) : null}
          </div>
          <div
            className="flex-row-center text-white"
            style={{ textAlign: "center", fontSize: "12px" }}>
            {match?._participantTwo?.name}
          </div>
        </div>
      </div>
      <div
        className="flex-row-center"
        style={{
          width: "100%",
          background: "rgb(25 160 194 / 74%)",
          marginTop: "1rem",
          padding: "0.5rem",
          color: "#fff",
          borderRadius: "5px",
        }}>
        {match?.town} - {match?.hall}
      </div>
    </div>
  );
};

const DayCard = ({ dayDate, matchList, handleSelect }) => {
  const matchDay = useMemo(() => {
    if (matchList) {
      const date = dayjs(dayDate);
    
      const matchDay = matchList.filter(
        (match) => dayjs(match.dateMatch).isSame(dayjs(date))
      );
      
      return matchDay;
    }
    return [];
  }, [dayDate, matchList]);

  return (
    <div
      style={{
        width: "15%",
        paddingBottom: "1rem",
        background: `#fff`,
        borderRadius: "5px",
        cursor: "pointer",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 1px",
      }}
      onClick={() => handleSelect(matchDay)}
      className="flex-col-center">
      <div
        style={{ background: "#19a0c2", width: "100%", paddingTop: "1rem" }}
        className="flex-row-center">
        <p style={{ fontWeight: 500, color: "#fff" }}>
          {" "}
          {weekdays[new Date(dayDate).getDay()]}{" "}
        </p>
      </div>
      <div className="flex-col-center">
        <p style={{ fontWeight: 500, color: "#000" }}>
          {" "}
          {formatDate(new Date(dayDate))}{" "}
        </p>
        <p style={{ fontSize: "14px", color: "#000" }}> nombre de match(s) </p>
        <p style={{ fontWeight: 500, color: "#000" }}> {matchDay.length} </p>
      </div>
    </div>
  );
};

const MatchCalenda = () => {
  //when navigating to this page the tormanent info and match info is also passed
  const { data, loading } = useSelector((state) => state.match);
  const [dates, setDates] = useState({});
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedWeek, setSelectedWeek] = useState("week_1");
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef();

  const [tournamentInfo, setTournamentInfo] = useState({
    auxiliaire: false,
    creationDate: "",
    end: "",
    id: "",
    name: "",
    noPhase: 0,
    organizer: "",
    salle: "",
    start: "",
    state: true,
    matches: [1, 2, 3],
  });

  useEffect(() => {
    dispatch(getMatchAsync());
  }, [dispatch]);

  const matchList = useMemo(() => {
    const list = Object.values(data || {}).filter(
      (match) => match._tournois.name === tournamentInfo.name
    );
    // console.log("list match tournament" , list)
    return list.sort((a, b) => a.matchNum - b.matchNum);
  }, [tournamentInfo.name, data]);

  useEffect(() => {
    if (!isNaN(Number(new Date(location.state.start)))) {
      const incomeData = location.state;
      const startDate = new Date(incomeData.start);
      const endDate = new Date(incomeData.end);
      const dates = {};
      let j = 1;

      while (startDate < endDate) {
        dates["week_" + j] = [];
        for (let i = 0; i < 7; i++) {
          if (Number(new Date(startDate)) <= Number(endDate)) {
            dates["week_" + j].push(ymd(new Date(startDate)));
          }
          startDate.setDate(startDate.getDate() + 1);
        }
        j = j + 1;
      }

      setDates(dates);
    }
    setTournamentInfo(location.state);
  }, [location.state]);

  const handleClose = () => setShow(false);

  const handleSelect = (el) => {
    if (el?.length === 0) {
      alert(" liste de match vide");
      return;
    }
    setSelected(el);
    setShow(true);
  };

  return (
    <div className="header pb-6">
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className=" header-body">
            <div
              className="row align-items-center py-4"
              style={{ background: `url(${bg})` }}>
              <div className="col-lg-12">
                <h6 className="h2 d-inline-block text-uppercase mb-0 text-white">
                  {tournamentInfo.name}
                  <span></span>
                </h6>
                
              </div>
              <div className="col-lg-12 align-items-center d-flex justify-content-between">
                <h6 className="h6 d-inline-block text-uppercase mb-0 text-white">
                  <span className="mr-2">
                    <i className="fa fa-volleyball-ball"></i>
                  </span>
                  {dates?.[selectedWeek] ? (
                    <>
                      WEEK {selectedWeek?.split("_")[1]}
                      <span
                        style={{
                          color: "#19a0c2",
                          marginInline: "1rem",
                        }}>
                        {" "}
                        {formatDate(
                          Number(new Date(dates?.[selectedWeek]?.[0]))
                        )}
                      </span>
                      to
                      <span
                        style={{
                          color: "#19a0c2",
                          marginInline: "1rem",
                        }}>
                        {" "}
                        {formatDate(
                          new Date(
                            dates?.[selectedWeek]?.[
                              dates?.[selectedWeek]?.length - 1
                            ]
                          )
                        )}
                      </span>
                    </>
                  ) : null}
                </h6>

                {Object.keys(dates).length !== 0 ? (
                  <div class="dropdown">
                    <button
                      class="btn btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ backgroundColor: "#19a0c2", color: "#fff" }}>
                      <i className="fa fa-calendar mr-1"></i>calenda
                    </button>
                    <div
                      class="dropdown-menu  "
                      aria-labelledby="dropdownMenuButton">
                      {Object.keys(dates).map((week, index) => (
                        <button
                          key={index}
                          className="dropdown-item"
                          onClick={() => setSelectedWeek(week)}>
                          <span> {week?.replace("_", " ")} </span>
                        </button>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              <BackButton  containerStyle={{background:"#fff"}}/>
            </div>
            {Object.keys(dates).length !== 0 ? (
              <div
                style={{
                  flexWrap: "wrap",
                  gap: "1rem",
                  marginTop: "1rem",
                }}
                className="flex-row-center">
                {dates[selectedWeek]?.map((date, index) => (
                  <DayCard
                    key={index}
                    dayDate={date}
                    matchList={matchList}
                    handleSelect={handleSelect}
                  />
                ))}

                <Modal
                  show={show}
                  size="md"
                  onHide={handleClose}
                  className="matchDayList"
                  style={{
                    background: `url(${bg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}>
                  <button
                    className="flex-center"
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      position: "fixed",
                      top: 50,
                      right: 150,
                    }}
                    onClick={() => ref?.current.save()}>
                    <i class="fa fa-download" style={{ color: "silver" }}></i>
                  </button>
                  <button
                    className="flex-center"
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 50,
                      position: "fixed",
                      top: 50,
                      right: 250,
                    }}
                    onClick={() => setShow(false)}>
                    <i class="fa fa-times" style={{ color: "silver" }}></i>
                  </button>
                  <Modal.Body style={{ height: "100vh" }}>
                    <PDFExport
                      ref={ref}
                      paperSize={selected?.length > 4 ? "A4" : null}
                      keepTogether="div">
                      <div
                        style={{
                          background: `url(${bg})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          padding: "1rem",
                        }}>
                        <div className="flex-row-between">
                          <div>
                            <span
                              className="mr-2 ml-2 flex-row"
                              style={{
                                background: `url(./assets/images/fecavolley.png)`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }}>
                              <img
                                src="/assets/images/fecavolley.png"
                                height="30"
                                alt="logo"
                              />
                            </span>
                            <span
                              style={{
                                fontSie: "14px",
                                fontweight: 300,
                                color: "#fff",
                              }}>
                              Fecavolley
                            </span>
                          </div>
                          <span
                            style={{
                              color: "#fff",
                              fontSie: "14px",
                              fontweight: 300,
                            }}>
                            Date du{" "}
                            <span style={{ marginLeft: "0.5rem" }}>
                              {" "}
                              {" " +
                                formatDate(
                                  new Date(selected?.[0]?.dateMatch)
                                )}{" "}
                            </span>
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}>
                          <span
                            style={{
                              fontSize: "20px",
                              fontWeight: 700,
                              color: "#fff",
                              marginBottom: "1rem",
                              marginTop: "1rem",
                            }}>
                            {tournamentInfo.name}{" "}
                            {`( phase ${selected?.[0]?.phase} )`}
                          </span>
                          {selected?.map((el) => (
                            <>
                              <MatchAffiche match={el} />
                            </>
                          ))}
                        </div>
                      </div>
                    </PDFExport>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              <div
                className="flex"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}>
                <h3> Aucuns Matchs </h3>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchCalenda;
