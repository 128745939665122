import React from "react";
import { createRoot } from "react-dom/client";
import "react-notifications-component/dist/theme.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
const container = document.getElementById("root");
const dataStore = store();
const root = createRoot(container)
root.render( 
    <Provider store={dataStore}>
        <App/>
    </Provider>
);
