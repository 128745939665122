import React from 'react';
import { useSelector } from "react-redux"
import useLang from "../../../hook/useLang"
import { hasPermission } from "../../../utilities/auth"
import { Link } from 'react-router-dom';

export default function PlayerMenu({row , onDelete , onTransfer}){
    const lang = useLang()
    const {user:loggedInUser } = useSelector((state) => state.auth)
    return(
        <div className="dropdown">
          <button
            className="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            {loggedInUser
              ? hasPermission(loggedInUser, ["EDIT_PLAYER"]) && (
                  <Link
                    to={{
                      pathname: `/admin/player-form/`,
                    }}
                    state={{userId:row.id}}
                    className="dropdown-item"
                  >
                    <span>
                      <i className="fa fa-edit mr-1"></i> {lang.t('edit')}
                    </span>
                  </Link>
                )
              : null}
            <div className="dropdown-divider"></div>
            <Link
              to={{
                pathname: "/admin/player-detail",
              }}
              state={{userId:row.id}}
              className="dropdown-item"
            >
              <span>
                {" "}
                <i className="fa fa-list mr-1"></i> {lang.t('detail')}
              </span>
            </Link>
            <div className="dropdown-divider"></div>
            <button
              className="dropdown-item"
              onClick={onTransfer}
            >
              <span>
                {" "}
                <i className="fa fa-exchange-alt mr-1"></i> {lang.t('transfer')}
              </span>
            </button>
            <div className="dropdown-divider"></div>
            {loggedInUser
              ? hasPermission(loggedInUser, [
                  "DEACTIVATE_PLAYER",
                ]) && (
                  <button
                    onClick={onDelete}
                    className="dropdown-item text-danger"
                  >
                    <span>
                      <i className="fa fa-trash mr-1"></i> {lang.t('delete')}
                    </span>
                  </button>
                )
              : null}
          </div>
        </div>
    )
}