import React from 'react';
import SvgColor from 'react-svg-color';
import { COLORS } from '../utilities/colors';

const SvgContainer = ({ svg, color, width = 20, rootStyle, isUniqueColor }) => {
  const inColor = color || COLORS.primary;
  return (
    <div style={{ ...rootStyle }}>
      <SvgColor
        svg={svg}
        width={width}
        colors={
          isUniqueColor
            ? color
            : [inColor, inColor, inColor, inColor, inColor, inColor]
        }
      />
    </div>
  );
};

export default SvgContainer;
