import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getEnrolledAsync } from '../../store/enrolled/enrolledSlice';
import { updateUserAsync } from '../../store/users/userSlice';
import { showPasswordModal } from '../../store/ui';
import { hasPermission } from '../../utilities/auth';
import { DatatableCard, Loader } from '../../components';
import ModalFooter from '../../components/ModalFooter';

const Inscrit = (props) => {
  const { data, loading } = useSelector((state) => state.enrolled);
  const enrolledLoaded = useSelector((state) => state.ui.enrolledLoaded);
  const { matriculeCodeList } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [matriculeCode, setMatriculeCode] = useState('');
  const [user, setUser] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const filterData = useMemo(() => Object.values(data || {}), [data]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (!data) {
      dispatch(getEnrolledAsync());
    }
  }, [data, dispatch, enrolledLoaded]);

  const ids = useMemo(() => {
    return filterData?.map((el) => el.id);
  }, [filterData]);

  const onGrant = (res) => {
    setUser({ ...res, state: true });
    setModalVisibility(true);
  };

  const callback = () => {
    setSending(false);
    setModalVisibility(false);
    // navigate("inscrit");
  };

  const validateRegistration = () => {
    user['matriculeCode'] = matriculeCode;
    setSending(true);

    // console.log("user to update", user)
    dispatch(
      updateUserAsync({
        user: { id: user.id, matriculeCode: user?.matriculeCode },
        onSuccess: callback,
      })
    );
  };

  const onDelete = (user) => {
    dispatch(
      showPasswordModal({ deleteItemId: user, deleteSource: 'inscrits' })
    );
  };

  const columns = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row) => <span>{ids.indexOf(row?.id) + 1}</span>,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Surname',
      selector: 'surname',
      sortable: false,
    },
    {
      name: 'Place of birth',
      selector: 'pob',
      sortable: false,
    },
    {
      name: 'Gender',
      selector: 'gender',
      sortable: false,
    },
    {
      name: 'Profession',
      selector: 'profession',
      sortable: false,
    },
    {
      cell: (row, index, column, selector) => (
        <Link
          to={{
            pathname: '/admin/inscrit-detail/',
          }}
          state={{ userId: row.id }}
          className="btn btn-sm"
        >
          <i className="fa fa-list"></i>
        </Link>
      ),
    },
    {
      name: '',
      selector: 'name',
      cell: (row, index, column, selector) =>
        loggedInUser
          ? hasPermission(loggedInUser, ['VALIDATE_REGISTRATION']) && (
              <button
                style={{ margin: 0 }}
                onClick={() => onGrant(row)}
                className="btn btn-sm btn-primary"
              >
                <i className="fa fa-check"></i>
              </button>
            )
          : null,
    },
    {
      name: '',
      selector: 'name',
      cell: (row, index, column, selector) =>
        loggedInUser
          ? hasPermission(loggedInUser, ['DELETE_REGISTRATION']) && (
              <button
                style={{ margin: 0 }}
                onClick={() => onDelete(row)}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-times"></i>
              </button>
            )
          : null,
    },
  ];

  const tableData = {
    columns,
    data: filterData,
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case 'matriculeCode':
        setMatriculeCode(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                LIST OF ENROLLED
              </h6> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? <Loader /> : <DatatableCard tableData={tableData} />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        show={modalVisibility}
        onHide={() => setModalVisibility(false)}
      >
        <Modal.Body>
          <p>Please choose a code to validate the registration</p>
          <hr style={{ margin: 5, marginBottom: 10 }} />

          <div class="row">
            {matriculeCodeList?.length ? (
              <div class="col-lg-12">
                <div class="form-group">
                  <label for="matriculeCode">Code Matricule</label>{' '}
                  <span class="text-danger ml-1">*</span>
                  <select
                    class="browser-default custom-select"
                    name="matriculeCode"
                    id="matriculeCode"
                    onChange={handleInputChange}
                    value={matriculeCode}
                  >
                    <option value="" disabled selected>
                      Choose an option
                    </option>
                    {matriculeCodeList.map((e) => {
                      return <option value={e.code}>{e.code}</option>;
                    })}
                  </select>
                </div>
              </div>
            ) : null}
          </div>

          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <button
              disabled={matriculeCode === '' ? true : false}
              onClick={() => validateRegistration()}
              class="btn btn-primary"
            >
              Validate registration
            </button>
            {sending ? (
              <Loader />
            ) : (
              <button class="btn" onClick={() => setModalVisibility(false)}>
                Close
              </button>
            )}
          </div>
        </Modal.Body>

        <ModalFooter />
      </Modal>
    </div>
  );
};

export default Inscrit;
