import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import EStopMatchSet from './EStopMatchSet';
import { Loader } from '../../../components';

const EStopMatchModal = ({
  handleClose,
  show,
  score,
  time,
  onConfirmStopMatch,
  isOverThree,
  loading,
}) => {
  const teamA = Object.values(score)[0];
  const teamB = Object.values(score)[1];
  console.log('is over three', isOverThree);
  const close = () => {
    if (isOverThree) {
      return;
    }
    if (teamA?.scoreSet?.length !== 5) {
      handleClose();
    }
  };

  const teamAScore = teamA.scoreSet.reduce((a, b) => a + b.score, 0);
  const teamBScore = teamB.scoreSet.reduce((a, b) => a + b.score, 0);

  const winner =
    teamAScore > teamBScore
      ? teamA.teamName
      : teamAScore < teamBScore
      ? teamB.teamName
      : 'DRAW';

  return (
    <Modal show={show} centered size="sm" onHide={close}>
      <Modal.Header closeButton style={{ borderBottom: '1px solid #c2c2c2' }}>
        <Modal.Title>END MATCH</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {teamA.scoreSet.map((score, index) => (
          <EStopMatchSet
            key={index}
            score={score}
            teamA={teamA}
            teamB={teamB}
          />
        ))}
        <div className="d-flex justify-content-between border-top border-bottom mt-2 pt-2">
          <div>
            <p className="mb-0">Time:</p>
            <h1>{time}</h1>
          </div>
          <div>
            <p className="mb-0">Winner:</p>
            <h1>{winner}</h1>
          </div>
        </div>

        <div>
          <button
            className="btn btn-sm w-100"
            onClick={onConfirmStopMatch}
            style={{ backgroundColor: '#E56969', color: 'white' }}
          >
            { loading ? <Loader /> : "END MATCH" }
          </button>

          {teamA?.scoreSet?.length !== 5 && !isOverThree ? (
            <button className="btn btn-sm w-100" onClick={handleClose}>
              Close
            </button>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EStopMatchModal;
