import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Checkbox, EmptyTable } from "../../../components";
import useLang from "../../../hook/useLang";

const EInjuryModal = ({
  handleClose,
  show,
  team,
  players,
  time,
  onInjury,
  data,
  captainSelection,
  playerSelection,
  onBenchPlayers,
}) => {
  const [playerID, setPlayerID] = useState("");
  const [playerSubID, setPlayerSubID] = useState("");
  const [isSubstitute, setSubstitute] = useState(false);

  const onSave = () => {
    let toSave = {
      time,
      player: playerID,
      playerSubID,
      team,
    };
    if (isSubstitute) {
      toSave = {
        ...toSave,
        playerSelection: {
          [playerID]: { playerIn: playerSubID, playerOut: playerID },
        },
      };
    }
    onInjury(toSave);
  };
  const lang = useLang();

  return (
    <Modal show={show} centered size={"lg"} onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title> {lang?.injury?.toUpperCase()} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{lang?.team}:</p>
                <h1 style={{ fontSize: 16 }}>{team.name}</h1>
              </div>
              <div>
                <p className="mb-0">{lang?.time}:</p>
                <h1 style={{ fontSize: 16 }}>{time}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">{lang?.player}:</p>
              <select
                onChange={({ target }) => setPlayerID(target.value)}
                className="form-control form-control-sm"
                value={playerID}
              >
                <option></option>
                {players?.map((val) => (
                  <option key={val.player.id} value={val.player.id}>
                    {`(${val.kit}) ${val.player.name} ${
                      captainSelection[val.player.id]?.role
                        ? `(${captainSelection[val.player.id]?.role?.charAt(
                            0
                          )})`
                        : ""
                    }`}
                  </option>
                ))}
              </select>
            </div>

            {isSubstitute && (
              <div>
                <p className="mb-0 mt-3">
                  {lang?.player} ( {lang?.substitute} ):
                </p>
                <select
                  onChange={({ target }) => setPlayerSubID(target.value)}
                  className="form-control form-control-sm"
                  value={playerSubID}
                >
                  <option></option>
                  {onBenchPlayers?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <Checkbox
              title="Substitute"
              id="normal"
              onChange={(e) => {
                setSubstitute((prev) => !prev);
              }}
              checked={isSubstitute}
            />
          </div>
          <div className="col-md-6 border-left">
            <h4 className="text-center">
              <b>{lang?.injuryHistory}</b>
            </h4>
            {data?.length ? (
              data
                .filter((dat) => dat.team === team.id)
                .map((history) => (
                  <div
                    className="border px-2"
                    style={{ backgroundColor: "#c2c2c220" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <small className="mb-0">{lang?.player}:</small>
                        <div>
                          <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                            {players.filter(
                              ({ player }) => player.id === history.player
                            )[0]?.player?.name ||
                              onBenchPlayers.filter(
                                ({ player }) => player.id === history.player
                              )[0]?.player?.name}
                          </h6>
                          {history.playerSubID ? (
                            <>
                              <div
                                className="my-3"
                                style={{
                                  height: 2,
                                  backgroundColor: "#c2c2c2",
                                }}
                              />
                              <small>{lang?.replaceWith}:</small>
                              <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                                {
                                  players.filter(
                                    ({ player }) =>
                                      player.id === history.playerSubID
                                  )[0]?.player?.name
                                }
                              </h6>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        <small className="mb-0">{lang?.time}:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {history.time}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <EmptyTable
                titleStyle={{ fontSize: 14 }}
                imageStyle={{ height: 70, width: 70 }}
                rootStyle={{ marginTop: 60 }}
              />
            )}
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#E56969", color: "white" }}
          >
            {lang?.saveInjury}
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            {lang?.close}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EInjuryModal;
