import React from 'react';
import { NavLink } from 'react-router-dom';
import { COLORS } from '../utilities/colors';
import SvgContainer from './SvgContainer';

const SidebarItem = ({ route, icon, title, active }) => {
  return (
    <li className="nav-item">
      <NavLink to={`/admin/${route}`}>
        <p
          className="nav-link"
          style={{
            backgroundColor: active ? COLORS.primary : 'transparent',
            borderRadius: 10,
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <SvgContainer
            svg={icon}
            color={active ? COLORS.white : COLORS.sidemenu_text}
            rootStyle={{ paddingBottom: 5, marginRight: 8 }}
          />
          <span
            className="nav-link-text"
            style={{
              fontSize: 13,
              color: active ? COLORS.white : COLORS.sidemenu_text,
            }}
          >
            {title.toUpperCase()}{' '}
          </span>
        </p>
      </NavLink>
    </li>
  );
};

export default SidebarItem;
