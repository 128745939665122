import React, { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import useLang from "../../../hook/useLang";
import EManualChangeBox from "./EManualChangeBox";

const EManualChangeModal = ({ handleClose, show }) => {
  const [manualChanges, setManualChanges] = useState({
    captainChange: false,
    liberoPopup: false,
    checkAccidents: false,
    timeouts: false,
    numPlayers: 0,
    scoreTimer: false,
    shareData: false,
  });

  const onSave = () => {
    localStorage.setItem("fcvbManualChanges", JSON.stringify(manualChanges));
    handleClose();
  };

  useEffect(() => {
    const mc = localStorage.getItem("fcvbManualChanges");
    if (mc) {
      setManualChanges(JSON.parse(mc));
    }
  }, []);
  const lang = useLang();

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>{lang?.manualChanges} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>GENERAL</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                preTitle={lang?.captain}
                title={lang?.changeCaptainInCour}
                checked={manualChanges.captainChange}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, captainChange: val })
                }
              />
              <EManualChangeBox
                preTitle={"Libero"}
                title={lang?.popupForLibero}
                checked={manualChanges.liberoPopup}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, liberoPopup: val })
                }
              />
              <EManualChangeBox
                preTitle={lang?.checkAcciddent}
                title={lang?.reportCaseAccident}
                checked={manualChanges.checkAccidents}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, checkAccidents: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{lang?.tournament}</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                preTitle={lang?.timeouts}
                title={lang?.playWithTechnicalTimaeout}
                checked={manualChanges.timeouts}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, timeouts: val })
                }
              />
              <EManualChangeBox
                title={lang?.numberOfPlayerAuthorize}
                showText
                checked={manualChanges.numPlayers}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, numPlayers: val })
                }
              />
              <EManualChangeBox
                preTitle={lang?.scoreTimer}
                title={lang?.enable15sTimer}
                checked={manualChanges.scoreTimer}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, scoreTimer: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{lang?.shortcuts}</Accordion.Header>
            <Accordion.Body>{lang?.listOfShortcuts}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header> {lang?.shareData}</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox
                title={lang?.ShareInformationWithOtherFederations}
                checked={manualChanges.shareData}
                onChange={(val) =>
                  setManualChanges({ ...manualChanges, shareData: val })
                }
              />
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey="4">
            <Accordion.Header>VCS</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Enable Video Challenge System" />
              <EManualChangeBox title="Configure IP address for information exchange" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>TABLET</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Enable PoE tablets" />
              <EManualChangeBox title="Configure IP address, Port and password" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>VIS</Accordion.Header>
            <Accordion.Body>
              <EManualChangeBox title="Configure access to Video Information System(login,IP,password,connection)" />
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#00a1ff", color: "white" }}
          >
            {lang?.save}
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            {lang?.close}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EManualChangeModal;
