import React from 'react';
import { COLORS } from '../utilities/colors';

const Footer = () => {
  return (
    <footer style={{ paddingLeft: 20, paddingRight: 20 }}>
      <hr style={{ backgroundColor: '#fff', margin: 0 }} />
      <div className="d-flex align-items-center justify-content-center">
        <p
          style={{
            color: COLORS.sidemenu_text,
            fontSize: 11,
            marginTop: 15,
            marginRight: 10,
          }}
        >
          Made by
        </p>
        <a href="https://cybexai.com" target="_blank" rel="noopener noreferrer">
          <img src="/assets/images/cybex_logo.png" height="20" alt="cybexai" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
