import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUsersAsync } from '../store/users/userSlice';
import { initPlayer } from '../store/players/playerSlice';

export default function usePlayers() {
  const { data, loaded } = useSelector((state) => state.player);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoading(true);
      dispatch(
        getUsersAsync({
          code: 'JOUER',
          onSuccess: (data) => {
            setLoading(false);
            dispatch(initPlayer(data));
          },
          onStop: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [dispatch, loaded]);
  return { data, loading };
}
