/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../BackButton';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utilities/auth';
import PrintButton from './PrintButton';
import MatchReportHeader from './MatchReportHeader';
import FooterSection from './RefereeSection';
// import ModalAddMatchResult from "./ModalMatchResult";

const cellStyle = {
  display: 'flex',
  padding: '20px',
  alignItems: 'center',
  justifyContent: 'center',
};

const MatchReport = () => {
  const [matchDetails, setMatchDetails] = useState();
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const [teamA, setTeamA] = useState([]);
  const [teamB, setTeamB] = useState([]);
  const [ecoreSheet, setEscoreSheet] = useState();

  useEffect(() => {
    if (location.state) {
      setMatchDetails({ ...location.state });
      console.log({ match: location.state });
      RosterTeams(location.state);
      const obj = JSON.parse(location.state.scoresheet);

      setEscoreSheet(obj);
    }
  }, [location.state]);

  const stat = useMemo(() => {
    const sets = (ecoreSheet?.sets || []).filter((set) => !set?.active);
    const teamA = matchDetails?._participantOne?.id;
    const teamB = matchDetails?._participantTwo?.id;

    const oldObj = ecoreSheet?.score || {};
    const timeList = [];

    const totalTeamA = { w: 0, t: 0, p: 0, s: 0 };
    const totalTeamB = { w: 0, t: 0, p: 0, s: 0 };

    const stat = sets.map((set) => {
      const teamAP = oldObj[teamA]?.scoreSet?.find(
        (el) => el.set === set.number
      )?.score;
      totalTeamA.p = totalTeamA.p + teamAP;

      const teamBP = oldObj[teamB]?.scoreSet?.find(
        (el) => el.set === set.number
      )?.score;
      totalTeamB.p = totalTeamB.p + teamBP;
      timeList.push(set.time);

      return {
        ...set,
        duration: set?.time,
        teamA: {
          p: teamAP,
          t: 0,
          w: 0,
          s: 0,
        },
        teamB: {
          p: teamBP,
          t: 0,
          w: 0,
          s: 0,
        },
      };
    });

    console.log({ stat });

    return { sets: stat, teamASum: totalTeamA, teamBSum: totalTeamB };
  }, [ecoreSheet, matchDetails]);

  const RosterTeams = (details) => {
    let teamA = [];
    let teamB = [];
    if (details) {
      const playerSelection = JSON.parse(details.roster).playerSelection;

      for (const key in playerSelection) {
        for (const subkey in playerSelection[key]) {
          if (playerSelection[key]['clubID'] === details._participantOne.id) {
            if (subkey === 'clubID') {
              teamA.push({
                ...playerSelection[key]['player'],
                kit: playerSelection[key]['kit'],
              });
              continue;
            }
          } else {
            if (subkey === 'clubID') {
              teamB.push({
                ...playerSelection[key]['player'],
                kit: playerSelection[key]['kit'],
              });
              continue;
            }
          }

          //   console.log(`${subkey}: ${playerSelection[key][subkey]}`);
        }
      }
    }

    setTeamA(teamA);
    setTeamB(teamB);
  };

  console.log({ teamA, teamB });
  function getScore(id, setNum) {
    // const nbPoint = sets.score[id]?.scoreSet?.find(
    //   (score) => score.set == setNum
    // )?.score;
    return 0;
  }
  function getSubs(id, setNum) {
    // const teamSub = sets.substitutions?.filter((sub) => sub?.team === id);
    // const len = teamSub?.filter((sub) => sub.set === setNum)?.length;
    return 0;
  }

  return (
    <>
      <div className="header pb-6">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center py-4">
              <div className="col-lg-12">
                <h6 className="h2 d-inline-block text-uppercase mb-0">
                  <span className="">
                    <i className="fa fa-list fa-lg mr-3"></i>
                  </span>
                  MATCHS LIST
                </h6>
                <BackButton />

                {loggedInUser
                  ? hasPermission(loggedInUser, ['PRINT_MATCH']) && (
                      <PrintButton />
                    )
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {matchDetails ? (
        <div className="container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h1>RESULTS</h1>
          </div>
          {/*  */}
          <MatchReportHeader
            pictureTeamA={matchDetails?._participantOne?.picture || ''}
            nameTeamA={matchDetails._participantOne?.name || ''}
            pictureTeamB={matchDetails?._participantTwo?.picture || ''}
            nameTeamB={matchDetails._participantTwo?.name}
          />
          {/*  */}

          <div
            className="container"
            style={{
              width: '100%',
              marginTop: '20px',

              padding: '0px',
            }}
          >
            <div
              className="row"
              style={{ width: '100%', margin: '0px', background: '#000000' }}
            >
              {['T', 'S', 'W', 'P', 'SET', 'DURATION', 'P', 'W', 'S', 'T'].map(
                (letter, i) => (
                  <div
                    className={`col-md-${letter.length > 1 ? 2 : 1}`}
                    key={i}
                    style={{
                      ...cellStyle,
                      color: '#FFF',
                    }}
                  >
                    {' '}
                    {letter}
                  </div>
                )
              )}
            </div>

            {stat.sets.map((set, i) => (
              <div
                className="row"
                key={i}
                style={{
                  background: i % 2 === 0 ? '#00060' : '#FFF',
                  margin: '0px',
                }}
              >
                {['t', 's', 'w', 'p'].map((key) => (
                  <div className="col-md-1" key={key} style={cellStyle}>
                    {set?.teamA?.[key]}
                  </div>
                ))}
                <div className="col-md-2" style={cellStyle}>
                  {set?.number}
                </div>
                <div className="col-md-2" style={cellStyle}>
                  {set?.duration}
                </div>
                {['p', 'w', 's', 't'].map((key) => (
                  <div className="col-md-1" key={key} style={cellStyle}>
                    {set.teamB?.[key]}
                  </div>
                ))}
              </div>
            ))}
            <div
              className="row"
              style={{
                background: '#00060',
                margin: '0px',
              }}
            >
              {['t', 's', 'w', 'p'].map((key) => (
                <div className="col-md-1" key={key} style={cellStyle}>
                  {stat.teamASum?.[key]}
                </div>
              ))}
              <div className="col-md-4" style={cellStyle}>
                total set duration
              </div>
              {['p', 'w', 's', 't'].map((key) => (
                <div className="col-md-1" key={key} style={cellStyle}>
                  {stat.teamBSum?.[key]}
                </div>
              ))}
            </div>

            <div
              className="row"
              style={{ margin: 0, background: '#000', color: '#ffffff' }}
            >
              <div className="col-md-4" style={cellStyle}>
                Match start time
                <br />
              </div>
              <div className="col-md-4" style={cellStyle}>
                Match end time
              </div>
              <div className="col-md-4" style={cellStyle}>
                Match total duration
              </div>
            </div>
            <div className="row" style={{ margin: 0, background: '#00000080' }}>
              <div
                className="col-md-4"
                style={{ ...cellStyle, fontWeight: 600, color: '#FFF' }}
              >
                Winner
              </div>
              <div className="col-md-8" style={{ ...cellStyle, color: '#FFF' }}>
                {stat.teamASum.p > stat.teamBSum.p
                  ? matchDetails._participantOne?.name || ''
                  : matchDetails._participantTwo?.name}
              </div>
            </div>
            <FooterSection
              matchDetails={matchDetails}
              teamA={teamA}
              teamB={teamB}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default MatchReport;
