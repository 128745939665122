import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import {
  BackButton,
  CardRow,
  DatatableCard,
  EmptyDetailCard,
  Loader,
} from "../../../components";

import { stringReduceAddDots } from "../../../utilities";
import { useLocation } from "react-router-dom";
import useLang from "../../../hook/useLang";
import { getUserInfoAsync } from "../../../store/users/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerTransferAsync, updatePlayer } from "../../../store/players/playerSlice";

const PlayerDetail = (props) => {
  const location = useLocation();
  // const [user, setUser] = useState([]);
  const [loading , setLoading] = useState(false)
  const dispatch = useDispatch()
  // const [playerTransfer, setPlayerTransfer] = useState([]);
  const {data} = useSelector((state)=>state.player)
  const lang = useLang();

  const {userId} = location.state;
  
  const user = useMemo(()=>data[userId],[data, userId])
 

  useEffect(() => {
    if(userId && !user?.hasAllInfo){
      setLoading(true)
      dispatch(getUserInfoAsync({
        userId,
        onSuccess:(data)=>{
          dispatch(updatePlayer({...data, hasAllInfo:true }))
          setLoading(false)
        }

      }))
    }
  }, [dispatch, user, userId]);

  useEffect(()=>{
    if(user?.hasAllInfo && !user?.transfertList){
        dispatch(getPlayerTransferAsync({
          playerId:userId,
        }))
    }
  },[dispatch, user, userId])

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "From",
      selector: "from.name",
      sortable: true,
    },
    {
      name: "To",
      selector: "to.name",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <button className="btn btn-sm btn-danger">
          <i className="fa fa-trash"></i>
        </button>
      ),
    },
  ];

  const tableData = {
    columns,
    data: user?.transfertList || [],
  };

  const categoryFinder = (date, gender) => {
    const today = new Date();
    const age = today.getFullYear() - date;

    if (age >= 21) {
      return "SÉNIORS";
    } else if (age < 21 && age > 18) {
      return "JUNIORS";
    } else if (age === 18) {
      return "CADETS";
    } else if (age < 18 && age >= 15) {
      return "MINIMES";
    } else if (age < 15 && age >= 13) {
      return gender === "HOMME" ? "BENJAMINS" : "BENJAMINES";
    } else {
      return "ÉCOLES";
    }

    // if (gender === 'HOMME') {
    //   if (date <= 2000)
    //     return 'SÉNIORS'
    //   else if (date > 2000 && date < 2003)
    //     return 'JUNIORS'
    //   else if (date === 2003)
    //     return 'CADETS'
    //   else if (date >= 2004 && date < 2006)
    //     return 'MINIMES'
    //   else if (date >= 2006 && date < 2008)
    //     return 'BENJAMINS'
    //   else if (date >= 2008)
    //     return 'ÉCOLES'
    // } else if (gender === 'FEMME') {
    //   if (date <= 2001)
    //     return 'SÉNIORS'
    //   else if (date > 2001 && date < 2004)
    //     return 'JUNIORS'
    //   else if (date === 2004)
    //     return 'CADETTES'
    //   else if (date >= 2005 && date < 2007)
    //     return 'MINIMES'
    //   else if (date >= 2007 && date <= 2008)
    //     return 'BENJAMINES'
    //   else if (date >= 2009)
    //     return 'ÉCOLES'
    // }
  };
  if(loading){
    return <Loader />;
  }

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span>
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.player_detail}
              </h6>
              <BackButton />
            </div>
          </div>

          {user ? (
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img
                        src={user?.picture || "/assets/images/profile.png"}
                        alt="Admin"
                        className="rounded"
                        width="150"
                        height="150"
                      />
                      <div className="mt-3">
                        <h6>{`${user?.name} ${user?.surname}`}</h6>

                        {user?.dossard ? (
                          <p className="font-size-sm mb-0">
                            Dossard: <b>{user?.dossard}</b>
                          </p>
                        ) : null}
                        {user?.matricule ? (
                          <p
                            className="font-size-sm mb-0"
                            style={{ fontSize: 12 }}
                          >
                            {user?.matricule}|
                            <b>
                              {categoryFinder(
                                parseInt(moment(user?.dob).format("YYYY")),
                                user?.gender
                              )}
                            </b>
                          </p>
                        ) : null}
                        {user?._subRole ? (
                          <p
                            className="font-size-sm mb-0"
                            style={{ fontSize: 12 }}
                          >
                            {user?._subRole?.name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-25 bg-white rounded my-3">
                  <div className="card-body">
                    <CardRow
                      title="League"
                      value={user?.league}
                      title2="Region"
                      value2={user?._region?.name}
                    />
                    <CardRow
                      title="Departement"
                      value={user?._departement?.name}
                      title2={stringReduceAddDots("District", 15)}
                      value2={ user?._arrondissement?.name
                      }
                      showHR={false}
                    />
                  </div>
                </div>

                {/* {user?.signature ? <SignaturePreview signature={user?.signature} height={'100%'} /> : null} */}
              </div>
              <div className="col-md-8">
                <div className="card mb-3 shadow bg-white rounded my-3">
                  <div className="card-body">
                    <CardRow
                      title={lang?.name}
                      value={user?.name}
                      title2={lang?.surname}
                      value2={user?.surname}
                    />
                    <CardRow
                      title={lang?.dateOfBirth}
                      value={user?.dob}
                      title2={lang?.placeOfBirth}
                      value2={user?.pob}
                    />
                    <CardRow
                      title={lang?.maritalStatus}
                      value={user?.familyStatus}
                      title2={lang?.gender}
                      value2={user?.gender}
                    />
                    <CardRow
                      title={lang?.email}
                      value={user?.email}
                      showHR={false}
                      title2={lang?.phone}
                      value2={user?.phone}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="card mb-3 shadow bg-white rounded my-3">
                      <div className="card-body">
                        <CardRow
                          title={lang?.profession}
                          value={user?.profession}
                          title2={lang?.role ?? "Role"}
                          value2={user?._role?.name}
                        />
                        <CardRow
                          title="Matricule"
                          value={user?.matricule}
                          title2={lang?.schoolLevel ?? "Education level"}
                          value2={user?.schoolLevel}
                        />
                        <CardRow
                          title={lang?.creationDate ?? "Creation date"}
                          value={
                            <Moment format="DD/MM/YYYY">
                              {user?.creationDate}
                            </Moment>
                          }
                          title2="Club"
                          value2={user?.clubs?.[0]?.name}
                          showHR={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {user?.transfertList ? (
                  <div className="row">
                    <div className="col">
                      <div className="card mb-3 shadow bg-white rounded my-3">
                        <div className="card-body">
                          <h1 className="text-center">Transferts</h1>
                          <DatatableCard tableData={tableData} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <EmptyDetailCard />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayerDetail;
