import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { DatatableCard, Loader } from '../../components';
import useLang from '../../hook/useLang';
import { hasPermission } from '../../utilities/auth';

import Options from './components/Options';
import { showPasswordModal } from '../../store/ui';

const Referee = (props) => {
  const { data, loading } = useSelector((state) => state.referee);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const refereeList = useMemo(() => {
    return Object.values(data);
  }, [data]);

  const dispatch = useDispatch();
  const lang = useLang();

  const ids = useMemo(() => {
    return refereeList?.map((el) => el.id);
  }, [refereeList]);

  const onDelete = (user) => {
    console.log('on delete', user);
    dispatch(
      showPasswordModal({ deleteItemId: user, deleteSource: 'referee' })
    );
  };

  const columnsPrint = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row, index, column, id) => <span>{ids.indexOf(row?.id) + 1}</span>,
    },
    {
      name: lang?.name,
      selector: 'name',
      sortable: false,
    },
    {
      name: lang?.surname,
      selector: 'surname',
      sortable: false,
    },
    {
      name: lang?.gender,
      selector: 'gender',
      sortable: false,
    },
    {
      name: 'Matricule',
      selector: 'matricule',
      sortable: false,
    },
    {
      name: 'Profession',
      selector: 'profession',
      sortable: false,
    },
  ];

  const columns = [
    ...columnsPrint,
    {
      cell: (row) => (
        <Options row={row} user={loggedInUser} onDelete={onDelete} />
      ),
    },
  ];

  const tableData = {
    columns,
    data: refereeList,
  };

  const refToPrint = useRef();
  const r = useReactToPrint({
    content: () => refToPrint.current,
  });

  const [preview, setPreview] = useState(false);
  const handlePrint = async () => {
    await setPreview(true);
    await r();
    await setPreview(false);
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.referee_list}
              </h6> */}
              {loggedInUser.length !== 0
                ? hasPermission(loggedInUser, ['ADD_REFEREE']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/referee-form"
                        state={{ userId: null }}
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser.length !== 0
                ? hasPermission(loggedInUser, ['PRINT_REFEREE']) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => setPreview((prev) => !prev)}
                        >
                          <span>
                            <i className="fa fa-eye mr-1"></i>{' '}
                            {preview
                              ? lang?.hide_preview ?? 'Hide PReview'
                              : lang?.preview_print ?? 'Preview print'}
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={handlePrint}>
                          <span>
                            <i className="fa fa-print mr-1"></i> {lang?.print}
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? (
                  <Loader />
                ) : (
                  <div ref={refToPrint}>
                    <DatatableCard
                      tableData={{
                        ...tableData,
                        columns: preview ? columnsPrint : columns,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referee;
