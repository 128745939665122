import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SidebarItem } from '.';
import useLang from '../hook/useLang';
import { hasRole } from '../utilities/auth';
import {
  clubs,
  coaches,
  dashboard,
  enrolled,
  federation,
  league,
  matches,
  players,
  referee,
  settings,
  tournament,
} from '../assets/icons';
import Footer from './Footer';

const AdminSidebar = ({ user }) => {
  const location = useLocation();
  const lang = useLang();

  return (
    <nav
      className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white border-0"
      id="sidenav-main"
      style={{ overflow: 'hidden' }}
    >
      <div>
        <div className="sidenav-header text-center align-items-center justify-content-center py-3 px-3 mb-3">
          <NavLink to="/admin/dashboard">
            <img src="/assets/images/slive.png" height="60" alt="logo" />
          </NavLink>
          <hr style={{ backgroundColor: '#fff', margin: 0 }} />
        </div>
        {/* <div className="collapse navbar-collapse" id="sidenav-collapse-main"></div> */}

        <div
          className="navbar-inner scrollbar-inner pt-3"
          style={{ overflow: 'auto', height: '83vh' }}
        >
          <ul className="navbar-nav">
            <SidebarItem
              title={lang?.dashboard?.toUpperCase()}
              route="dashboard"
              icon={dashboard}
              active={location.pathname.includes('dashboard')}
            />
            {/* <SidebarItem title='MON PROFIL' route='profile' icon='user' active={location.pathname.includes('profile')} /> */}
            {hasRole(user, ['ADMIN']) && (
              <SidebarItem
                title={lang?.settings?.toUpperCase()}
                route="securite"
                icon={settings}
                active={location.pathname.includes('securite')}
              />
            )}
            {/* {hasRole(user, ['ADMIN']) && <SidebarItem title='E-SCORE SHEET' route='e-score-sheet' icon='poll' iconColor='text-green' />} */}
            <SidebarItem
              title="MATCHS"
              route="match"
              icon={matches}
              active={location.pathname.includes('match')}
            />
            <SidebarItem
              title={lang?.players}
              route="player"
              icon={players}
              active={location.pathname.includes('player')}
            />
            <SidebarItem
              title={lang?.referees?.toUpperCase()}
              route="referee"
              active={location.pathname.includes('referee')}
              icon={referee}
            />
            <SidebarItem
              title="COACHS"
              route="coach"
              active={location.pathname.includes('coach')}
              icon={coaches}
            />
            <SidebarItem
              title="CLUBS"
              route="club"
              active={location.pathname.includes('club')}
              icon={clubs}
            />
            <SidebarItem
              title={lang?.enrolled}
              route="inscrit"
              icon={enrolled}
              active={location.pathname.includes('inscrit')}
            />
            <SidebarItem
              title={lang?.tournament}
              route="tournois"
              icon={tournament}
              active={location.pathname.includes('tournois')}
            />
            {hasRole(user, ['ADMIN']) && (
              <SidebarItem
                title="FEDERATION"
                route="federation"
                icon={federation}
                active={location.pathname.includes('federation')}
              />
            )}
            {hasRole(user, ['ADMIN', 'LEAGU']) && (
              <SidebarItem
                title="LEAGUE"
                route="ligue"
                icon={league}
                active={location.pathname.includes('ligue')}
              />
            )}
          </ul>

          {/* <h6 className="navbar-heading p-0 text-muted">
                        <span className="docs-normal" style={{ letterSpacing: 1 }}>Membres</span>
                    </h6> */}
        </div>
        <Footer />
      </div>
    </nav>
  );
};

export default AdminSidebar;
