import React from 'react';
// import { useDispatch } from 'react-redux';
// import { closeNotify } from '../../screens/admin/actions';

const Settings = () => {

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   return () => dispatch(closeNotify())
  // }, [dispatch]);


  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-cog fa-lg mr-3"></i></span>SETTINGS</h6>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >

                <div className="card-body" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="fa fa-globe mb-2 mr-1"></i>
                    <h1>Language</h1>
                  </div>
                  <div>
                    <select className='form-control'>
                      <option>Français</option>
                      <option>English</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >

                <div className="card-body" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="fa fa-adjust mb-2 mr-1"></i>
                    <h1>Theme</h1>
                  </div>
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" />
                    <label class="custom-control-label" for="customSwitch1">Night mode</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Settings