import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Modal from "react-bootstrap/Modal";
import { Link, useParams } from 'react-router-dom';
import { DatatableCard, Loader } from '../../components';
import { getRolePermissionAsync } from '../../store/ui';

// import { closeNotify, showPasswordModal, getRolePermissions, saveRolePermission, updateRolePermission, getPermissions, getPermissionsSuccess } from '../actions';
// import { getRole } from '../apis';



const RolePermissionPermission = (props) => {

  const {roles , loading} = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const { roleCode } = useParams();

  const [id, setId] = useState('');
  const [role, setRole] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);

  // console.log("role code", roleCode, roles);

  useEffect(() => {
    dispatch(getRolePermissionAsync({roleCode}));
  }, [dispatch, roleCode]);


  const resetFields = () => {
    // setId('');
    
  }

  const handleSubmit = e => {

    // e.preventDefault();

    // let rolePermission = { roleId: role.id, id, permissions: selectedPermissions, roleCode }

    // id === '' ? dispatch(saveRolePermission(rolePermission)) : dispatch(updateRolePermission(rolePermission))

    // resetFields();
    // setModalVisibility(false)

  }

  const addRolePermission = () => {
    resetFields()
    // setModalVisibility(true)
  }

  
  const onEdit = (rolePermission) => {

    setId(rolePermission.id)
    // setCode(rolePermission.code)
    // setName(rolePermission.name)
    setModalVisibility(true)
  }

  const onDelete = (rolePermission) => {
    // dispatch(showPasswordModal(rolePermission, 'rolePermission'))
  }

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>
    },
    {
      name: 'Role',
      selector: '_role.name',
      sortable: true,
      cell: (row, index, column, selector) => row?.name
    },
    {
      name: 'Permission',
      selector: '_permission.name',
      sortable: true,
      cell: (row, index, column, selector) => row?.name
    },
    {
      name: "Creation date",
      selector: 'creationDate',
      sortable: true,
      cell: (row, index, column, selector) => <Moment format='DD/MM/YYYY'>{row.creationDate}</Moment>
    },
    {
      cell: (row, index, column, selector) => <button
        onClick={() => onEdit(row)}
        className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button>
    },
    {
      cell: (row, index, column, selector) => <button
        onClick={() => onDelete(row)}
        className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
    },
  ];

  const tableData = {
    columns,
    // data: adminState.rolePermissions
    data:roles || []
  };

  const permissionColumns = [
    {
      name: 'Nº',
      cell: (row, index, column, id) => <p>{index + 1}</p>
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    }
  ];

  const permissionTableData = {
    columns: permissionColumns,
    data:[]
    // data: adminState.permissions
  };


  const handleRowClicked = row => {
    // const updatedData = adminState.permissions.map(item => {
    //   if (row.id !== item.id) {
    //     return item;
    //   }

    //   return {
    //     ...item,
    //     toggleSelected: !item.toggleSelected
    //   };
    // });
    
    // setSelectedPermissions(updatedData)
    // dispatch(getPermissionsSuccess(updatedData));
  };

  const conditionalRowStyles = [
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: "#2DCE89",
        color: "#FFF",
        userSelect: "none"
      }
    }
  ];

  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                <i className="fa fa-list fa-lg mr-3"></i></span>
                PERMISSION ROLE LIST: <b className="h1"> 
              {/* {role.name} */}
              </b></h6>
              <h6 style={{ float: 'right' }} className="h2 d-inline-block text-uppercase mb-0"><button onClick={() => addRolePermission()} style={{ backgroundColor: '#19a0c2', color: '#fff' }} className="btn btn-sm"><i className="fa fa-plus fa-lg"></i> </button></h6>
              <h6 style={{ float: 'right' }} class="h2 d-inline-block text-uppercase mb-0"> <Link to='/admin/securite/role' className="btn btn-sm" style={{ backgroundColor: '#19a0c2', color: '#fff' }}><i class="fa fa-list fa-lg mr-2"></i> ROLES LIST</Link></h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >
                <DatatableCard tableData={tableData} />
              </div>
            </div>
          </div>
        </div>
        <Modal show={modalVisibility} size='lg' centered onHide={() => setModalVisibility(false)}>
          <Modal.Header tabIndex='-1' aria-hidden closeButton>{id === '' ? 'Add' : 'Edit'} a permission</Modal.Header>
          <Modal.Body>

            <form onSubmit={handleSubmit} style={{height: "70vh", overflow: "auto"}}>

              {loading ?

                <Loader /> : 
                <DatatableCard
                  tableData={permissionTableData}
                  onRowClicked={handleRowClicked}
                  highlightOnHover={false}
                  conditionalRowStyles={conditionalRowStyles} 
                />}

            </form>

            <div class="modal-footer pb-0 pt-0">
                <button
                  type="button"
                  class="btn"
                  onClick={() => setModalVisibility(false)}>
                  Close
              </button>
                <button type="submit" class="btn btn-primary">
                  Save
              </button>
              </div>

          </Modal.Body>

        </Modal>
      </div>
    </div>
  );
}

export default RolePermissionPermission