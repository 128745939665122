import { SIGNIN, AUTH_RESULT, SIGNUP } from '../../auth/constants';
import * as constants from '../constants';

const initialState = {
  referees: [],
  refereesLength: 0,
  coachs: [],
  coachsLength: 0,
  players: [],
  playersLength: 0,
  clubs: [],
  clubsLength: 0,
  code: 0,
  grade: [],
  inscrits: [],
  inscritsLength: 0,
  federations: [],
  federationsLength: 0,
  leagues: [],
  leaguesLength: 0,
  notifyDisplay: 'none',
  notifyType: 'default',
  notifyMessage: '',
  notifyIcon: 'check',
  roles: [],
  loading: false,
  loggedInUser: [],
  isLoggedIn: false,
  sessionExpiredModal: false,
  sessionExpiredSource: '',
  passwordModal: false,
  deleteItemId: 0,
  deleteSource: '',
  deleteItemPwd: '',
  regions: [],
  departements: [],
  arrondissements: [],
  ligueUsers: [],
  clubAdmins: [],
  subRoles: [],
  matchs: [],
  tournois: [],
  matriculeCodes: [],
  permissions: [],
  rolePermissions: [],
  signatureModal: false,
  signature: '',
  playerTransfers: [],
};

const reducer = (state = initialState, action) => {
  let nextState;

  switch (action.type) {
    case SIGNIN:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case SIGNUP:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.SAVE_CLUB:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.SAVE_MATCH:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.SAVE_TOURNOIS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.SAVE_USER:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.UPDATE_CLUB:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.UPDATE_MATCH:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.UPDATE_TOURNOIS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.UPDATE_USER:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case AUTH_RESULT:
      nextState = {
        ...state,
        loading: false,
      };

      return nextState || state;

    case constants.GET_REFEREES_SUCCESS:
      nextState = {
        ...state,
        referees: action.referees,
        loading: false,
      };

      return nextState || state;

    case constants.GET_USERS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_COACH_SUCCESS:
      nextState = {
        ...state,
        coachs: action.coachs,
        loading: false,
      };

      return nextState || state;

    case constants.GET_PLAYERS_SUCCESS:
      nextState = {
        ...state,
        players: action.players,
        loading: false,
      };

      return nextState || state;

    case constants.GET_INSCRITS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_CLUB:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_CLUB_SUCCESS:
      nextState = {
        ...state,
        clubs: action.clubs,
        loading: false,
      };

      return nextState || state;

    case constants.GET_ROLE_SUCCESS:
      nextState = {
        ...state,
        code: action.code,
      };

      return nextState || state;

    case constants.GET_GRADE_SUCCESS:
      nextState = {
        ...state,
        grade: action.grade,
      };

      return nextState || state;

    case constants.GET_INSCRITS_SUCCESS:
      nextState = {
        ...state,
        inscrits: action.inscrits,
        loading: false,
      };

      return nextState || state;

    case constants.GET_FEDERATION_SUCCESS:
      nextState = {
        ...state,
        federations: action.federations,
        loading: false,
      };

      return nextState || state;

    case constants.GET_LEAGUE_SUCCESS:
      nextState = {
        ...state,
        leagues: action.leagues,
        loading: false,
      };

      return nextState || state;

    case constants.NOTIFY_SUCCESS:
      nextState = {
        ...state,
        notifyDisplay: '',
        notifyType: 'success',
        notifyMessage: action.notifyMessage,
        notifyIcon: 'check',
      };

      return nextState || state;

    case constants.NOTIFY_ERROR:
      nextState = {
        ...state,
        notifyDisplay: '',
        notifyType: 'danger',
        notifyMessage: action.notifyMessage || 'Une erreur est survenue !',
        notifyIcon: 'exclamation-triangle',
      };

      return nextState || state;

    case constants.GET_ROLES_SUCCESS:
      nextState = {
        ...state,
        roles: action.roles,
      };

      return nextState || state;

    case constants.CLOSE_NOTIFY:
      nextState = {
        ...state,
        notifyDisplay: 'none',
        passwordModal: false,
      };

      return nextState || state;

    case constants.GET_LOGGED_IN_USER_SUCCESS:
      nextState = {
        ...state,
        loggedInUser: action.loggedInUser,
        isLoggedIn: true,
        loading: false,
      };

      return nextState || state;

    case constants.OPEN_SESSION_EXPIRED_MODAL:
      nextState = {
        ...state,
        sessionExpiredModal: true,
        sessionExpiredSource: action.source,
        loading: false,
      };

      return nextState || state;

    case constants.CLOSE_SESSION_EXPIRED_MODAL:
      nextState = {
        ...state,
        sessionExpiredModal: false,
        sessionExpiredSource: '',
      };

      return nextState || state;

    case constants.SHOW_PASSWORD_MODAL:
      nextState = {
        ...state,
        passwordModal: true,
        deleteItemId: action.deleteItemId,
        deleteSource: action.deleteSource,
      };

      return nextState || state;

    case constants.HIDE_PASSWORD_MODAL:
      nextState = {
        ...state,
        passwordModal: false,
      };

      return nextState || state;

    case constants.GET_REGIONS_SUCCESS:
      nextState = {
        ...state,
        regions: action.regions,
      };

      return nextState || state;

    case constants.GET_DEPARTEMENTS_SUCCESS:
      nextState = {
        ...state,
        departements: action.departements,
      };

      return nextState || state;

    case constants.GET_ARRONDISSEMENTS_SUCCESS:
      nextState = {
        ...state,
        arrondissements: action.arrondissements,
      };

      return nextState || state;

    case constants.GET_LIGUE_USERS_SUCCESS:
      nextState = {
        ...state,
        ligueUsers: action.ligueUsers,
      };

      return nextState || state;

    case constants.GET_CLUB_ADMIN_SUCCESS:
      nextState = {
        ...state,
        clubAdmins: action.clubAdmins,
        loading: false,
      };

      return nextState || state;

    case constants.GET_SUB_ROLES_SUCCESS:
      nextState = {
        ...state,
        subRoles: action.subRoles,
        loading: false,
      };

      return nextState || state;

    case constants.GET_MATCHS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_MATCH_SUCCESS:
      nextState = {
        ...state,
        matchs: action.matchs,
        loading: false,
      };

      return nextState || state;

    case constants.GET_TOURNOIS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_TOURNOIS_SUCCESS:
      nextState = {
        ...state,
        tournois: action.tournois,
        loading: false,
      };

      return nextState || state;

    case constants.RESET_ARRONDISSEMENTS:
      nextState = {
        ...state,
        arrondissements: [],
        loading: false,
      };

      return nextState || state;

    case constants.GET_MATRICULE_CODES:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_MATRICULE_CODE_SUCCESS:
      nextState = {
        ...state,
        matriculeCodes: action.matriculeCodes,
        loading: false,
      };

      return nextState || state;

    case constants.GET_DASHBOARD_ELEMENTS_SUCCESS:
      nextState = {
        ...state,
        refereesLength: action.elementsCount.refereesLength,
        coachsLength: action.elementsCount.coachsLength,
        playersLength: action.elementsCount.playersLength,
        clubsLength: action.elementsCount.clubsLength,
        inscritsLength: action.elementsCount.inscritsLength,
        federationsLength: action.elementsCount.federationsLength,
        leaguesLength: action.elementsCount.leaguesLength,
      };

      return nextState || state;

    case constants.GET_PERMISSIONS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_PERMISSION_SUCCESS:
      nextState = {
        ...state,
        permissions: action.permissions,
        loading: false,
      };

      return nextState || state;

    case constants.GET_ROLE_PERMISSIONS:
      nextState = {
        ...state,
        loading: true,
      };

      return nextState || state;

    case constants.GET_ROLE_PERMISSION_SUCCESS:
      nextState = {
        ...state,
        rolePermissions: action.rolePermissions,
        loading: false,
      };

      return nextState || state;

    case constants.SHOW_SIGNATURE_MODAL:
      nextState = {
        ...state,
        signatureModal: true,
      };

      return nextState || state;

    case constants.HIDE_SIGNATURE_MODAL:
      nextState = {
        ...state,
        signatureModal: false,
      };

      return nextState || state;

    case constants.SET_SIGNATURE:
      nextState = {
        ...state,
        signature: action.signature,
        signatureModal: false,
      };

      return nextState || state;

    case constants.GET_PLAYER_TRANSFER_SUCCESS:
      nextState = {
        ...state,
        playerTransfers: action.playerTransfers,
        loading: false,
      };

      return nextState || state;

    default:
      return state;
  }
};

export default reducer;
