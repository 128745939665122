import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  DropdownLarge,
  InputTextLarge,
  Loader,
  SaveButton,
  SaveSpinner,
} from '../../../components';
import { useLocation } from 'react-router-dom';
import {
  getMatchAsync,
  updateMatchAsync,
} from '../../../store/matchs/matchSlice';

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 60,
    width: 60,
    borderRadius: '50%',
    background: 'gray',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  input: {
    height: '45px',
    border: '1px solid #ced4da',
    borderRadius: '8px',
    width: '100%',
    padding: '10px',
  },
};

export default function MatchResultForm() {
  const { data, loading } = useSelector((state) => state.match);
  const [sending, setSending] = useState(false);

  const [info, setInfo] = useState({});
  const tournois = useSelector((state) =>
    Object.values(state.tournois.data || {})
  );
  const location = useLocation();
  const { id } = location.state;
  const dispatch = useDispatch();

  const selectedMatch = useMemo(() => data?.[id] || {}, [data, id]);

  const match = useMemo(() => {
    const m = data?.[id];
    const club1 = m?._participantOne;
    const club2 = m?._participantTwo;
    return { club1, club2, data: m };
  }, [data, id]);

  useEffect(() => {
    if (match) {
      const defaultSet = [1, 2, 3, 4, 5].reduce((acc, num) => {
        acc[`set${num}`] = {
          point: '',
          substitution: '',
          timeout: '',
        };
        return acc;
      }, {});
      const escoreSheet = JSON.parse(match.data?.scoresheet || '{}');

      console.log({ escoreSheet });
      if (escoreSheet?.matchInfo) {
        setInfo(escoreSheet?.matchInfo);
      } else {
        setInfo({
          [match.club1?.id]: defaultSet,
          [match.club2?.id]: defaultSet,
          timeSet1: '',
          timeSet2: '',
          timeSet3: '',
          timeSet4: '',
          timeSet5: '',
          startTime: '',
          endTime: '',
          location: '',
          tournament: '',
        });
      }
    }
  }, [match]);

  const handleSet = (clubId, setNumber) => (ev) => {
    const key = ev.target.name;
    const val = ev.target.value;

    if (key === 'substitution' && Number(val) > 6) {
      alert('nombre maximale de substitutions incorrectes');
      return;
    }
    if (key === 'timeout' && Number(val) > 2) {
      alert('nombre maximale de temps mort incorrectes');
      return;
    }

    setInfo((prev) => {
      return {
        ...prev,
        [clubId]: {
          ...prev[clubId],
          [`set${setNumber}`]: {
            ...prev[clubId][`set${setNumber}`],
            [key]: val,
          },
        },
      };
    });
  };

  const handleChange = (ev) => {
    setInfo((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  };

  const callback = () => {
    setSending(false);
  };

  const save = () => {
    console.log({ info });
    const pointListA = Object.values(info[match.club1.id]).map((set) =>
      set.point === '' ? 0 : Number(set.point)
    );
    const pointListB = Object.values(info[match.club2.id]).map((set) =>
      set.point === '' ? 0 : Number(set.point)
    );
    // console.log({ pointListA, pointListB });

    const toSave = {
      ...selectedMatch,
      id,
      _winner: match.club1.id,
      status: 'PLAYED',
      scoresheet: JSON.stringify({ matchInfo: info }),
    };
    setSending(true);
    dispatch(
      updateMatchAsync({
        match: toSave,
        onSuccess: callback,
        onStop: () => setSending(false),
      })
    );
   
  };

  useEffect(() => {
    dispatch(getMatchAsync({ callback: () => {} }));
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="header p-3">
      <div className="container">
        <div class="row align-items-center py-4">
          <div class="col-lg-12">
            <h6 class="h2 d-inline-block text-uppercase mb-0">
              <span class="">
                <i class="fa fa-plus fa-lg mr-3"></i>
              </span>
              SET MATCH RESULT
            </h6>
            <BackButton />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="row">
          <div
            class="alert alert-primary"
            role="alert"
            style={{ width: '100%' }}
          >
            <span>- Au max 2 temps mort par set</span> <br />
            <span>
              - L’écart de points (minimum 2 entre les deux équipes)
            </span>{' '}
            <br />
            <span> - Nombre de remplacements maximum par set : 6 </span> <br />
            <span>- Les 3 premiers sets sont requis </span>
          </div>
        </div>
        <div
          className="row align-items-center mb-3 gap-2"
          style={{ paddingLeft: '50px', marginTop: '50px' }}
        >
          {/* TEAM A SET */}
          <div className="col-md-6" style={{ paddingRight: '50px' }}>
            <div style={{ ...styles.row, marginBottom: '50px' }}>
              <div
                style={{
                  ...styles.avatar,
                  backgroundImage: `url(${match.club1?.picture})`,
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              <h6 style={{ marginLeft: '20px' }}>
                {match.club1?.name?.toUpperCase()}
              </h6>
            </div>
            <div className="row">
              {[1, 2, 3, 4, 5].map((num) => (
                <div
                  key={num}
                  style={{
                    borderBottom: '1px solid #ced4da',
                    paddingBottom: '30px',
                  }}
                >
                  <h6
                    style={{
                      marginTop: '15px',
                      marginBottom: '20px',
                      fontWeight: 600,
                      color: '#0000000',
                    }}
                  >
                    {' '}
                    SET {num}{' '}
                  </h6>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <label> Total Points</label>
                      <input
                        onChange={handleSet(match?.club1?.id, num)}
                        value={info?.[match?.club1?.id]?.['set' + num]?.point}
                        name="point"
                        type="number"
                        style={styles.input}
                      />
                    </div>
                    <div className="col-md-4">
                      <label> Total Remplacements </label>
                      <input
                        onChange={handleSet(match?.club1?.id, num)}
                        value={
                          info?.[match?.club1?.id]?.['set' + num]?.substitution
                        }
                        type="number"
                        name="substitution"
                        style={styles.input}
                      />
                    </div>
                    <div className="col-md-4">
                      <label> Total temps mort </label>
                      <input
                        onChange={handleSet(match?.club1?.id, num)}
                        type="number"
                        name="timeout"
                        value={info?.[match?.club1?.id]?.['set' + num]?.timeout}
                        style={styles.input}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* TEAM B SET */}

          <div className="col-md-6" style={{ paddingLeft: '50px' }}>
            <div
              className="col-md-12"
              style={{ ...styles.row, marginBottom: '50px' }}
            >
              <div
                style={{
                  ...styles.avatar,
                  backgroundImage: `url(${match.club2?.picture})`,
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              <h6 style={{ marginLeft: '20px' }}>
                {match.club2?.name?.toUpperCase()}
              </h6>
            </div>
            <div className="row">
              {[1, 2, 3, 4, 5].map((num) => (
                <div
                  key={num}
                  style={{
                    borderBottom: '1px solid #ced4da',
                    paddingBottom: '30px',
                  }}
                >
                  <h6
                    style={{
                      marginTop: '15px',
                      marginBottom: '20px',
                      fontWeight: 600,
                      color: '#0000000',
                    }}
                  >
                    {' '}
                    SET {num}{' '}
                  </h6>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <label> Total points </label>
                      <input
                        onChange={handleSet(match?.club2?.id, num)}
                        value={info?.[match?.club2?.id]?.['set' + num]?.point}
                        name="point"
                        type="number"
                        style={styles.input}
                      />
                    </div>
                    <div className="col-md-4">
                      <label> Total Remplacements </label>
                      <input
                        onChange={handleSet(match?.club2?.id, num)}
                        value={
                          info?.[match?.club2?.id]?.['set' + num]?.substitution
                        }
                        type="number"
                        name="substitution"
                        style={styles.input}
                      />
                    </div>
                    <div className="col-md-4">
                      <label> Total temps mort </label>
                      <input
                        onChange={handleSet(match?.club2?.id, num)}
                        type="number"
                        name="timeout"
                        value={info?.[match?.club2?.id]?.['set' + num]?.timeout}
                        style={styles.input}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/*  SET DURATION SECTION  */}
        <div
          style={{
            borderBottom: '1px solid #ced4da',
            paddingBottom: '30px',
          }}
        >
          <h6
            style={{
              marginTop: '15px',
              marginBottom: '20px',
              fontWeight: 600,
              color: '#0000000',
            }}
          >
            DUREE DE CHAQUE SET
          </h6>
          <div
            className="row"
            style={{ paddingTop: '30px', paddingBottom: '30px' }}
          >
            {[1, 2, 3, 4, 5].map((num) => (
              <div className="col" key={num}>
                <label> Durée du set {num} en minute </label>
                <input
                  name={'timeSet' + num}
                  type="number"
                  value={info?.['timeSet' + num]}
                  onChange={handleChange}
                  style={styles.input}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="row">
          <InputTextLarge
            title="heure de debut"
            type="time"
            name="startTime"
            value={info?.startTime}
            id="timeMatch"
            onChange={handleChange}
            required
          />
          <InputTextLarge
            title="heure de fin"
            type="time"
            name="endTime"
            onChange={handleChange}
            value={info?.endTime}
            id="timeMatch"
            required
          />
        </div>
        <div className="row">
          <InputTextLarge
            title="Lieu"
            onChange={handleChange}
            name="location"
            id="timeMatch"
            value={info?.location}
            required
          />
          <DropdownLarge
            title="Tournois"
            onChange={handleChange}
            options={tournois}
            dynamic={true}
            name="tournament"
            value={info?.tournament}
            id="tournois"
            required
          />
        </div>

        <div className="float-right" style={{ paddingBottom: '50px' }}>
          {!sending ? <SaveButton onClick={save} /> : <SaveSpinner />}
        </div>
      </div>
    </div>
  );
}
