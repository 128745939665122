import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  HomeHeader,
  Notify,
  SignatureModal,
  SignupStepOne,
  SignupStepThree,
  SignupStepTwo,
} from "../../components";
import { yupValidation } from "../../utilities";

import useLang from "../../hook/useLang";
import { closeNotify, getRegions } from "../../store/ui";

const Signup = () => {
  const [page, setPage] = useState(1);
  const [picture, setPicture] = useState([]);
  const {regions } = useSelector((state)=>state.ui)

  const dispatch = useDispatch();

  const nextPage = () => {
    if (page < 4) {
      setPage((page) => page + 1);
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };

  //const schema = page === 2 ? yupValidation.reportScamStep2 : yupValidation.reportScamStep3


  useEffect(() => {
    // dispatch(getRoles());
    dispatch(getRegions());
    return () => dispatch(closeNotify());
  }, [dispatch]);
  
  const { values, handleSubmit, touched, handleBlur, errors, handleChange } =
    useFormik({
      initialValues: {
        name: "",
        surname: "",
        dob: "",
        gender: "",
        pob: "",
        familyStatus: "",
        phone: "",
        email: "",
        picture: [],
        profession: "",
        league: "",
        schoolLevel: "",
        _role: "",
        _region: "",
        _departement: "",
        _arrondissement: "",
        adDateObtention: "",
        adLieu: "",
        adFormateur: "",
        adObservation: "",
        arDateObtention: "",
        arLieu: "",
        arFormateur: "",
        arObservation: "",
        anDateObtention: "",
        anLieu: "",
        anFormateur: "",
        anObservation: "",
        aiDateObtention: "",
        aiLieu: "",
        aiFormateur: "",
        aiObservation: "",
      },
      validationSchema: Yup.object().shape(yupValidation.signinYup),
      onSubmit({ setSubmitting }) {
        setTimeout(() => {
          setSubmitting(false);

          if (page === 3) {
            //setLoading(true)
          } else {
            nextPage();
            //Object.keys(values[page + 1]).map(key => { setFieldTouched(key, false, false); });
          }
        }, 400);
      },
    });

  const lang = useLang();

  const [titleName1, setTitleName1] = useState(lang?.entraineur_niveau_3);
  const [titleName2, setTitleName2] = useState(lang?.entraineur_niveau_2);
  const [titleName3, setTitleName3] = useState(lang?.entraineur_niveau_1);
  const [titleName4, setTitleName4] = useState(lang?.entraineur_niveau_0);

  /*
  AD: Departmental Referee
  AR: Regional Referee
  AN: National Referee
  AI: International Referee
  */

  // const handleInputChange = e => {
  //     switch (e.target.id) {

  //         case 'dob':
  //             {
  //                 let date = new Date(e.target.value)
  //                 let newDate = new Date()
  //                 let validAge = newDate.getFullYear() - date.getFullYear()

  //                 if (validAge < 10 || validAge > 99) {
  //                     setValidate(true)
  //                     setDob('')
  //                     setValidateMessage(DATE_VALIDATOR_LOCALS)
  //                 } else {
  //                     setDob(e.target.value)
  //                     setValidate(false)
  //                 }
  //             }
  //             break;

  //         case 'role':
  //             toggleRoleTitle(e.target.value)
  //             break;

  //         case '_region':
  //             dispatch(getDepartements(e.target.value));
  //             setRegion(e.target.value)
  //             dispatch(resetArrondissements())
  //             break;
  //         case '_departement':
  //             dispatch(getArrondissements(e.target.value));
  //             setDepartement(e.target.value)
  //             break;
  //         case '_arrondissement':
  //             setArrondissement(e.target.value)
  //             break;

  //         default:
  //             break;
  //     }

  // }

  // const handleSubmit = e => {

  //     e.preventDefault();

  //     let user = {
  //         name, surname, dob,
  //         gender, pob, familyStatus,
  //         phone, email, picture: picture.base64,
  //         profession, schoolLevel,
  //         league, _role, state: false,
  //         _region, _departement, _arrondissement,
  //         signature: adminState.signature
  //     }

  //     let arbitreDepartemental = {
  //         name: titleName1,
  //         date: adDateObtention,
  //         place: adLieu,
  //         instructor: adFormateur,
  //         observation: adObservation
  //     }

  //     let arbitreRegional = {
  //         name: titleName2,
  //         date: arDateObtention,
  //         place: arLieu,
  //         instructor: arFormateur,
  //         observation: arObservation
  //     }

  //     let arbitreNational = {
  //         name: titleName3,
  //         date: anDateObtention,
  //         place: anLieu,
  //         instructor: anFormateur,
  //         observation: anObservation
  //     }

  //     let arbitreInternational = {
  //         name: titleName4,
  //         date: aiDateObtention,
  //         place: aiLieu,
  //         instructor: aiFormateur,
  //         observation: aiObservation
  //     }

  //     if (adDateObtention != '' && adLieu != '' &&
  //         adFormateur != '' && adObservation != '') {
  //         user = {
  //             ...user,
  //             arbitreDepartemental
  //         }
  //     }

  //     if (arDateObtention != '' && arLieu != '' &&
  //         arFormateur != '' && arObservation != '') {
  //         user = {
  //             ...user,
  //             arbitreRegional
  //         }
  //     }

  //     if (anDateObtention != '' && anLieu != '' &&
  //         anFormateur != '' && anObservation != '') {
  //         user = {
  //             ...user,
  //             arbitreNational
  //         }
  //     }

  //     if (aiDateObtention != '' && aiLieu != '' &&
  //         aiFormateur != '' && aiObservation != '') {
  //         user = {
  //             ...user,
  //             arbitreInternational
  //         }
  //     }

  //     if (picture.length === 0) {
  //         alert('Please add a picture')
  //     } else if (!name.trim() || !surname.trim()) {
  //         alert('Please enter a valid name')
  //     } else if (adminState.signature === '') {
  //         alert('Veuillez ajouter une SIGNATURE')
  //     } else {
  //         dispatch(signup(user, history))
  //     }

  // }

  return (
    <div
      style={{
        backgroundImage: `url(/assets/images/bg.jpg)`,
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "auto",
      }}
    >
      <HomeHeader />
      <div
        style={{ backgroundColor: "rgba( 0, 0, 0, 0.5 )", minHeight: "100vh" }}
      >
        <section class="pt-5">
          <div
            class="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="row mt-5">
              <div class="card">
                <div
                  class="card-header"
                  style={{ padding: 10, backgroundColor: "#212629" }}
                >
                  <h1
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      letterSpacing: 2,
                    }}
                  >
                    {lang?.sign_up}
                  </h1>
                </div>
                <div class="card-body">
                  <Notify />
                  <form onSubmit={handleSubmit}>
                    <div>
                      <ul className="progressbar">
                        <li className={page === 1 && "active"}></li>
                        <li className={page === 2 && "active"}></li>
                        <li className={page === 3 && "active"}></li>
                      </ul>
                      <hr className="my-0 mb-4" />
                    </div>

                    {page === 1 && (
                      <SignupStepOne
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        nextPage={() => nextPage()}
                        touched={touched}
                        handleBlur={handleBlur}
                      />
                    )}
                    {page === 2 && (
                      <SignupStepTwo
                        values={values}
                        handleChange={handleChange}
                        setPicture={setPicture}
                        errors={errors}
                        left={() => previousPage()}
                        nextPage={() => nextPage()}
                        touched={touched}
                        handleBlur={handleBlur}
                        regions={regions}

                      />
                    )}
                    {page === 3 && (
                      <SignupStepThree
                        values={values}
                        handleChange={handleChange}
                        titleName1={titleName1}
                        titleName2={titleName2}
                        titleName3={titleName3}
                        titleName4={titleName4}
                        errors={errors}
                        left={() => previousPage()}
                        touched={touched}
                        handleBlur={handleBlur}
                      />
                    )}

                    {/* {!adminState.loading ? <div style={{ textAlign: 'center' }}>
                                            <button style={{ backgroundColor: '#00387B', color: '#fff' }} type="submit" class="btn rounded ">
                                                Je m'inscris </button>
                                            <Link to='signin' class="btn rounded ">
                                                Se connecter </Link>
                                        </div> :
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button style={{ backgroundColor: '#00387B', color: '#fff' }} type="button" class="btn btn-sm ">
                                                        <i class="fa fa-spinner fa-spin fa-2x"></i></button>

                                                </div>
                                            </div>} */}
                  </form>
                </div>
                <div class="card-footer">
                  <div className="row">
                    <div className="col-md-3">
                      <a
                        href="https://cybexai.com"
                        class="font-weight-bold mr-5"
                        target="_blank"
                      >
                        <img src="/assets/images/cybex_logo.png" height="30" />
                      </a>
                    </div>

                    <div className="col-md-9">
                      <a style={{ float: "right" }} className="text">
                        {lang?.termsAndConditions}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <SignatureModal />
    </div>
  );
};

export default Signup;
