export const authFr = {
  sign_in: "Connexion",
  connect: "Se Connecter",
  sign_up: "S'inscrire",
  iSignUp: "Je m'inscris",
  password: "Mot de passe",
  email: "email",
  name: "Nom",
  surname: "Prénom",
  dateOfBirth: "Date De Naissance",
  placeOfBirth: "Lieu De Naisance",
  gender: "Sexe",
  maritalStatus: "Status Matrimonial",
  profession: "Profession",
  league: "league",
  termsAndConditions: "Confidentialité & Conditions d'utilisations",
  phone: "Téléphone",
  schoolLevel: "Niveau Scolaire",
  next: "Suivant",
  previous: "precédent",
  iAm: "Je suis",
  region: "Region",
  department: "Departement",
  district: "Arrondissement",
  creationDate: "creation Date",
  observation: "Observation",
  dateObtained: "Date D'obtention",
  location: "Location",
  instructor: "Instructeur",
  entraineur_niveau_0: "Arbitre International",
  entraineur_niveau_1: "Arbitre National",
  entraineur_niveau_2: "Arbitre Regional",
  entraineur_niveau_3: "Arbitre Departemental",
  save: "Enregister",
  close: "Quitter",
  professionalInfo: "informations Professionelles",
  add_image: "Ajouter Une Photo",
};
