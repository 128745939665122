import React from "react";

const TeamHeader = ({selectedMatch}) => (
    <div className="row justify-content-between mt-3">
      <div className="col-md-6">
        <h2 className="text-center">
          <b>{selectedMatch?._participantOne?.name}</b>
        </h2>
      </div>
      <div className="col-md-6">
        <h2 className="text-center">
          <b>{selectedMatch?._participantTwo?.name}</b>
        </h2>
      </div>
    </div>
  );
export default TeamHeader