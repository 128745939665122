import { createSlice } from '@reduxjs/toolkit';
import { SERVER } from '../../utilities';
import axios from 'axios';
import * as API from '../../api/api';
import { notifyError, notifySuccess, openSessionExpiredModal } from '../ui';

const matchSlice = createSlice({
  name: 'match',
  initialState: {
    data: {},
    loaded: false,
    loading: false,
    sending: false,
  },
  reducers: {
    initMatch: (state, { payload }) => {
      // console.log("init of match list match",payload)
      // eslint-disable-next-line no-unused-expressions
      payload?.forEach((match) => {
        state.data[match?.id] = match;
      });
      state.loaded = true;
    },
    initStopLoading: (state) => {
      state.loading = !state.loading;
    },
  },
  extraReducers: (builder) => {},
});

const matchReducer = matchSlice.reducer;

export const { initMatch, initStopLoading } = matchSlice.actions;
export default matchReducer;

export const saveMatchAsync =
  ({ match, onSuccess = () => {}, onStop = () => {} }) =>
  async (dispatch, getState) => {
    try {
      console.log('inside save match', match);
      const response = await API.saveMatch(match);

      if (String(response).substring(String(response).length - 3) === '401') {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 201) {
          onSuccess();
          dispatch(notifySuccess('Save Successful !'));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log('error when save match', error);
    } finally {
      onStop();
    }
  };

export const getMatchAsync = () => async (dispatch, getState) => {
  if (!getState().match.loaded) {
    dispatch(initStopLoading());
    try {
      console.log('get match here');
      const response = await API.getMatchs();
      console.log('match response', response);

      if (String(response).substring(String(response).length - 3) === '401') {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          console.log('init of Match', response.data);
          dispatch(initMatch(response.data));
        } else {
          dispatch(
            notifyError(
              "une erreur s'est s'est produite au moment de la lecture de la liste des matchs"
            )
          );
        }
      }
    } catch (error) {
      console.log('error when getMatch', error);
    } finally {
      dispatch(initStopLoading());
    }
  } else {
    console.log('match only get ');
  }
};

export const getMatchsByTournois = (tournoisId) => {
  return axios
    .get(SERVER + 'matchs-tournois/' + tournoisId, {
      headers: {
        'cybexai-fcvb-token': localStorage.getItem('fvAccessToken'),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((response) => {
      return response;
    });
};

export const deleteMatchAsync =
  ({ id, pwd }) =>
  async (dispatch, getState) => {
    try {
      let user = {
        pwd,
        accessToken: localStorage.getItem('fvAccessToken'),
      };

      const checkPasswordResponse = await API.checkPassword(user);

      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === '401'
      ) {
        dispatch(openSessionExpiredModal());
        dispatch(notifyError('Email or password incorrect. '));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deleteMatch(id);

          if (response.status === 200) {
            dispatch(notifySuccess('Delete Successful !'));
            // yield put(actions.getMatchs());
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateMatchAsync =
  ({ match, onSuccess = () => {}, onStop = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.updateMatch(match);

      if (String(response).substring(String(response).length - 3) === '401') {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(notifySuccess('Update Successful !'));
          onSuccess(response);
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log('error when update match', error);
    } finally {
      onStop();
    }
  };
