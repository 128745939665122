import React from "react";
import { DropdownLarge, FormButton, InputTextLarge } from ".";
import useLang from "../hook/useLang";
import { DropdownConstants } from "../utilities";

const SignupStepOne = ({
  values,
  handleChange,
  errors,
  nextPage,
  left,
  touched,
  handleBlur,
  roles,
  ...rest
}) => {
  const lang = useLang();

  return (
    <>
      <div class="row">
        <InputTextLarge
          title={lang?.name}
          name="name"
          id="name"
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.name}
        />
        <InputTextLarge
          title={lang?.surname}
          name="surname"
          id="surname"
          value={values.surname}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.surname}
        />
      </div>
      <div class="row">
        <InputTextLarge
          title={lang?.dateOfBirth}
          type="date"
          name="dob"
          id="dob"
          value={values.dob}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.dob}
        />

        <InputTextLarge
          title={lang?.placeOfBirth}
          name="pob"
          id="pob"
          value={values.pob}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.pob}
        />
      </div>
      <div class="row">
        <DropdownLarge
          title={lang?.gender}
          options={DropdownConstants.GENDER}
          name="gender"
          id="gender"
          value={values.gender}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.gender}
        />

        <DropdownLarge
          title={lang?.maritalStatus}
          options={DropdownConstants.FAMILY_STATUS}
          name="familyStatus"
          id="familyStatus"
          value={values.familyStatus}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.familyStatus}
        />
      </div>
      <div class="row">
        <InputTextLarge
          title={lang?.profession}
          name="profession"
          id="profession"
          value={values.profession}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.profession}
        />

        <DropdownLarge
          title={lang?.schoolLevel}
          options={DropdownConstants.SCHOOL_LEVEL}
          name="schoolLevel"
          id="schoolLevel"
          value={values.schoolLevel}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.schoolLevel}
        />
      </div>
      <div class="row">
        <DropdownLarge
          title={lang?.league}
          options={DropdownConstants.LEAGUE}
          name="league"
          id="league"
          value={values.league}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.league}
        />

        <InputTextLarge
          title={lang?.phone}
          name="phone"
          id="phone"
          value={values.phone}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.phone}
        />
      </div>
      <div class="row">
        <InputTextLarge
          title={lang?.email}
          name="email"
          id="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.email}
        />

        <DropdownLarge
          title={lang?.iAm}
          options={roles}
          dynamic={true}
          name="role"
          id="role"
          value={values._role}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors._role}
        />
      </div>

      <div className="text-right">
        <FormButton
          title={lang?.next}
          iconRight="chevron-right"
          onClick={nextPage}
        />
      </div>
    </>
  );
};

export default SignupStepOne;
