import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  BackButton,
  CardContainer,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  Loader,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals,} from "../../../utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfoAsync, saveUserAsync, updateUserAsync } from "../../../store/users/userSlice";
import { updatePlayer } from "../../../store/players/playerSlice";
import { getArrondissment, getDepartment } from "../../../store/ui";
const requiredFields = ["name", "surname","dob","pob","gender", "_club",""]

const PlayerFormAdmin = (props) => {
  const location = useLocation();
  const [loading , setLoading] = useState(false)
  const [sending , setSending] = useState(false)
  const {userId} = location.state;
  const {data:playerList} = useSelector((state)=>state.player)
  const {user:loggedInUser } = useSelector((state) => state.auth)
  const user = useMemo(()=>playerList[userId],[playerList, userId])
  const {data:club} = useSelector((state) => state.club);
  const clubList = useMemo(()=>Object.values(club),[club]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    matriculeCodeList,
    regions,
    department: departmentList,
    district: districtList,
    roles:roleList
  } = useSelector((state) => state.ui);

  const [data , setData] = useState({
    name: "",
    state: true,
    surname: "",
    dob: "",
    pob: "",
    gender: "",
    auxiliaire: false,
    matricule: "",
    _club: "",
    _region: "",
    _departement: "",
    _arrondissement: "",
    familyStatus: "",
    profession: "",
    schoolLevel: "",
    league: "",
    phone: "",
    email: "",
    conseilAdministration: false,
    commission: false,
    dossard: "",
  })
  const [picture, setPicture] = useState([]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if(userId && !user?.hasAllInfo){
      setLoading(true)
      dispatch(getUserInfoAsync({
        userId,
        onSuccess:(data)=>{
          dispatch(updatePlayer({...data, hasAllInfo:true }))
          setLoading(false)
        }

      }))
    }
  }, [dispatch, user, userId]);

  

  useEffect(()=>{
    if(user?.hasAllInfo){
      const localData = {...user}
      

      delete localData?.grade
      delete localData?._role
      delete localData?.clubs
      setData((prev)=>({
        ...prev,
        ...localData,
        _region:localData?._region?.id || "",
        _arrondissement:localData?._arrondissement?.id || "",
        _departement:localData?._departement?.id || "",
        _club:user?.clubs?.[0]?.id || ""
      }))
      setPicture(localData?.picture)
    }
    // load league
    const ligue = localStorage.getItem("fvApp");
    if (ligue !== locals.ADMIN_LOCALS) {
      setData((prev)=>({...prev, league:loggedInUser.league}))
      setDisabled(true);
    }
  },[loggedInUser.league, user]);


  useEffect(() => {
    if (data._region !== "") {
      dispatch(getDepartment({ regionId: data._region }));
    }
  }, [data._region, dispatch]);

  useEffect(() => {
    if (data._departement !== "") {
      // console.log("departmentid", data._departement);
      dispatch(getArrondissment({ departmentId: data._departement }));
    }
  }, [data._departement, dispatch]);


  const onSuccess = (data)=>{
    dispatch(updatePlayer(data))
    navigate(-1)
    
  }
  const onStop = ()=>setSending(false)

  const handleInputChange = (e) => {
    
    setData((prev) => {
      if (e.target.id === "dob") {
        let date = new Date(e.target.value);
        let newDate = new Date();
        let validAge = newDate.getFullYear() - date.getFullYear();
        if (validAge < 10 || validAge > 99) {
          // setValidate(true);
          // setValidateMessage(DATE_VALIDATOR_LOCALS);
          return { ...prev, dob: "" };
        } else {
          // setValidate(false);
          return { ...prev, dob: e.target.value };
        }
      }

      if (
        [
          "arbitreDepartemental",
          "arbitreNational",
          "arbitreRegional",
          "arbitreInternational",
        ].includes(e.target.id)
      ) {
        return {
          ...prev,
          [e.target.id]: {
            ...prev[e.target.id],
            [e.target.name]: e.target.value,
          },
        };
      }

      if(["auxiliaire","normal"].includes(e.target.id)){
        console.log("includes",e.target.id === "auxiliaire")
        return{
          ...prev,
          auxiliaire:e.target.id === "auxiliaire"
        };
      }
      
      if (["conseilAdministration", "commission"].includes(e.target.id)) {
        return {
          ...prev,
          [e.target.id]: !prev[e.target.id],
        };
      }

      return { ...prev, [e.target.id]: e.target.value };
    });
  };


  // const {
  //   values,
  //   handleSubmit,
  //   getFieldProps,
  //   setValues,
  //   errors,
  //   setFieldValue,
  //   data,
  // } = useFormik({
  //   initialValues: {
  //     name: "",
  //     state: true,
  //     surname: "",
  //     dob: "",
  //     pob: "",
  //     gender: "",
  //     auxiliaire: false,
  //     matricule: "",
  //     _club: "",
  //     _region: "",
  //     _departement: "",
  //     _arrondissement: "",
  //     familyStatus: "",
  //     profession: "",
  //     schoolLevel: "",
  //     league: "",
  //     phone: "",
  //     email: "",
  //     conseilAdministration: false,
  //     commission: false,
  //     id: "",
  //     dossard: "",
  //   },
  //   //validationSchema: Yup.object().shape(yupValidation.userYup),
    

  //   onSubmit(values) {
  //     let user = values;
  //     let code = roleList.find((role)=>role.code === "JOUER").id
  //     user = {
  //       ...user,
  //       signature:"",
  //       _role:code,
  //       matriculeCode: values.matricule,
  //     };

  //     if (picture.base64 === undefined) {
  //       user = {
  //         ...user,
  //         picture: picture,
  //       };
  //     } else {
  //       user = {
  //         ...user,
  //         picture: picture.base64,
  //       };
  //     }

  //     if (picture.length === 0) {
  //       alert("Please add a picture");
  //     } else {
  //       if (values.id === "") {
  //         dispatch(saveUserAsync({user, onSuccess , onStop}));
  //       } else {
  //         dispatch(updateUserAsync({user, onSuccess, onStop}));
  //       }
  //     }
  //   },
  // });


  const handleSubmit = (e) => {
    e.preventDefault();
    let user = {
      ...data, 
      _role:roleList.find((role)=>role.code === "JOUER").id,
      signature:""
    }

    if (picture?.base64 === undefined) {
      user = {
        ...user,
        picture: picture,
      };
    } else {
      user = {
        ...user,
        picture: picture?.base64,
      };
    }


    if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (!data.name.trim() || !data.surname.trim()) {
      alert("Please enter a valid name");
    } else if (data.matricule === "" && data?.id === "") {
      alert("Please choose a MATRICULE CODE");
    } else {
      console.log("user to send", user)
      setSending(true)
      !data?.id
        ? dispatch(saveUserAsync({user,onSuccess,onStop}))
        : dispatch(updateUserAsync({user,onSuccess,onStop}));
    }
  };
  if(loading){
    return <Loader />
  }

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <form onSubmit={handleSubmit} className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!userId ? "ADD" : "EDIT"} A PLAYER
              </h6>
              <BackButton />
              <h6 className="float-right">
                {!loading ? <SaveButton /> : <SaveSpinner />}
              </h6>
            </div>
          </div>

          <>
            <CardContainer>
              <h4 className="font-weight-bold mb-4">General information</h4>
              <div className="row">
                <InputTextLarge
                  title="Name"
                  name="name"
                  id="name"
                  // error={data.name && errors.name}
                  // {...getFieldProps("name")}
                  value={data.name}
                  onChange={handleInputChange}
                  required
                />
                <InputTextLarge
                  title="Surname"
                  name="surname"
                  id="surname"
                  value={data.surname}
                  onChange={handleInputChange}
                  // error={data.surname && errors.surname}
                  // {...getFieldProps("surname")}
                  required
                />
              </div>

              <div className="row">
                <InputTextLarge
                  title="Date of birth"
                  type="date"
                  name="dob"
                  id="dob"
                  value={data.dob}
                  onChange={handleInputChange}
                  // error={data.dob && errors.dob}
                  // {...getFieldProps("dob")}
                  required
                />
                <InputTextLarge
                  title="Place of birth"
                  name="pob"
                  id="pob"
                  value={data?.pob}
                  onChange={handleInputChange}
                  // error={data.pob && errors.pob}
                  // {...getFieldProps("pob")}
                  required
                />
              </div>

              <div className="row">
                <DropdownLarge
                  title="Gender"
                  options={DropdownConstants.GENDER}
                  name="gender"
                  id="gender"
                  value={data.gender}
                  onChange={handleInputChange}
                  // error={data.gender && errors.gender}
                  // {...getFieldProps("gender")}
                  required
                />
                <InputTextLarge
                  title="Dossard"
                  name="dossard"
                  id="dossard"
                  value={data.dossard}
                  onChange={handleInputChange}
                  // error={data.dossard && errors.dossard}
                  // {...getFieldProps("dossard")}
                  //required
                />
              </div>

              <div className="row">
                {matriculeCodeList?.length !== 0 && !user?.id
                  ? <div class="col-lg-6">
                  <div class="form-group">
                    <label for="matricule">Code Matricule</label>{" "}
                    <span class="text-danger ml-1">*</span>
                    <select
                      // className={
                      //   data.matricule && errors.matricule
                      //     ? "browser-default custom-select is-invalid"
                      //     : "browser-default custom-select"
                      // }
                      className="browser-default custom-select"
                      name="matricule"
                      id="matricule"
                      value={data.matricule}
                      onChange={handleInputChange}
                      // {...getFieldProps("matricule")}
                    >
                      <option value="" disabled selected>
                        Choose an option
                      </option>
                      {matriculeCodeList?.map((e, index) => {
                        return (
                          <option key={index} value={e.code}>
                            {e.code}
                          </option>
                        );
                      })}
                    </select>
                    {/* {data.matricule && errors.matricule && (
                      <small className="text-danger ml-1">{errors.matricule}</small>
                    )} */}
                  </div>
                </div>
                  : null}
                {clubList?.length !== 0 ? (
                  <DropdownLarge
                    title="Club"
                    options={clubList}
                    dynamic={true}
                    name="_club"
                    id="_club"
                    value={data._club}
                    onChange={handleInputChange}
                    // error={data._club && errors._club}
                    // {...getFieldProps("_club")}
                    required
                  />
                ) : null}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>{"Normal or Auxiliary Player ?"}</label>{" "}
                    <span className="text-danger ml-1">*</span>
                    <br />
                    <Checkbox
                      title="Normal"
                      id="normal"
                      onChange={handleInputChange}
                      checked={!data.auxiliaire}
                    />
                    <Checkbox
                      title="Auxiliary"
                      id="auxiliaire"
                      onChange={handleInputChange}
                      checked={data.auxiliaire}
                    />
                  </div>
                </div>
              </div>
            </CardContainer>

            <CardContainer>
              <div className="row">
                {picture !== null ? (
                  <div className="col-lg-6">
                    <div>
                      <label for={"Photo"}>{"Photo"}</label>{" "}
                      <span className="text-danger ml-1">*</span>
                      <br />
                      <div className="row">
                        {picture.length !== 0 && (
                          <div className="col">
                            {picture.base64 === undefined ? (
                              <img
                                src={picture}
                                height={50}
                                width={50}
                                alt="pic"
                              />
                            ) : (
                              <img
                                src={picture.base64}
                                height={50}
                                width={50}
                                alt="pic"
                              />
                            )}
                          </div>
                        )}
                        <div className="col-md-10" style={{ marginTop: -19 }}>
                          <InputPicture
                            placeholder="Choose another picture"
                            onDone={setPicture.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <InputPicture onDone={setPicture.bind(this)} />
                  </div>
                )}

                <DropdownLarge
                  title="Region"
                  options={regions}
                  dynamic={true}
                  name="_region"
                  id="_region"
                  value={data._region}
                  // error={data._region && errors._region}
                  // {...getFieldProps("_region")}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="row">
                <DropdownLarge
                  title="Departement"
                  options={departmentList?.[data._region] || []}
                  dynamic={true}
                  name="_departement"
                  id="_departement"
                  value={data._departement}
                  // error={data._departement && errors._departement}
                  // {...getFieldProps("_departement")}
                  onChange={handleInputChange}
                  required
                />
                <DropdownLarge
                  title="District"
                  options={districtList?.[data._departement] || []}
                  dynamic={true}
                  name="_arrondissement"
                  id="_arrondissement"
                  value={data._arrondissement}
                  // error={data._arrondissement && errors._arrondissement}
                  // {...getFieldProps("_arrondissement")}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </CardContainer>

            <CardContainer>
              <div className="row">
                <Dropdown
                  title="Marital status"
                  options={DropdownConstants.FAMILY_STATUS}
                  name="familyStatus"
                  id="familyStatus"
                  value={data.familyStatus}
                  onChange={handleInputChange}
                  // error={data.familyStatus && errors.familyStatus}
                  // {...getFieldProps("familyStatus")}
                  required
                />
                <InputText
                  title="Profession"
                  name="profession"
                  id="profession"
                  value={data.profession}
                  onChange={handleInputChange}
                  // error={data.profession && errors.profession}
                  // {...getFieldProps("profession")}
                />
                <Dropdown
                  title="Education level"
                  options={DropdownConstants.SCHOOL_LEVEL}
                  name="schoolLevel"
                  id="schoolLevel"
                  value={data.schoolLevel}
                  onChange={handleInputChange}
                  // error={data.schoolLevel && errors.schoolLevel}
                  // {...getFieldProps("schoolLevel")}
                  required
                />
              </div>

              <div className="row">
                <Dropdown
                  title="League"
                  options={DropdownConstants.LEAGUE}
                  name="league"
                  id="league"
                  value={data.league}
                  onChange={handleInputChange}
                  // error={data.league && errors.league}
                  // {...getFieldProps("league")}
                  disabled={disabled}
                  required
                />
                <InputText
                  title="Phone"
                  type="number"
                  name="phone"
                  id="phone"
                  value={data.phone}
                  onChange={handleInputChange}
                  // error={data.phone && errors.phone}
                  // {...getFieldProps("phone")}
                />
                <InputText
                  title="Email"
                  type="email"
                  name="email"
                  id="email"
                  value={data.email}
                  onChange={handleInputChange}
                  // error={data.email && errors.email}
                  // {...getFieldProps("email")}
                />
              </div>
            </CardContainer>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <CardContainer>
              <div className="row">
                {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                <div className="col d-flex align-items-center">
                  <Checkbox
                    title=""
                    id="conseilAdministration"
                    checked={data.conseilAdministration}
                    onChange={handleInputChange}
                    // error={
                    //   data.conseilAdministration &&
                    //   errors.conseilAdministration
                    // }
                    // {...getFieldProps("conseilAdministration")}
                  />
                  <label>Member of the Board</label>
                </div>

                <div className="col d-flex align-items-center">
                  <Checkbox
                    title=""
                    id="commission"
                    checked={data.commission}
                    onChange={handleInputChange}
                    // error={data.commission && errors.commission}
                    // {...getFieldProps("commission")}
                  />
                  <label>Member of the Commission</label>
                </div>
              </div>
            </CardContainer>

            <div className="float-right">
              {!sending ? <SaveButton /> : <SaveSpinner />}
            </div>
          </>
        </form>
      </div>
    </div>
  );
};

export default PlayerFormAdmin;
