// import {  combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
// import rootSaga from "./rootSaga";
import adminReducer from "../screens/admin/reducers";
import { configureStore } from "@reduxjs/toolkit";
import langReducer from "./langSlice";
import uiReducer from "./ui";
import matchReducer from "./matchs/matchSlice";
import tournoisReducer from "./tournois/tournoisSlice";
import authReducer from "./auth/authSlice";
import playerReducer from "./players/playerSlice";
import coachReducer from "./coach/coachSlice";
import refereeReducer from "./referee/refereeSlice";
import clubReducer from "./clubs/clubSlice";
import enrolledReducer from "./enrolled/enrolledSlice";
import federationReducer from "./federation/federationSlice";
import leagueReducer from "./league/leagueSlice";

const store = () => {
  const sagaMiddleWare = createSagaMiddleware();
  const storeApp = configureStore({
    reducer: {
      adminState: adminReducer,
      match:matchReducer,
      lang: langReducer,
      tournois: tournoisReducer,
      ui: uiReducer,
      auth:authReducer,
      player:playerReducer,
      referee:refereeReducer,
      coach:coachReducer,
      club:clubReducer,
      enrolled:enrolledReducer,
      federation:federationReducer,
      league:leagueReducer
    
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        sagaMiddleWare,
      ]),
    devTools: process.env.NODE_ENV !== "production",
  });
  return storeApp
  // return {
  //   ...storeApp,
  //   runSaga: sagaMiddleWare.run(rootSaga),
  // };
};

export default store;
