import React from "react";

const ETopBar = ({
  team1,
  team2,
  timer,
  team1Score,
  team2Score,
  teamColors,
}) => {
  return (
    <div className="top-bar">
      <div
        className="figures-left"
        style={{ backgroundColor: teamColors[team1?.id] }}
      >
        <span>A</span>
      </div>
      <img
        src={team1?.picture || "/assets/images/no-image.png"}
        alt="logo"
        width="40"
        height="40"
      />
      <div className="team">{team1?.name}</div>
      <div className="middle-figures">
        <div className="figures-left">
          <span>{team1Score}</span>
        </div>
        <div className="timer">
          <span>{timer}</span>
        </div>
        <div className="figures-right">
          <span>{team2Score}</span>
        </div>
      </div>
      <div className="team">{team2?.name}</div>
      <img
        src={team2?.picture || "/assets/images/no-image.png"}
        alt="logo"
        width="40"
        height="40"
      />
      <div
        className="figures-right"
        style={{ backgroundColor: teamColors[team2?.id] }}
      >
        <span>B</span>
      </div>
    </div>
  );
};

export default ETopBar;
