import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  Loader,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants } from "../../../utilities";
import { DATE_VALIDATOR_LOCALS } from "../../../utilities/locals";
// import {
//   getRole,
//   updateUser,
//   saveUser,
//   getDepartements,
//   getArrondissements,
//   getRegions,
//   resetArrondissements,
//   getMatriculeCodes,
//   setSignature,
// } from "../../../screens/admin/actions";
// import { getUser } from "../../../screens/admin/apis";
import { getUserInfoAsync, saveUserAsync, updateUserAsync } from "../../../store/users/userSlice";
import { getArrondissment, getDepartment, getSubRole } from "../../../store/ui";
import { initFederationInfo } from "../../../store/federation/federationSlice";

const FederationForm = (props) => {
  let { userId } = useLocation().state;

  const { user: loggedInUser } = useSelector((state) => state.auth);
  const {data:federationList} = useSelector((state) => state.federation);
  const {
    matriculeCodeList,
    regions,
    department: departmentList,
    district: districtList,
    roles:roleList,
    subRole,
  } = useSelector((state) => state.ui);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [picture, setPicture] = useState(null);
  const [validate, setValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [sending , setSending] = useState(false)

  const user = useMemo(() => {
    return federationList?.[userId];
  }, [federationList, userId]);

  const [data, setData] = useState({
    name: "",
    surname: "",
    dob: "",
    pob: "",
    matricule: "",
    gender: "",
    familyStatus: "",
    profession: "",
    schoolLevel: "",
    league: "",
    phone: "",
    email: "",
    state: true,
    matriculeCode: "",
    _region: "",
    _departement: "",
    _arrondissement: "",
    _subRole:"",
    conseilAdministration: "",
    commission: "",
    signature: loggedInUser?.signature,
    
  });

  const onLoad = useCallback(
    (data) => {
      setLoading(false);
      dispatch(initFederationInfo({...data, hasAllInfo:true}));
    },
    [dispatch]
  );

  useEffect(() => {
    if (userId && !user?.hasAllInfo) {
      setLoading(true);
      dispatch(
        getUserInfoAsync({
          userId: userId,
          onSuccess: onLoad,
          onError: () => setLoading(false),
        })
      );
    }
  }, [onLoad, dispatch, user, userId, loggedInUser.league]);

  useEffect(()=>{
    dispatch(getSubRole({role:"FEDER"}))
  },[dispatch])

  useEffect(() => {
    if (user?.hasAllInfo) {
      const localData = {...user}
     
      setData((prev) => ({
        ...prev,
        ...localData,
        _region: localData?._region?.id,
        _departement: localData?._departement?.id,
        _arrondissement: localData?._arrondissement?.id,
      }));
      setPicture(localData?.picture)
    }
  }, [loggedInUser.league, user, userId]);

  useEffect(() => {
    if (data._region !== "") {
      dispatch(getDepartment({ regionId: data._region }));
    }
  }, [data._region, dispatch]);

  useEffect(() => {
    if (data._departement !== "") {

      dispatch(getArrondissment({ departmentId: data._departement }));
    }
  }, [data._departement, dispatch]);


  const handleInputChange = (e) => {

    setData((prev) => {
      if (e.target.id === "dob") {
        let date = new Date(e.target.value);
        let newDate = new Date();
        let validAge = newDate.getFullYear() - date.getFullYear();
        if (validAge < 10 || validAge > 99) {
          setValidate(true);
          setValidateMessage(DATE_VALIDATOR_LOCALS);
          return { ...prev, dob: "" };
        } else {
          setValidate(false);
          return { ...prev, dob: e.target.value };
        }
      }

      
      if (["conseilAdministration", "commission"].includes(e.target.id)) {
        return {
          ...prev,
          [e.target.id]: !prev[e.target.id],
        };
      }

      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const onSuccess = (data) => {
    dispatch(initFederationInfo(data));
    
    navigate(-1);
  };
  const onStop = ()=>{
    setSending(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let toSend = {
      ...data,
      _role:roleList.find((role)=>role.code === "FEDER").id,
      signature: loggedInUser.signature,
      picture:picture?.base64 || picture
    };

    if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (!data.name.trim() || !data.surname.trim()) {
      alert("Please enter a valid name");
    } else if (data.matriculeCode === "" && !data.id) {
      alert("Please choose a MATRICULE CODE");
    } else {
      console.log("user", toSend)
      setSending(true)
      !data?.id
        ? dispatch(saveUserAsync({user:toSend, onSuccess,onStop}))
        : dispatch(updateUserAsync({user:toSend, onSuccess,onStop}));
    }
  };

  if(loading){
    return <Loader />
  }

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {location.state === undefined ? "ADD" : "EDIT"} A FEDERATION
                MEMBER
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="font-weight-bold mb-4">General information</h4>
                    <div class="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={data.name}
                        required
                      />
                      <InputTextLarge
                        title="Surname"
                        name="surname"
                        id="surname"
                        onChange={handleInputChange}
                        value={data.surname}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        validate={data.validate}
                        validateMessage={validateMessage}
                        title="Date of birth"
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleInputChange}
                        value={data.dob}
                        required
                      />

                      <InputTextLarge
                        title="Place of birth"
                        name="pob"
                        id="pob"
                        onChange={handleInputChange}
                        value={data.pob}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Gender"
                        options={DropdownConstants.GENDER}
                        name="gender"
                        id="gender"
                        onChange={handleInputChange}
                        value={data.gender}
                        required
                      />

                      <DropdownLarge
                        title="Role"
                        options={subRole?.["FEDER"] || []}
                        dynamic={true}
                        name="role"
                        id="_subRole"
                        onChange={handleInputChange}
                        value={data._subRole}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {picture !== null ? (
                        <div class="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span class="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div class="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div class="col-md-10" style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}

                      <DropdownLarge
                        title="Region"
                        options={regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={data._region}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Departement"
                        options={departmentList?.[data?._region] || []}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={data._departement}
                        required
                      />
                      <DropdownLarge
                        title="District"
                        options={districtList?.[data?._departement] || []}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={data._arrondissement}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <Dropdown
                        title="Marital status"
                        options={DropdownConstants.FAMILY_STATUS}
                        name="familyStatus"
                        id="familyStatus"
                        onChange={handleInputChange}
                        value={data.familyStatus}
                        required
                      />

                      <InputText
                        title="Profession"
                        name="profession"
                        id="profession"
                        onChange={handleInputChange}
                        value={data.profession}
                        required
                      />

                      <Dropdown
                        title="Education level"
                        options={DropdownConstants.SCHOOL_LEVEL}
                        name="schoolLevel"
                        id="schoolLevel"
                        onChange={handleInputChange}
                        value={data.schoolLevel}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputText
                        title="Phone"
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputChange}
                        value={data.phone}
                        required
                      />

                      <InputText
                        title="Email"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleInputChange}
                        value={data.email}
                        required
                      />

                      {matriculeCodeList?.length !== 0
                        ? (
                          <div class="col-lg-4 mt-3">
                          <div class="form-group">
                            <label for="matriculeCode">Code Matricule</label>{" "}
                            <span class="text-danger ml-1">*</span>
                            <select
                              class="browser-default custom-select"
                              name="matriculeCode"
                              id="matriculeCode"
                              onChange={handleInputChange}
                              value={data.matriculeCode}
                            >
                              <option value="" disabled selected>
                                Choose an option
                              </option>
                              {matriculeCodeList?.map((e) => {
                                return <option value={e.code}>{e.code}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        )
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="conseilAdministration"
                          onChange={handleInputChange}
                          checked={data.conseilAdministration}
                        />
                        <label>Member of the Board</label>
                      </div>

                      <div class="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="commission"
                          onChange={handleInputChange}
                          checked={data.commission}
                        />
                        <label>Member of the Commission</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!sending ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FederationForm;
