import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  BackButton,
  Checkbox,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from '../../../components';

import ModalAddTeams from './ModalAddTeams';
import {
  saveTournois,
  updateTournois,
} from '../../../store/tournois/tournoisSlice';
// import {  saveTournois, updateTournois } from "../actions";

const TournoisForm = (props) => {
  const { sending: loading } = useSelector((state) => state.tournois);
  const { data: clubs } = useSelector((state) => state.club);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showTeams, setshowTeams] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isNormal, setIsNormal] = useState(false);

  const [data, setData] = useState({
    name: '',
    salle: '',
    start: '',
    end: '',
    organizer: '',
    auxiliaire: '',
    tour: '',
  });

  useEffect(() => {
    if (location.state) {
      // console.log("initial")
      setData((prev) => ({ ...prev, ...location.state }));
      setTeams(JSON.parse(location.state?.teams || '[]'))
    }
  }, [location.state]);

  useEffect(() => console.log(data), [data]);

  const handleInputChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const callback = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let allTeams = JSON.stringify(teams);
    let tournois = {
      ...data,
      auxiliaire: !isNormal,

      teams: allTeams,
    };

    data?.id
      ? dispatch(updateTournois({ data: tournois, callback }))
      :dispatch(saveTournois({ data: tournois, callback })) 
  };

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <div class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {location.state === undefined ? 'ADD' : 'EDIT'} A TOURNAMENT
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div class="row">
              <div class="col-md-12">
                <div class="card card-stats">
                  <div class="card-body">
                    <div class="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={data.name}
                        required
                      />
                      <InputTextLarge
                        title="Organizer"
                        name="organizer"
                        id="organizer"
                        onChange={handleInputChange}
                        value={data.organizer}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Start date"
                        type="date"
                        name="start"
                        id="start"
                        onChange={handleInputChange}
                        value={data.start}
                      />

                      <InputTextLarge
                        title="End date"
                        type="date"
                        name="end"
                        id="end"
                        onChange={handleInputChange}
                        value={data.end}
                      />
                    </div>

                    <div className="row">
                      <InputTextLarge
                        title="Tour"
                        name="tour"
                        id="tour"
                        onChange={handleInputChange}
                        value={data.tour}
                        required
                      />

                      {/* <InputTextLarge
                        title="Select teams"
                        name="teams"
                        id="teams"
                        onChange={handleInputChange}
                        onCLick={() => setshowTeams(true)}
                        value={teams.length + " teams selected"}
                        required
                      /> */}

                      <ModalAddTeams
                        title="select teams"
                        teams={teams}
                        clubs={Object.values(clubs)}
                        showTeams={showTeams}
                        setshowTeams={setshowTeams}
                        setTeams={setTeams}
                      />

                      {/* {showTeams ? (
                        <>
                          <div
                            className="Overlay"
                            style={{
                              backgroundColor: "white",
                              position: "relative",
                              zIndex: 99,
                              width: "500px",
                              display: "grid",
                              gridTemplateColumns: "auto auto auto auto",
                              gridTemplateRows: "auto auto auto auto",
                              gap: "10px",
                              boxShadow: "0 0 0 2px white",
                              padding: "20px",
                            }}
                          >
                            {adminState.clubs.map((club) => (
                              <Selected
                                club={club}
                                teams={teams}
                                setTeams={setTeams}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Salle"
                        name="salle"
                        id="salle"
                        onChange={handleInputChange}
                        value={data.salle}
                        required
                      />

                      <div className="col-lg-6">
                        <div class="form-group">
                          <label for="test">
                            {'Normal or Auxiliary Tournament ?'}
                          </label>{' '}
                          <span class="text-danger ml-1">*</span>
                          <br />
                          <Checkbox
                            title="Normal"
                            id="normal"
                            onChange={() => setIsNormal(true)}
                            checked={isNormal}
                          />
                          <Checkbox
                            title="Auxiliary"
                            id="auxiliaire"
                            onChange={() => setIsNormal(false)}
                            checked={!isNormal}
                          />
                          {/* <Checkbox
                            title="International"
                            id="international"
                            onChange={handleInputChange}
                            checked={international}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!loading ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TournoisForm;
