import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initRefereeCount } from "../store/referee/refereeSlice";
import { initCoachCount } from "../store/coach/coachSlice";
import { initPlayerCount } from "../store/players/playerSlice";
import { initFederationCount } from "../store/federation/federationSlice";
import { initLeagueCount } from "../store/league/leagueSlice";
import { initClubCount } from "../store/clubs/clubSlice";
import { initEnrolledCount } from "../store/enrolled/enrolledSlice";

export default function useGetDashboardStat(){
    const {count:refereeCount} =  useSelector((state)=>state.referee)
    const {count:coachCount} =  useSelector((state)=>state.coach)
    const {count:playerCount} =  useSelector((state)=>state.player)
    const {count:federationCount} =  useSelector((state)=>state.federation)
    const {count:leagueCount} =  useSelector((state)=>state.league)
    const {count:clubCount} =  useSelector((state)=>state.club)
    const {count:enrolledCount} =  useSelector((state)=>state.enrolled)
    const dispatch = useDispatch()

    useEffect(() => {
        // dispatch(getDashboardElements());
        dispatch(initRefereeCount())
        dispatch(initCoachCount())
        dispatch(initPlayerCount())
        dispatch(initFederationCount())
        dispatch(initLeagueCount())
        dispatch(initClubCount())
        dispatch(initEnrolledCount())
        // dispatch(closeNotify());
      }, [dispatch]);
    return {
        refereeCount,
        coachCount,
        playerCount,
        federationCount,
        leagueCount,
        clubCount,
        enrolledCount
    }
}