import React from "react";
import { useNavigate } from "react-router-dom";
import { hasPermission } from "../../../utilities/auth";
import { useDispatch, useSelector } from "react-redux";
import { showPasswordModal } from "../../../store/ui";
import useLang from "../../../hook/useLang";
export default function Options({ row }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.auth.user);
  const lang = useLang();
  const goToAdministration = (club) => {
    navigate("/admin/club-admin", { state: club });
  };

  const onEdit = (club) => {
    navigate("/admin/club-form", { state: club });
  };

  const onDelete = (clubId) => {
    dispatch(
      showPasswordModal({ deleteItemId: clubId, deleteSource: "club" })
    );
  };

  return (
    <div class="dropdown">
      <button
        class="btn btn-sm"
        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i class="fa fa-ellipsis-h"></i>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton">
        <button
          onClick={() => goToAdministration(row)}
          className="dropdown-item">
          <span>
            <i className="fa fa-list mr-1"></i> Staff
          </span>
        </button>
        <button
          onClick={() => navigate("/admin/player",{state:{clubId:row?.id}})}
          className="dropdown-item">
          <span>
            <i className="fa fa-list mr-1"></i> Joueurs
          </span>
        </button>

        <div class="dropdown-divider"></div>
        {loggedInUser
          ? hasPermission(loggedInUser, ["EDIT_TEAM"]) && (
              <button onClick={() => onEdit(row)} className="dropdown-item">
                <span>
                  <i className="fa fa-edit mr-1"></i> {lang?.edit}
                </span>
              </button>
            )
          : null}
        <div class="dropdown-divider"></div>
        {loggedInUser
          ? hasPermission(loggedInUser, ["DEACTIVATE_TEAM"]) && (
              <button
                onClick={() => onDelete(row)}
                className="dropdown-item text-danger">
                <span>
                  <i className="fa fa-trash mr-1"></i> {lang?.delete}
                </span>
              </button>
            )
          : null}
      </div>
    </div>
  );
}
