import { authEn } from "./authEn";
import EScoreSheetEn from "./EScoreSheetEn";
import { playersEn } from "./playersLang";

const en = {
  ...authEn,
  ...EScoreSheetEn,
  ...playersEn,
  inProgress: "In Progress...",
  dashboard: "Dashboard",
  players: "PLAYERS",
  enrolled: "ENROLLED",
  profil: "My Profil",
  settings: "Settings",
  logOut: "Log Out",
  referee_detail: "REFEREE DETAILS",
  professional_info: "Professional Informations",
  entraîneur_niveau_1: "Level 1 Trainer",
  entraîneur_niveau_2: "Level 2 trainer",
  entraîneur_niveau_3: "Level 3 coach",
  club_list: "CLUB LIST",
  filter: "Filter",
  filter_by_league: "Filter By League",
  coach_list: "COACH LIST",
  coaches: "Coaches",
  referees: "referees",
  myProfile: "My Profile",
  edit:"edit",
  logout: "Log out",
  referee: "referee",
  numberReferee: "Number of Referees",
  coach: "Coach",
  numberCoaches: "Number of Coaches",
  player: "Player",
  numberPlayers: "Number of Players",
  federation: "Federation",
  numberFederation: "Federation's Members",
  league: "League",
  leagues: "Leagues",
  numberLeague: "League Members",
  club: "Team",
  numberClub: "Number of Teams",
  numberEnrolled: "Number of Enrolled Entities",
  back: "Back",

  role: "Role",
  roles: "Roles",
  listOfRoles: "List of roles",
  Names : "Name",
  code: "Code",
  creationDate: "Creation Date",
  Admin: "Administrator",
  description: "Description",
  permission: "Permissions",
  listOfPermissions: "List of Permissions",
  matriculeCodes: "Matricule Codes",
  listOfMatricules: "List of matricule codes",

  playerList: "Player List",
  filterByClub: "Filter by club",
  gender: "Gender",
  matricule: "Matricule",
  shirtNum: "Shirt number",
  edit: "Edit",
  details: "Details",
  transfer: "Transfer",
  delete: "Delete",
  addPlayer: "Add a Player",
  save: "Save",
  generalInfo: "General Information",
  surname: "Surname",
  dob: "Date of birth",
  pob: "Place of birth",
  normalOrAux: "Normal or Auxiliary Player",
  normal: "Normal",
  auxiliary: "Auxiliary",
  photo: "Photo",
  chooseAPicture: "Choose a picture",
  anotherPicture: "Choose another picture",
  region: "Region",
  east: "East",
  department: "Department",
  district: "District",
  choose_an_option: "Choose an option",
  maritalStatus: "Marital status",
  profession: "Profession",
  educational_level: "Education Level",
  phone: "Telephone",
  email: "Email",
  boardMember: "Member of the Board",
  commissionMember: "Member of a Commission",

  refereeList: "Referee List",
  filterTable: "Filter table",
  profesionalInfo: "Professional Information",
  grade: "Grade",
  departmental_referee: "Departmental Referee",
  dateObtained: "Date obtained",
  location: "Location",
  instructor: "Instructor",
  observation: "Observation",
  regional_referee: "Regional Referee",
  national_referee: "National Referee",
  international_referee: "International Referee",
  previewPrint: "Preview print",
  print: "Print",
  edit_referee:"EDIT A REFEREE",
  deleteRef: "For security measures please enter your password to confirm the deletion of: ",
  confirmDel: "Confirm deletion",
  ref_details: "REFEREE DETAILS",
  addReferee: "ADD A REFEREE",
  

  addCoach: "Add a Coach",
  coach_pas: "Volleyball school coach",
  volleyball_school: "Vollayball School",
  coach_lvl_1: "Coach level 1",
  coach_lvl_2: "Coach level 2",
  coach_lvl_3: "Coach level 3",

  clubList: "Club List",
  filterByLeague: "Filter by league",
  logo: "Logo",
  type: "Type",
  staff: "Staff",
  
  listofEnrolled: "List of Enrolled",

  listOfTournaments: "List of Tournaments",
  hall: "Hall",
  startDate: "Starting date",
  endDate: "End date",
  organizer: "Organizer",
  normalOrAux_Tournament: "Normal or Auxiliary Tournament",

  fedMember_list: "Federation Member List",
  commission: "Commission",
  boardOf_Directors: "Board of Directors",
  edit_federation_member: "Edit a Federation Member",

  listOfLeagueMembers: "List of League Members",
  
  edit_league_member: "Edit a League Member",
  leagueType: "League Type",
  
  matchList: "Match List",
  match: "Match",
  filterByTournament: "Filter by Tournament",
  team_A: "Team A",
  team_B: "Team B",
  game_status: "Status",
  score: "Score",
  formation: "Lineups",

  roster: "Roster",
  tournament: "Tournament",
  team1: "Team 1",
  team2: "Team 2",
  dayNum: "Day N°",
  phase: "Phase",
  matchDate: "Date of Match",
  matchTime: "Match Time",
  Country: "Country",
  town: "Town",
  numSpectators: "Number of spectators",
  countryCode: "Country Code",
  division: "Division",
  category: "Category",
  first: "First",
  second: "Second",
  scorer: "Scorer",
  assistant_scorer: "Assistant Scorer",
  supervisor: "Supervisor",
  judge1: "Line judge 1",
  judge2: "Line judge 2",
  judge3: "Line judge 3",
  judge4: "Line judge 4",
  setCaptainLibero: "Set Captain & Libero",
  officials: "Officials",
  teamColors: "Team Colors",
  kit: "Kit",
  captain: "Captain",
  therapist: "Therapist",
  medicalDoctor: "Medical Doctor",
  notPlayed: "Not played",
  played: "Played",
  generateReport: "Generate Report",
  startMatch: "Start match",

  substitution: "Substitution",
  assignPoint: "Assign Point",
  playerOnBench: "Players on Bench",
  sanction: "Sanctions",
  timeout: "Timeout",
  cancel: "Cancel",
  undo: "Undo",
  addLiberos: "Add Liberos",
  exit: "Exit",
  setTime: "Set Duration",
  onCourt: "On Court",
  injury: "Injury",
  manualChanges: "Manual Changes",
  set: "Set",
  technicalTimeout: "Technical Timeout",
  time: "Time",
  sanctionHistory: "Sanction History",
  emptyList: "This list is empty",
  delayWarning: "Delay Warning",
  delayPenalty: "Delay Penalty",
  warning: "Warning",
  penalty: "Penalty",
  expulsion: "Expulsion",
  disqualification: "Disqualification",
  subsRemaining: "Remaining Substitutions",
  substitute: "Substitute",
  timeoutHistory: "Timeout History",
  startTimeout: "Start Timeout",
  liberoHistory: "Libero History",
  changeLibero: "Change Libero",
  exitMessage: "Are you sure you want to exit?",
  exitMessage2: "All the current data will be lost",

  west: "West",
  farNorth: "Far North",
  north: "North",
  adamawa: "Adamawa",
  centre: "Center",
  south: "South",
  littoral: "Littoral",
  southWest: "South West",
  northWest: "North West",
  fianced: "Fianced",
  divorced: "Divorced",
  married: "Married",
  bachelor: "Bachelor",
  complex: "Complex",
  enrollDetails: "Enroll Details",

  auTotal: "in total"

};

export default en;
