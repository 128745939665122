const EScoreSheetFr = {
  technicalTimeout: "Temps-mort Technique",
  match: "Match",
  exit: "Quitter",
  assignPoint: "Assigner des Points",
  updateCaptaimTeam: "Mise à jour du capitaine en jeu....",
  manualChange: "CHAGEMENTS MANUELS",
  timeout: "TEMPS-MORT",
  team: "Equipe",
  Time: "Temps",
  timeoutHistory: "Historique des temps-morts",
  startTimeout: "Debut du temps-mort",
  startTime: "Heure de démarrage",
  AreYouSureYouWantToExit: "Etes Vous sûre De Vouloir Quitter",
  AllTheCurrentDataWillBeLost: "Toutes Les Données Courant Seront Perdues",
  injury: "Blessure",
  substitution: "Substitution",
  time_out: "TEMPS-MORT",
  player: "Joueur",
  substitute: "Remplaçant",
  replaceWith: "Remplacé Par",
  saveInjury: "Enregistrer La Blessure",
  injuryHistory: "Historiques Des Blessures",
  changeLibero: "Changer Le Libero",
  liberoHistory: "Historiques Des Libero",
  addLibero: "AJOUTER UN LIBERO",
  tournament: "Tournois",
  captain: "Capitaine",
  referees: "arbitres",
  changeCaptainInCour: "changement de capitaine en jeu",
  popupForLibero: "Le libero doit être sorti ",
  checkAcciddent: "Vérifier les accidents",
  reportCaseAccident: "signaler les cas d'accidents ou de blessures",
  timeouts: "Timeouts",
  playWithTechnicalTimaeout: "jouer avec des temps morts techniques",
  numberOfPlayerAuthorize: "Nombre de joueurs autorisés",
  scoreTimer: "Minuteur de score",
  enable15sTimer: "activer une minuterie de 15s entre 2 points",
  listOfShortcuts: "Liste de raccourcis pour effectuer différentes actions",
  SHORTCUTS: "raccourcis",
  shareData: "PARTAGER LES DONNEEs",
  ShareInformationWithOtherFederations:
    "Partager des informations avec d'autres fédérations (FIVB inclus)",
  generate_report: "Générer Le Rapport",
  filter_by_tournament: "Filter Par Tournois",
  match_list: "LISTE DES MATCHS",
  delete: "Supprimer",
  hide_preview: "Annuler",
};
export default EScoreSheetFr;
