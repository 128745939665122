import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  InputTextSmall,
  Loader,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals } from "../../../utilities";
import { DATE_VALIDATOR_LOCALS } from "../../../utilities/locals";

import { getUserInfoAsync, saveUserAsync, updateUserAsync } from "../../../store/users/userSlice";
import { initCaochInfo } from "../../../store/coach/coachSlice";
import { getArrondissment, getDepartment } from "../../../store/ui";

const getKey = (keyString)=>{
  const key = keyString?.replaceAll(" ","")?.toLowerCase()
  console.log(key)
  if(key?.includes("arbitreregional") || key?.includes("niveau3")){
    return "arbitreRegional"
  }
  if(key?.includes("arbitrenational") || key?.includes("niveau2")){
    return "arbitreNational"
  }
  if(key?.includes("arbitreinternational") || key?.includes("niveau1")){
    return "arbitreInternational"
  }
  return "arbitreDepartemental"
}

const CoachFormAdmin = (props) => {
  const location = useLocation();
  const { userId } = location.state;
  const [loading, setLoading] = useState(false);
  const { data: coachList } = useSelector((state) => state.coach);
  const {
    matriculeCodeList,
    regions,
    department: departmentList,
    district: districtList,
    roles:roleList
  } = useSelector((state) => state.ui);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [picture, setPicture] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [validate, setValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [sending , setSending] = useState(false)
  const user = useMemo(() => {
    return coachList?.[userId];
  }, [coachList, userId]);

  const [data, setData] = useState({
    name: "",
    surname: "",
    dob: "",
    pob: "",
    matricule: "",
    gender: "",
    familyStatus: "",
    profession: "",
    schoolLevel: "",
    league: "",
    phone: "",
    email: "",
    state: true,
    matriculeCode: "",
    _region: "",
    _departement: "",
    _arrondissement: "",
    conseilAdministration: "",
    commission: "",
    signature: loggedInUser?.signature,
    arbitreDepartemental: {
      name: "Departemental trainer",
      date: "",
      place: "",
      instructor: "",
      observation: "",
    },
    arbitreRegional: {
      name: "Regional Trainer",
      date: "",
      place: "",
      instructor: "",
      observation: "",
    },

    arbitreNational: {
      name: "National Trainer",
      date: "",
      place: "",
      instructor: "",
      observation: "",
    },
    arbitreInternational: {
      name: "International Trainer",
      date: "",
      place: "",
      instructor: "",
      observation: "",
    },
  });

  /*
  AD: Departmental Referee
  AR: Regional Referee
  AN: National Referee
  AI: International Referee
  */

  const onLoad = useCallback(
    (data) => {
      setLoading(false);
      dispatch(initCaochInfo({...data, hasAllInfo:true}));
    },
    [dispatch]
  );




  useEffect(() => {
    
    if (userId && !user?.hasAllInfo) {
      setLoading(true);
      dispatch(
        getUserInfoAsync({
          userId: userId,
          onSuccess: onLoad,
          onError: () => setLoading(false),
        })
      );
    }

    const ligue = localStorage.getItem("fvApp");

    if (ligue !== locals.ADMIN_LOCALS) {
      //setLeague(adminState.loggedInUser.league);
      setDisabled(true);
      setData((prev) => ({
        ...prev,
        league: ligue !== locals.ADMIN_LOCALS ? loggedInUser.league : "",
      }));
    }
  }, [onLoad, dispatch, user, userId, loggedInUser.league]);


  useEffect(() => {
    if (user?.hasAllInfo) {
      const localData = {...user}
      const gradeList = localData?.grade?.reduce((init, arbitre) => {
        init[getKey(arbitre.name)] = arbitre;
        return init;
      }, {});

      delete localData?.grade
      delete localData?._role
      delete localData?.clubs

      setData((prev) => ({
        ...prev,
        ...localData,
        _region: localData?._region?.id,
        _departement: localData?._departement?.id,
        _arrondissement: localData?._arrondissement?.id,
      }));
      setPicture(localData?.picture)
      
      setData((prev) => ({
        ...prev,
        ...gradeList,
      }));
    }
  }, [loggedInUser.league, user, userId]);

  useEffect(() => {
    if (data._region !== "") {
      dispatch(getDepartment({ regionId: data._region }));
    }
  }, [data._region, dispatch]);

  useEffect(() => {
    if (data._departement !== "") {
      // console.log("departmentid", data._departement);
      dispatch(getArrondissment({ departmentId: data._departement }));
    }
  }, [data._departement, dispatch]);


  const handleInputChange = (e) => {
    setData((prev) => {
      if (e.target.id === "dob") {
        let date = new Date(e.target.value);
        let newDate = new Date();
        let validAge = newDate.getFullYear() - date.getFullYear();
        if (validAge < 10 || validAge > 99) {
          setValidate(true);
          setValidateMessage(DATE_VALIDATOR_LOCALS);
          return { ...prev, dob: "" };
        } else {
          setValidate(false);
          return { ...prev, dob: e.target.value };
        }
      }

      if (
        [
          "arbitreDepartemental",
          "arbitreNational",
          "arbitreRegional",
          "arbitreInternational",
        ].includes(e.target.id)
      ) {
        return {
          ...prev,
          [e.target.id]: {
            ...prev[e.target.id],
            [e.target.name]: e.target.value,
          },
        };
      }
      if (["conseilAdministration", "commission"].includes(e.target.id)) {
        return {
          ...prev,
          [e.target.id]: !prev[e.target.id],
        };
      }

      return { ...prev, [e.target.id]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let user = {...data, _role:roleList.find((role)=>role.code === "COACH").id,}

    if (picture?.base64 === undefined) {
      user = {
        ...user,
        
        picture: picture,
      };
    } else {
      user = {
        ...user,
        picture: picture?.base64,
      };
    }

    const onSuccess = (data)=>{
      dispatch(initCaochInfo(data))
      navigate(-1)
    }

    [
      "arbitreDepartemental",
      "arbitreNational",
      "arbitreRegional",
      "arbitreInternational",
    ].forEach((key)=>{
      const tab = Object.values(user[key]).map((el)=>el?.replaceAll(" ", ""))
      if(tab.includes("")){
        delete user[key]
      }
    })

    if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (!data.name.trim() || !data.surname.trim()) {
      alert("Please enter a valid name");
    } else if (data.matriculeCode === "" && data?.id === "") {
      alert("Please choose a MATRICULE CODE");
    } else {
      // console.log("user to send", user)
      setSending(true)
      !data?.id
        ? dispatch(saveUserAsync({user,onSuccess,onStop:()=>setSending(false)}))
        : dispatch(updateUserAsync({user,onSuccess,onStop:()=>setSending(false)}));
    }
  };

  if (loading) {
    return <Loader />;
  }



  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!userId ? "ADD" : "EDIT"} A COACH
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="font-weight-bold mb-4">General information</h4>
                    <div className="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={data.name}
                        required
                      />
                      <InputTextLarge
                        title="Surname"
                        name="surname"
                        id="surname"
                        onChange={handleInputChange}
                        value={data.surname}
                        required
                      />
                    </div>

                    <div className="row">
                      <InputTextLarge
                        validate={validate}
                        validateMessage={validateMessage}
                        title="Date of birth"
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleInputChange}
                        value={data.dob}
                        required
                      />

                      <InputTextLarge
                        title="Place of birth"
                        name="pob"
                        id="pob"
                        onChange={handleInputChange}
                        value={data.pob}
                        required
                      />
                    </div>

                    <div className="row">
                      {/* <InputTextLarge title="Matricule" name="matricule" id="matricule" onChange={handleInputChange} value={matricule} /> */}

                      <DropdownLarge
                        title="Gender"
                        options={DropdownConstants.GENDER}
                        name="gender"
                        id="gender"
                        onChange={handleInputChange}
                        value={data.gender}
                        required
                      />

                      <DropdownLarge
                        title="Marital status"
                        options={DropdownConstants.FAMILY_STATUS}
                        name="familyStatus"
                        id="familyStatus"
                        onChange={handleInputChange}
                        value={data.familyStatus}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <InputText
                        title="Profession"
                        name="profession"
                        id="profession"
                        onChange={handleInputChange}
                        value={data.profession}
                        required
                      />

                      <Dropdown
                        title="Education level"
                        options={DropdownConstants.SCHOOL_LEVEL}
                        name="schoolLevel"
                        id="schoolLevel"
                        onChange={handleInputChange}
                        value={data.schoolLevel}
                        required
                      />

                      <InputText
                        title="Phone"
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputChange}
                        value={data.phone}
                        required
                      />
                    </div>

                    <div className="row">
                      <InputText
                        title="Email"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleInputChange}
                        value={data.email}
                        required
                      />

                      <Dropdown
                        title="League"
                        options={DropdownConstants.LEAGUE}
                        name="league"
                        id="league"
                        onChange={handleInputChange}
                        disabled={disabled}
                        value={data.league}
                        required
                      />

                      {!data?.id && matriculeCodeList?.length !== 0 ? (
                        <div className="col-lg-4 mt-3">
                          <div className="form-group">
                            <label for="matriculeCode">Code Matricule</label>{" "}
                            <span className="text-danger ml-1">*</span>
                            <select
                              className="browser-default custom-select"
                              name="matriculeCode"
                              id="matriculeCode"
                              onChange={handleInputChange}
                              value={matriculeCodeList}
                            >
                              <option value="" disabled selected>
                                Choose an option
                              </option>
                              {matriculeCodeList?.map((e, i) => {
                                return (
                                  <option value={e.code} key={i}>
                                    {e.code}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {picture !== null ? (
                        <div className="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span className="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div className="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div className="col-md-10" style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}

                      <DropdownLarge
                        title="Region"
                        options={regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={data._region}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="Departement"
                        options={departmentList?.[data?._region] || []}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={data._departement}
                        required
                      />
                      <DropdownLarge
                        title="District"
                        options={districtList?.[data?._departement] || []}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={data._arrondissement}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                      <div className="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="conseilAdministration"
                          onChange={handleInputChange}
                          checked={data.conseilAdministration}
                        />
                        <label>Member of the Board</label>
                      </div>

                      <div className="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="commission"
                          onChange={handleInputChange}
                          checked={data.commission}
                        />
                        <label>Member of the Commission</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="font-weight-bold mb-4 mt-5">
                      Professional information
                    </h4>
                    <h5 className="h5">Grade</h5>
                    <h6 className="h6">Coach Step (Volleyball School)</h6>
                    <div className="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="date"
                        id="arbitreDepartemental"
                        onChange={handleInputChange}
                        value={data["arbitreDepartemental"]?.date}
                      />

                      <InputTextSmall
                        title="Location"
                        name="place"
                        id="arbitreDepartemental"
                        onChange={handleInputChange}
                        value={data["arbitreDepartemental"]?.place}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="instructor"
                        id="arbitreDepartemental"
                        onChange={handleInputChange}
                        value={data["arbitreDepartemental"]?.instructor}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="observation"
                        id="arbitreDepartemental"
                        onChange={handleInputChange}
                        value={data["arbitreDepartemental"]?.observation}
                      />
                    </div>
                    <h6 className="h6">Coach Level 1</h6>
                    <div className="row">
                      <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="date"
                        id="arbitreRegional"
                        onChange={handleInputChange}
                        value={data["arbitreRegional"]?.date}
                      />

                      <InputTextSmall
                        title="Location"
                        name="place"
                        id="arbitreRegional"
                        onChange={handleInputChange}
                        value={data["arbitreRegional"]?.place}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="instructor"
                        id="arbitreRegional"
                        onChange={handleInputChange}
                        value={data["arbitreRegional"]?.instructor}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="observation"
                        id="arbitreRegional"
                        onChange={handleInputChange}
                        value={data["arbitreRegional"]?.observation}
                      />
                    </div>
                    <h6 className="h6">Coach Level 2</h6>
                    <div className="row">
                    <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="date"
                        id="arbitreNational"
                        onChange={handleInputChange}
                        value={data["arbitreNational"]?.date}
                      />

                      <InputTextSmall
                        title="Location"
                        name="place"
                        id="arbitreNational"
                        onChange={handleInputChange}
                        value={data["arbitreNational"]?.place}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="instructor"
                        id="arbitreNational"
                        onChange={handleInputChange}
                        value={data["arbitreNational"]?.instructor}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="observation"
                        id="arbitreNational"
                        onChange={handleInputChange}
                        value={data["arbitreNational"]?.observation}
                      />
                    </div>
                    <h6 className="h6">Coach Level 3</h6>
                    <div className="row">
                    <InputTextSmall
                        title="Date obtained"
                        type="date"
                        name="date"
                        id="arbitreInternational"
                        onChange={handleInputChange}
                        value={data["arbitreInternational"]?.date}
                      />

                      <InputTextSmall
                        title="Location"
                        name="place"
                        id="arbitreInternational"
                        onChange={handleInputChange}
                        value={data["arbitreInternational"]?.place}
                      />

                      <InputTextSmall
                        title="Instructor"
                        name="instructor"
                        id="arbitreInternational"
                        onChange={handleInputChange}
                        value={data["arbitreInternational"]?.instructor}
                      />

                      <InputTextSmall
                        title="Observation"
                        name="observation"
                        id="arbitreInternational"
                        onChange={handleInputChange}
                        value={data["arbitreInternational"]?.observation}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {!sending? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CoachFormAdmin;
