import { call, put } from 'redux-saga/effects';
import * as API from '../apis';
import { closeSessionExpiredModal, notifyError, notifySuccess, openSessionExpiredModal } from '../../admin/actions';
import { checkDuplicateUser, getLoggedInUser } from '../../admin/apis';
import { authResult } from '../actions';

export function* signinWorker(payload) {
  console.log('signinWorker')
  try {

    const response = yield call(API.signin, payload.user);

    if (String(response).substring(String(response).length - 3) === '401') {
      yield put(notifyError("E-mail ou mot de passe incorrect. "));
      yield put(openSessionExpiredModal())
      yield put(authResult())
    } else {

      if (response?.data?.length === 0) {
        yield put(notifyError("E-mail ou mot de passe incorrect. "));
        yield put(authResult())
        // localStorage.setItem('fvAuth', 'false')
      } else {

        localStorage.setItem('fvAccessToken', response.data.accessToken)
        const loggedInUser = yield call(getLoggedInUser, response.data.accessToken);

        if (loggedInUser.data !== []) {

          localStorage.setItem('fvUser', loggedInUser.data.name)

          if (loggedInUser.data._role.code === 'ADMIN') {
            localStorage.setItem('fvApp', '15sdf#4%$#sd#@_)54')
          } else {
            localStorage.setItem('fvApp', 'pk((^%*kj$%&hg81+_)(&')
          }
        }
        yield put(authResult())

        yield put(closeSessionExpiredModal())
        payload.callback();

        if (payload.source === undefined || payload.source === '') {      
          payload.history.push('/admin/dashboard')          
        } else {
          yield put(closeSessionExpiredModal())
          payload.history.push(payload.source)
        }

      }
    }

  } catch (error) {
    console.log(error)
    yield put(authResult())
  }
}

export function* signupWorker(payload) {

  try {

    const checkDuplication = yield call(checkDuplicateUser, payload.user);

    if (checkDuplication.data.length > 0) {

      yield put(notifyError(`Cet Utilisateur existe déjà !`))
      yield put(authResult())
    } else {

      const response = yield call(API.signup, payload.user);

      if (response.status === 201) {
        yield put(authResult())
        payload.callback();
        yield put(notifySuccess('Enregistré avec Succès !'))
      } else {
        yield put(authResult())
        yield put(notifyError());
      }

    }

  } catch (error) {
    console.log(error)
    yield put(authResult())
  }
}

