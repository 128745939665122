import React from "react";

const SaveButton = ({onClick}) => {
  return (
    <div>
      <button
        type="submit"
        onClick={onClick}
        className="btn rounded btn-sm btn-primary font-weight-medium auth-form-btn z-depth-2"
      >
        <i className="fa fa-save fa-lg mr-2"></i>
        Save
      </button>
    </div>
  );
};

export default SaveButton;
