import React from "react";
import useLang from "../hook/useLang";

const Dropdown = ({
  title = "Title",
  dynamic = false,
  options = [1, 2, 3],
  error,
  ...other_props
}) => {
  const lang = useLang();

  return (
    <div className="col-lg-4 mt-3">
      <div className="form-group">
        <label htmlFor={title}>{title}</label>
        {other_props.required ? (
          <span className="text-danger ml-1">*</span>
        ) : null}
        <select
          className={
            error
              ? "browser-default custom-select is-invalid"
              : "browser-default custom-select"
          }
          {...other_props}
        >
          <option disabled selected>
            {lang?.choose_option}
          </option>
          {other_props.showAll ? (
            <option> {lang?.showAll ?? "show All"} </option>
          ) : null}
          {dynamic
            ? options.map((e, index) => {
                return (
                  <option value={e.id} key={index}>
                    {e.name}
                  </option>
                );
              })
            : options.map((e, index) => {
                return (
                  <option value={e} key={index}>
                    {e}
                  </option>
                );
              })}
        </select>
        {error && <small className="text-danger ml-1">{error}</small>}
      </div>
    </div>
  );
};

export default Dropdown;
