import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_LOCALS } from "../../utilities/locals";
import * as API from "../../api/api";
import { notifyError } from "../ui";
import Axios from "axios";
import { SERVER } from "../../utilities";

const federationSlice = createSlice({
  name: "federation",
  initialState: {
    data: null,
    loaded: false,
    loading: false,
    sending: false,
    statLoaded: false,
  },
  reducers: {
    initFederation: (state, { payload }) => {
      // console.log("init coach",payload)
      // eslint-disable-next-line no-unused-expressions
      state.data = {}
      const d = payload?.forEach((match) => {
        state.data[match?.id] = match;
      });
      state.loaded = true;
      state.loading = false;
    },
    initFederationInfo: (state, { payload }) => {
      if (!state.data) {
        state.data = {};
      }
      state.data[payload?.id] = { ...state.data[payload?.id], ...payload };
    },
    initLoading: (state) => {
      state.loading = true;
    },
    initCount: (state, { payload }) => {
      state.count = payload;
    },
    changeStatLoaded: (state) => {
      state.statLoaded = true;
    },
    deleteUserFederation:(state,{payload})=>{
      delete state.data[payload]
      return state
    }
  },
  extraReducers: (builder) => {},
});

const federationReducer = federationSlice.reducer;

export const {
  initFederation,
  initLoading,
  initCount,
  changeStatLoaded,
  initFederationInfo,
  deleteUserFederation
} = federationSlice.actions;
export default federationReducer;

export const initFederationCount = () => async (dispatch, getState) => {
  if (!getState().federation.statLoaded) {
    const ligue = localStorage.getItem("fvApp");
    const firstResponse = await API.getRole("FEDER");

    let dataResponse;
    if (ligue !== ADMIN_LOCALS) {
      dataResponse = await API.getUsersCount(firstResponse.data.id);
      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.data))
        : dispatch(notifyError(""));
    }
    dispatch(changeStatLoaded());
  }
};
export const getFederaion = () => (dispatch, getState) => {
  if (!getState().federation.loaded) {
    console.log("get federation");
    dispatch(initLoading());
    Axios.get(SERVER + "federations/", {
      headers: { "cybexai-fcvb-token": localStorage.getItem("fvAccessToken") },
    })
      .then((response) => {
        console.log("federation  response........................", response);
        dispatch(initFederation(response));
      })
      .catch((response) => {
        return response;
      });
  }
};
