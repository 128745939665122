import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { EntrantTableRow } from "../../../components";

const ESetEntrantModal = ({
  handleClose,
  show,
  teamA,
  teamB,
  players,
  time,
  onSave,
  captainSelection
}) => {
  const [playerSelection, setPlayerSelection] = useState({});
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);

  // useEffect(()=>{
  //   console.log("player selection here", playerSelection)
  // },[playerSelection])

  const onPlayerSelect = (data,prevSeletedPlayer) => {
    const localData = JSON.parse(data)
    const prevData = JSON.parse(prevSeletedPlayer || '{}')
    if(prevData?.player){
      // console.log("prev selected player",prevData)
      // console.log("selected player", localData)
      delete  playerSelection?.[prevData?.player?.id]
    }
    
    if (Object.keys(playerSelection || {})?.includes(localData.player.id)) {
      const result = Object.values(playerSelection || {}).filter(
        ({player}) => player.id !== localData.player.id
      );
     
      const rest = result.reduce((a, b) => ({ ...a, [b.player.id]: {...b,type:"onCourt"} }), {});
      setPlayerSelection(rest);
    } else {
      setPlayerSelection((prev) => ({ ...prev, [localData.player.id]: {...localData,type:"onCourt"} }));
    }
    
  };

  useEffect( 
    function () {
      setTimeout(function () {
        if (seconds === 0) {
          if (minutes === 0) {
            handleClose();
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    },
    [seconds, minutes, handleClose]
  );

  return (
    <Modal show={show} centered size="lg">
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>SELECT SET ENTRANTS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: "70vh", overflow: "auto" }}>

        <div className="d-flex align-items-center justify-content-center">
                <img
                  src={`/assets/images/time-${
                    minutes === 0 ? "bomb2" : "bomb"
                  }.png`}
                  alt="scored"
                  width={50}
                  height={50}
                />
                <h1
                  style={{ fontSize: 50, color: minutes === 0 && "#E56969" }}
                  className="mx-2"
                >
                  :
                </h1>
                <h1
                  style={{ fontSize: 50, color: minutes === 0 && "#E56969" }}
                >
                  {`${minutes}:${seconds}`}
                </h1>
              </div>

          <div className="row justify-content-between">
            <div className="col-md-6">
              <h2 className="text-center">
                <b>{teamA?.name}</b>
              </h2>
            </div>
            <div className="col-md-6">
              <h2 className="text-center">
                <b>{teamB?.name}</b>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Player</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5, 6].map((value, index) => (
                    <EntrantTableRow
                      key={index}
                      position={value}
                      players={players.filter(
                        ({ clubID }) => clubID === teamA?.id
                      )}
                      onDeselect={onPlayerSelect}
                      mapValues={[5,6,1]}
                      playerSelection={playerSelection}
                      onPlayerSelect={onPlayerSelect}
                      captainSelection={captainSelection}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="col-md-6">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Player</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3, 4, 5, 6].map((value, index) => (
                    <EntrantTableRow
                      key={index}
                      position={value}
                      players={players.filter(
                        ({ clubID }) => clubID === teamB?.id
                      )}
                      onDeselect={onPlayerSelect}
                      mapValues={[1,6,5]}
                      playerSelection={playerSelection}
                      onPlayerSelect={onPlayerSelect}
                      captainSelection={captainSelection}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-center align-items-center">
          <button
            className="btn btn-sm w-50"
            disabled={Object.values(playerSelection).length < 12}
            onClick={() => onSave(playerSelection)}
            style={{ backgroundColor: "#369688", color: "white" }}
          >
            Save
          </button>
          {/* <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESetEntrantModal;
