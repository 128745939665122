import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BackButton, DatatableCard, Loader } from '../../../components';
import { formatDate, getDiffDay } from '../../../utilities/dateUtils';
import { getMatchAsync } from '../../../store/matchs/matchSlice';
import useLang from '../../../hook/useLang';

const matchStatus = (status) => {
  switch (status) {
    case 'NOT_PLAYED':
      return { title: 'Not played', color: 'black' };
    case 'PLAYED':
      return { title: 'Played', color: '#369688' };
    case 'PAUSED':
      return { title: 'Paused', color: '#94393a' };

    default:
      return 'Not played';
  }
};

const TournoisDetails = () => {
  const location = useLocation();
  const lang = useLang();
  const tournoisID = location?.state?.id;
  const dispatch = useDispatch();
  const tournois = useSelector((state) => state.tournois?.data?.[tournoisID]);

  const { data: clubs } = useSelector((state) => state.club);
  const { loading: matchLoading, data } = useSelector((state) => state.match);

  const matchTournament = useMemo(() => {
    return Object.values(data)
      .filter((el) => el._tournois?.id === tournoisID)
      .sort((a, b) => new Date(b?.dateMatch).getTime() - new Date(a?.dateMatch).getTime());
  }, [data, tournoisID]);

  const matchColumns = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row, index, column, id) => (
        <span>{matchTournament?.map((el) => el.id)?.indexOf(row?.id) + 1}</span>
      ),
    },
    {
      name: `${lang?.team} A`,
      selector: '_participantOne.name',
      sortable: false,
    },
    {
      name: `${lang?.team} B`,
      selector: '_participantTwo.name',
      sortable: false,
    },
    {
      name: 'Salle',
      selector: 'hall',
      sortable: false,
    },
    {
      name: 'Statut',
      cell: (row) => (
        <span style={{ color: matchStatus(row.status).color }}>
          {matchStatus(row.status).title}
        </span>
      ),
    },
    {
      name: 'Score',
      // selector: "_tournois.salle",
      sortable: false,
    },
    {
      name: 'Tournament',
      cell: (row) => (
        <span style={{ color: matchStatus(row.status).color }}>
          {row?._tournois?.name}
        </span>
      ),
    },
  ];

  const columnsPrint = [
    {
      name: 'Nº',
      width: '50px',
      sortable: false,
      cell: (row) => <span> {row?.number}</span>,
    },
    {
      name: 'name',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Logo',
      cell: (row) => (
        <img
          src={row.picture || '/assets/images/no-image.png'}
          alt="logo"
          className="rounded"
          width="50"
          height="50"
        />
      ),
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Type',
      selector: 'typeClub',
      sortable: false,
    },
    {
      name: 'Region',
      selector: '_region.name',
      sortable: false,
    },
  ];
  useEffect(() => {
    dispatch(getMatchAsync({ callback: () => {} }));
  }, [dispatch]);

  // console.log("tournois", tournois)

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span>
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                tournament details
              </h6>
              <BackButton />
            </div>
          </div>

          {tournois !== undefined ? (
            <div className="row">
              <div className="col-md-12">
                <div className="card card-stats">
                  <div className="card-body" style={{ textAlign: 'center' }}>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Tournament name</h6>
                        <b>{tournois.name}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Creation date</h6>
                        <b>{formatDate(tournois.creationDate)}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Tournament organizer</h6>
                        <b>{tournois.organizer}</b>
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Tournament duration </h6>
                        <b>{getDiffDay(tournois.start, tournois.end)}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Tournois start date</h6>
                        <b>{tournois.start || '__'}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Tournament end date</h6>
                        <b>{tournois.end || '__'}</b>
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Tournois phase number</h6>
                        <b>{matchTournament?.reverse()?.[0]?.phase || '__'}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>tournament location</h6>
                        <b>{tournois.salle}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Tournament type</h6>
                        <b>{tournois.auxiliaire ? 'auxiliare' : 'normal'}</b>
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>selected teams</h6>
                        <b>{`${
                          JSON.parse(tournois?.teams || '[]')?.length
                        } teams`}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <DatatableCard
            tableData={{
              data: JSON.parse(tournois?.teams || '[]'),
              columns: columnsPrint,
            }}
          />
          <h6> Liste des matchs </h6>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {matchLoading ? (
                  <Loader />
                ) : (
                  <DatatableCard
                    tableData={{
                      data: matchTournament,
                      columns: matchColumns,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournoisDetails;
