/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

import { notifyError, notifySuccess, openSessionExpiredModal } from "../ui";
import * as API from "../../api/api";

const tournoisSlice = createSlice({
  name: "tournois",
  initialState: {
    data:{},
    loaded:false,
    loading:false,
    sending:false
  },
  reducers: {
    initTournament: (state, { payload }) => {
        
        // eslint-disable-next-line no-unused-expressions
        payload?.forEach((match)=>{
            state.data[match?.id] = match
        })
        state.loaded = true
        
    },
    initStopLoading:(state)=>{
        state.loading = !state.loading
    },
    initStopSending:(state)=>{
      state.sending = !state.sending
    },
    addData:(state,{payload})=>{
      state.data[payload?.id] = payload
    },
    deleteTournament:(state,{payload:id})=>{
      delete state.data[id]
    }
  },
  extraReducers: (builder) => {},
});

const tournoisReducer = tournoisSlice.reducer;

export const { initTournament ,deleteTournament, initStopLoading, initStopSending, addData } = tournoisSlice.actions;
export default tournoisReducer;

// actions
export const saveTournois = ({data,callback})=>async(dispatch, getState) => {
      try {
        dispatch(initStopSending())
        const response = await API.saveTournois(data);

        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
          // yield put(authResult());
        } else {
          if (response.status === 201) {
            console.log("add tounois response", response)
            dispatch(addData(response?.data) )
            dispatch(notifySuccess("Save Successful !"));
            // eslint-disable-next-line no-unused-expressions
            callback?.();
            
          } else {
            // yield put(authResult());
            dispatch(notifyError());
          }
        }
      } catch (error) {
        // yield put(authResult());
        console.log(error);
      } finally{
        dispatch(initStopSending())
      }
};
  
export const getTournois = ()=>async (dispatch,getState) => {
    
    if(!getState().tournois.loaded){
      try {
          dispatch(initStopLoading())
          const response = await API.getTournois();

          if (String(response).substring(String(response).length - 3) === "401") {
            dispatch(openSessionExpiredModal());
          } else {
            if (response.status === 200) {
              console.log("tournament response", response)
              dispatch(initTournament(response.data));
            } else {
              dispatch(notifyError());
            }
          }
        } catch (error) {
          console.log("error when get tournament ",error);
        }finally{
          dispatch(initStopLoading())
        }
    }
  };
  
  export const deleteTournamentAsync = ({id,pwd}) =>async(dispatch, getState)=> {
  try {
    let user = {
      pwd: pwd,
      accessToken: localStorage.getItem("fvAccessToken"),
    };

    const checkPasswordResponse = await API.checkPassword(user);

    if (
      String(checkPasswordResponse).substring(
        String(checkPasswordResponse).length - 3
      ) === "401"
    ) {
      // yield put(actions.openSessionExpiredModal())
      dispatch(notifyError("Email or password incorrect. "));
    } else {
      if (checkPasswordResponse.status === 200) {
        const response = await API.deleteTournois(id)

        if (response.status === 200) {
          dispatch(notifySuccess("Delete Successful !"));
          dispatch(deleteTournament(id))
        } else {
        dispatch(notifyError());
        }
      } else {
        dispatch(notifyError());
      }
    }
  } catch (error) {
    console.log("error whwn delete tournament",error);
  }
  };
  
  export const updateTournois = ({data,callback}) =>async(dispatch, getState)=>{
    try {
      dispatch(initStopSending())
      const response = await API.updateTournois(data);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        
      } else {
        if (response.status === 200) {
          // yield put(authResult());
          dispatch(addData(response?.data))
          callback?.();
          dispatch(notifySuccess("Update Successful !"));
        } else {
          dispatch(notifyError());
        }
      }
    }catch (error) {
      // yield put(authResult())
      console.log("error when update tournament",error);
    }finally{
      dispatch(initStopSending())
    }
  };