import { createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/api";
import { ADMIN_LOCALS } from "../../utilities/locals";
import { notifyError, notifySuccess, openSessionExpiredModal } from "../ui";

const playerSlice = createSlice({
  name: "player",
  initialState: {
    data: {},
    loaded: false,
    loading: false,
    sending: false,
    statLoaded: false,
  },
  reducers: {
    initPlayer: (state, { payload }) => {
      // console.log("init player", payload);
      // eslint-disable-next-line no-unused-expressions
      payload?.forEach((match) => {
        state.data[match?.id] = match;
      });
      state.loaded = true;
    },
    updatePlayer: (state, { payload }) => {
      state.data[payload?.id] = {
        ...(state.data[payload?.id] || {}),
        ...payload,
      };
    },
    addPlayerTransfer: (state, { payload }) => {

      state.data[payload.playerId].transfertList = payload?.data;
    },
    deletePlayer: (state, { payload }) => {
      delete state.data[payload];
      return state;
    },
    initLoading: (state) => {
      state.loading = true;
    },
    changeStatLoaded: (state) => {
      state.statLoaded = true;
    },
    initCount: (state, { payload }) => {
      state.count = payload;
    },
  },
  extraReducers: (builder) => {},
});

const playerReducer = playerSlice.reducer;

export const {
  initPlayer,
  initLoading,
  changeStatLoaded,
  initCount,
  updatePlayer,
  addPlayerTransfer,
  deletePlayer,
} = playerSlice.actions;
export default playerReducer;

export const initPlayerCount = () => async (dispatch, getState) => {
  if (!getState().player?.statLoaded) {
    const ligue = localStorage.getItem("fvApp");
    const firstResponse = await API.getRole("JOUER");

    let dataResponse;
    if (ligue !== ADMIN_LOCALS) {
      dataResponse = await API.getUsersCount(firstResponse.data?.id);

      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.data))
        : dispatch(notifyError(""));
    } else {
      dataResponse = await API.getUsersByLigue(
        firstResponse.data?.id,
        getState().auth?.user?.league
      );

      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.length))
        : dispatch(notifyError(""));
    }
    dispatch(changeStatLoaded());
  }
};

export const getPlayerTransferAsync =
  ({ playerId, onStop = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.getPlayerTransfer(playerId);
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(
            addPlayerTransfer({ data: response.data, playerId: playerId })
          );
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get Player Transfer", err);
    } finally {
      onStop();
    }
  };

export const addPlayerTransferAsync =
  ({ data, onStop = () => {} }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.savePlayerTransfer(data);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200 || response.status === 201) {
          // dispatch(addPlayerTransfer({data:response.data,playerId:playerId}))
          dispatch(notifySuccess("transfer successfully"));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get Player Transfer", err);
    } finally {
      onStop();
    }
  };
