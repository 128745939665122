import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import ESubstitutionTableRow from './ESubstitutionTableRow';

const ESubstitutionModal = ({
  handleClose,
  show,
  team,
  players,
  onBenchPlayers,
  time,
  onSubstitute,
  captainSelection,
  subCount,
  subHistory,
  activeSetNumber,
}) => {
  const [playerSelection, setPlayerSelection] = useState({});
  console.log({ subHistory, activeSetNumber, players, onBenchPlayers });

  const onPlayerSelect = (data) => {
    if (data?.playerInPlaceId) {
      delete playerSelection?.[data?.playerInPlaceId];
    }
    setPlayerSelection((prev) => ({ ...prev, [data.playerIn]: data }));
  };
  const getPlayers = (id) => {
    if (subHistory?.[activeSetNumber]?.[id]) {
     
      return onBenchPlayers.filter(
        (item) => item?.player?.id === subHistory[activeSetNumber][id]
      );
    }
    return onBenchPlayers;
  };

  return (
    <Modal show={show} centered size="lg" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: '1px solid #c2c2c2' }}>
        <Modal.Title>SUBSTITUTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '65vh', overflow: 'auto' }}>
          <div className="d-flex justify-content-between mb-3">
            <div>
              <p className="mb-0">Team:</p>
              <h1>{team.name}</h1>
            </div>
            <div>
              <p className="mb-0">Substitutions remaining:</p>
              <h1 className="text-center">{6 - Number(subCount)}</h1>
            </div>
            <div>
              <p className="mb-0">Time:</p>
              <h1>{time}</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Player on court</th>
                    <th>Substitute</th>
                    <th>Change</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((item, index) => (
                    <ESubstitutionTableRow
                      key={index}
                      item={item}
                      index={index + 1}
                      players={players}
                      onBenchPlayers={getPlayers(item?.player?.id)}
                      onDeselect={onPlayerSelect}
                      playerSelection={playerSelection}
                      onPlayerSelect={onPlayerSelect}
                      captainSelection={captainSelection}
                      // clubID={selectedMatch._participantTwo?.id}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-center">
          {6 - Number(subCount) > 0 ? (
            <button
              className="btn btn-sm w-50"
              onClick={() => onSubstitute({ playerSelection, team, time })}
              style={{ backgroundColor: '#369688', color: 'white' }}
            >
              Substitute
            </button>
          ) : (
            <button className="btn btn-sm w-50" disabled>
              Substitutions Exhausted
            </button>
          )}
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESubstitutionModal;
