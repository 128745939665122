import dayjs from 'dayjs';

export const getDay = (date) => {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDay());
};

export const formatDate = (date) => {
  return dayjs(date).format('DD/MMM/YYYY').replaceAll('/', ' ');
};

export const getDiffDay = (d1, d2) => {
  const date1 = dayjs(d1);
  const date2 = dayjs(d2);

  if (date1.isValid() || date2.isValid()) {
    return `${Math.abs(date1.diff(date2, 'day'))} jour(s)`;
  }
  return '---';
};

export const time = (date) =>
  Intl.DateTimeFormat('default', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(Number(date) || new Date()));

export const ymd = (date) => {
  const d = new Date(date);
  let y = d.getFullYear();
  let m = d.getMonth();
  let day = d.getDate();
  return `${y}-${m + 1}-${day}`;
};
