import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { BackButton, CardRow, EmptyDetailCard, Loader } from '../../components';
import { getUser } from '../../screens/admin/apis';
import { stringReduceAddDots } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import { initFederationInfo } from '../../store/federation/federationSlice';
import { getUserInfoAsync } from '../../store/users/userSlice';


const FederationDetail = () => {


  const location = useLocation();
  const {userId} = location.state;
  const { data } = useSelector((state) => state.federation);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const lang = useLang();


  const user = useMemo(() => {
    return data?.[userId];
  }, [data, userId]);



  const callback = useCallback(
    (data) => {
      setLoading(false);
      console.log("data", data)
      dispatch(initFederationInfo({...data,hasAllInfo:true}));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user?.hasAllInfo) {
      setLoading(true);
      console.log("userid", userId)
      dispatch(
        getUserInfoAsync({
          userId: userId,
          onSuccess: callback,
        })
      );
    }
  }, [callback, dispatch, user, userId]);
  
  if(loading){
    return <Loader />
  }



  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span><i className="fa fa-plus fa-lg mr-3"></i></span>FEDERATION MEMBER DETAILS</h6>
              <BackButton />

            </div>
          </div>

          {user?
            <div className="row gutters-sm">
              <div className="col-md-4 mb-3">
                <div className="card shadow mb-25 bg-white rounded my-3" >
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <img src={user?.picture || '/assets/images/profile.png'} alt="Admin" className="rounded" width="150" height="150" />
                      <div className="mt-3">
                        <h6>{`${user?.name} ${user?.surname}`}</h6>

                        <p className="font-size-sm mb-0" style={{fontSize: 12}}>{user?.matricule === null ? '' : user?.matricule}</p>
                        <p className="font-size-sm" style={{fontSize: 12}}>{ user?._subRole?.name ||"" }</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-25 bg-white rounded my-3" >
                  <div className="card-body">
                    <CardRow title='League' value={user?.league} title2='Region' value2={user?._region?.name || ""} />
                    <CardRow title="Departement" value={user?._departement?.name || ""} title2={stringReduceAddDots('District',10)} value2={user?._arrondissement?.name || ""} showHR={false} />
                  </div>
                </div>

                {/* <SignaturePreview signature={user?.signature} height={'100%'} /> */}

              </div>
              <div className="col-md-8">
                <div className="card mb-3 shadow bg-white rounded my-3" >
                  <div className="card-body">

                    <CardRow title='Name' value={user?.name} title2='Surname' value2={user?.surname} />
                    <CardRow title='Date of birth' value={user?.dob} title2='Place of birth' value2={user?.pob} />
                    <CardRow title='Marital status' value={user?.familyStatus} title2='Gender' value2={user?.gender} />
                    <CardRow title='Email' value={user?.email} showHR={false} title2="Phone number" value2={user?.phone} />

                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="card mb-3 shadow bg-white rounded my-3" >
                      <div className="card-body">
                        <CardRow title="Profession" value={user?.profession} title2='Role' value2={user?._subRole?.name || ""} />
                        <CardRow title="Matricule" value={user?.matricule} title2="Education level" value2={user?.schoolLevel} />
                        <CardRow title="Creation date" value={<Moment format='DD/MM/YYYY'>{user?.creationDate}</Moment>} showHR={false} />

                      </div>
                    </div>

                  </div>
                </div>


              </div>

            </div>

            : <EmptyDetailCard />}

        </div>
      </div>
    </div>
  );
}

export default FederationDetail