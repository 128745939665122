import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { getUsersAsync } from '../../store/users/userSlice';
import { showPasswordModal } from '../../store/ui';
import useLang from '../../hook/useLang';
import { hasPermission } from '../../utilities/auth';
import { DatatableCard, Loader } from '../../components';
import { initCoach } from '../../store/coach/coachSlice';

const Coach = (props) => {
  const { data } = useSelector((state) => state.coach);
  const [loading, setLoading] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const lang = useLang();

  useEffect(() => {
    if (!data) {
      setLoading(true);
      dispatch(
        getUsersAsync({
          code: 'COACH',
          onSuccess: (res) => dispatch(initCoach(res)),
          onStop: () => setLoading(false),
        })
      );
    }
  }, [data, dispatch]);

  const coachList = useMemo(() => {
    return Object.values(data || {});
  }, [data]);

  const ids = useMemo(() => {
    return coachList.map((el) => el?.id);
  }, [coachList]);

  const onDelete = (user) => {
    dispatch(showPasswordModal({ deleteItemId: user, deleteSource: 'coach' }));
  };

  const columnsPrint = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row, index, column, id) => (
        <span>{ids?.indexOf(row?.id) + 1}</span>
      ),
    },
    {
      name: lang?.name,
      selector: 'name',
      sortable: false,
    },
    {
      name: lang?.surname,
      selector: 'surname',
      sortable: false,
    },
    {
      name: lang?.gender,
      selector: 'gender',
      sortable: false,
    },
    {
      name: 'Matricule',
      selector: 'matricule',
      sortable: false,
    },
    {
      name: 'Profession',
      selector: 'profession',
      sortable: false,
    },
  ];
  const columns = [
    ...columnsPrint,
    {
      cell: (row, index, column, selector) => (
        <div class="dropdown">
          <button
            class="btn btn-sm"
            style={{ backgroundColor: '#19a0c2', color: '#fff' }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-ellipsis-h"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            {loggedInUser
              ? hasPermission(loggedInUser, ['EDIT_COACH']) && (
                  <Link
                    to={{ pathname: `/admin/coach-form/` }}
                    state={{ userId: row.id }}
                    className="dropdown-item"
                  >
                    <span>
                      <i className="fa fa-edit mr-1"></i> {lang.t('edit')}
                    </span>
                  </Link>
                )
              : null}
            <div class="dropdown-divider"></div>
            <Link
              to={{
                pathname: '/admin/coach-detail/',
              }}
              state={{ userId: row.id }}
              className="dropdown-item"
            >
              <span>
                {' '}
                <i class="fa fa-list mr-1"></i> {lang?.t('detail')}
              </span>
            </Link>
            <div class="dropdown-divider"></div>
            {loggedInUser
              ? hasPermission(loggedInUser, ['DEACTIVATE_COACH']) && (
                  <button
                    onClick={() => onDelete(row)}
                    className="dropdown-item text-danger"
                  >
                    <span>
                      <i className="fa fa-trash mr-1"></i> {lang.t('delete')}
                    </span>
                  </button>
                )
              : null}
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: coachList,
  };

  const refToPrint = useRef();
  const r = useReactToPrint({
    content: () => refToPrint.current,
  });

  const [preview, setPreview] = useState(false);
  const handlePrint = async () => {
    await setPreview(true);
    await r();
    await setPreview(false);
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.coach_list}
              </h6> */}
              {loggedInUser
                ? hasPermission(loggedInUser, ['ADD_COACH']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/coach-form"
                        state={{ userId: null }}
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser
                ? hasPermission(loggedInUser, ['PRINT_COACH']) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => setPreview((prev) => !prev)}
                        >
                          <span>
                            <i className="fa fa-eye mr-1"></i>{' '}
                            {preview
                              ? lang?.hide_preview ?? 'Hide PReview'
                              : lang?.preview_print ?? 'Preview print'}
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={handlePrint}>
                          <span>
                            <i className="fa fa-print mr-1"></i> {lang?.print}
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? (
                  <Loader />
                ) : (
                  <div ref={refToPrint}>
                    <DatatableCard
                      tableData={{
                        ...tableData,
                        columns: preview ? columnsPrint : columns,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coach;
