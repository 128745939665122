import React from 'react'
export default function PrintButton() {
  return (
    <div className="dropdown float-right">
      <button
        className="btn btn-sm"
        style={{ backgroundColor: '#343a3f', color: '#fff' }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-print"></i>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <button className="dropdown-item">
          <span>
            <i className="fa fa-eye mr-1"></i> Preview print
          </span>
        </button>
        <div className="dropdown-divider"></div>
        <button className="dropdown-item">
          <span>
            <i className="fa fa-print mr-1"></i> Print
          </span>
        </button>
      </div>
    </div>
  );
}
