import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DatatableCard, Loader } from '../../components';
import { hasPermission } from '../../utilities/auth';

// import { getUsersCommision, getUsersConseilAdministration } from "../../screens/admin/apis";
import { initFederation } from '../../store/federation/federationSlice';
import { getUsersAsync, updateUserAsync } from '../../store/users/userSlice';
import { showPasswordModal } from '../../store/ui';

const Federation = (props) => {
  const [loading, setLoading] = useState(false);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { data } = useSelector((state) => state.federation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    if (!data) {
      setLoading(true);
      dispatch(
        getUsersAsync({
          code: 'FEDER',
          callback: (res) => {
            dispatch(initFederation(res));
            setLoading(false);
          },
        })
      );
    }
  }, [data, dispatch]);

  const filterData = useMemo(() => {
    const d = Object.values(data || {});
    if (filter === 'conseilAdministration') {
      return d.filter((c) => c?.conseilAdministration);
    }
    if (filter === 'commission') {
      return d.filter((c) => c?.commission);
    }
    return d;
  }, [data, filter]);

  const ids = useMemo(() => {
    return filterData?.map((el) => el?.id);
  }, [filterData]);

  const [pwd, setPwd] = useState('');
  const [user, setUser] = useState('');

  const onDelete = (user) => {
    dispatch(
      showPasswordModal({ deleteItemId: user, deleteSource: 'federation' })
    );
  };

  const onPasswordGenerator = (data) => {
    setUser(data);
    setPwd(data.pwd);
    document.getElementById('passwordGenerator').click();
  };

  const callback = () => {
    navigate('federation');
  };

  const generatePassword = () => {
    var length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setPwd(retVal);
  };

  const saveGeneratedPassword = () => {
    if (user !== '' && pwd !== '') {
      let userUpdate = {
        ...user,
        pwd,
      };
      dispatch(updateUserAsync({ user: userUpdate, onSuccess: callback }));
      document.getElementById('closePwdGenerator').click();
    }
  };

  const closePwdGenerator = () => {
    setPwd('');
    document.getElementById('closePwdGenerator').click();
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);

    // switch (e.target.value) {
    //   case "all":
    //     dispatch(getUsers("FEDER"));
    //     break;
    //   case "conseilAdministration":
    //     getUsersConseilAdministration().then((user) => {
    //       dispatch(getFederationSuccess(user.data));
    //     });
    //     break;
    //   case "commission":
    //     getUsersCommision().then((user) => {
    //       dispatch(getFederationSuccess(user.data));
    //     });
    //     break;

    //   default:
    //     break;
    // }
  };

  const columns = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row, index, column, id) => <span>{ids.indexOf(row?.id) + 1}</span>,
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Surname',
      selector: 'surname',
      sortable: false,
    },
    {
      name: 'Gender',
      selector: 'gender',
      sortable: false,
    },
    {
      name: 'Matricule',
      selector: 'matricule',
      sortable: false,
    },
    {
      name: 'Profession',
      selector: 'profession',
      sortable: false,
    },
    {
      cell: (row, index, column, selector) =>
        loggedInUser.length !== 0
          ? hasPermission(loggedInUser, ['VIEW_FEDERATION_MEMBER']) && (
              <Link
                to={`/admin/federation-detail/${row.id}`}
                state={{ userId: row?.id }}
                className="btn btn-sm"
              >
                <i className="fa fa-list"></i>
              </Link>
            )
          : null,
    },
    {
      cell: (row, index, column, selector) => (
        <button
          style={{ margin: 0, backgroundColor: '#139c9b', color: '#fff' }}
          onClick={() => onPasswordGenerator(row)}
          className="btn btn-sm"
        >
          <i className="fa fa-lock"></i>
        </button>
      ),
    },
    {
      cell: (row, index, column, selector) =>
        loggedInUser.length !== 0
          ? hasPermission(loggedInUser, ['EDIT_FEDERATION_MEMBER']) && (
              <Link
                to={{
                  pathname: '/admin/federation-form/' + row.id,
                }}
                state={{ userId: row?.id }}
                className="btn btn-sm btn-primary"
              >
                <i className="fa fa-edit"></i>
              </Link>
            )
          : null,
    },
    {
      cell: (row, index, column, selector) =>
        loggedInUser.length !== 0
          ? hasPermission(loggedInUser, ['DEACTIVATE_FEDERATION_MEMBER']) && (
              <button
                onClick={() => onDelete(row)}
                className="btn btn-sm btn-danger"
              >
                <i className="fa fa-trash"></i>
              </button>
            )
          : null,
    },
  ];

  const tableData = {
    columns,
    data: filterData,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                FEDERATION MEMBER LIST
              </h6> */}
              <h6
                style={{ float: 'right' }}
                className="h2 d-inline-block text-uppercase mb-0"
              >
                {loggedInUser
                  ? hasPermission(loggedInUser, [
                      'PRINT_FEDERATION_MEMBER',
                    ]) && (
                      <div class="dropdown float-right">
                        <button
                          class="btn btn-sm"
                          style={{ backgroundColor: '#343a3f', color: '#fff' }}
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-print"></i>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-right"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button class="dropdown-item">
                            <span>
                              {' '}
                              <i class="fa fa-eye mr-1"></i> Print preview
                            </span>
                          </button>
                          <div class="dropdown-divider"></div>
                          <button class="dropdown-item">
                            <span>
                              {' '}
                              <i class="fa fa-print mr-1"></i> Print
                            </span>
                          </button>
                        </div>
                      </div>
                    )
                  : null}
                <div class="dropdown">
                  <button
                    class="btn btn-sm"
                    style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Menu
                  </button>
                  {loggedInUser.length !== 0 ? (
                    <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {hasPermission(loggedInUser, [
                        'ADD_FEDERATION_MEMBER',
                      ]) && (
                        <Link
                          to="/admin/federation-form/0"
                          class="dropdown-item"
                          state={{ userId: null }}
                        >
                          <span>
                            {' '}
                            <i class="fa fa-plus mr-1"></i> Add
                          </span>
                        </Link>
                      )}
                      <div class="dropdown-divider"></div>
                      <button class="dropdown-item">
                        <span>
                          {' '}
                          <i class="fa fa-list mr-1"></i> Commission
                        </span>
                      </button>
                      <div class="dropdown-divider"></div>
                      <button class="dropdown-item">
                        <span>
                          {' '}
                          <i class="fa fa-list mr-1"></i> Conseils
                          d’administration
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </h6>
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <div class="col-lg-4 mt-3">
              <div class="form-group">
                <label for="filterLabel">Filter</label>
                <select
                  class="browser-default custom-select"
                  onChange={handleFilterChange}
                  id="filterLabel"
                >
                  <option value="all">Show all</option>
                  <option value="conseilAdministration">
                    Conseil d'Administration
                  </option>
                  <option value="commission">Commission</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? <Loader /> : <DatatableCard tableData={tableData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        id="passwordGenerator"
        data-toggle="modal"
        data-target="#passwordGeneratorModal"
        className="btn btn-sm"
        style={{ display: 'none' }}
      ></button>
      <div
        className="modal fade"
        id="passwordGeneratorModal"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: '#11213A', color: '#fff', padding: 12 }}
            >
              <h5 className="modal-title" id="exampleModalLabel">
                PASSWORD
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => closePwdGenerator()}
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ color: '#fff' }}>
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Generate the password for{' '}
                <b
                  style={{ fontWeight: 'bold' }}
                >{`${user.name} ${user.surname}`}</b>
              </p>
              <div className="row">
                <div className="col-lg-8">
                  <input
                    placeholder="Password"
                    disabled
                    className="form-control"
                    type="text"
                    value={pwd}
                  />
                </div>
                <div className="col-lg-4">
                  <button
                    type="button"
                    onClick={() => generatePassword()}
                    style={{ backgroundColor: '#139c9b', color: '#fff' }}
                    className="btn btn-sm"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                style={{ display: 'none' }}
                id="closePwdGenerator"
                className="btn btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => closePwdGenerator()}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => saveGeneratedPassword()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Federation;
