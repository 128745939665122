import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Modal from "react-bootstrap/Modal";
import { BackButton, DatatableCard, InputTextLarge, Loader } from '../../components';
// import {  showPasswordModal, saveRole, updateRole } from '../actions';
import { Link } from 'react-router-dom';
import { saveRole, showPasswordModal, updateRoleAsync } from '../../store/ui';



const Role = (props) => {

  const roles = useSelector((state) => state.ui.roles || []);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRoles('admin'));
  //   return () => dispatch(closeNotify())
  // }, [dispatch]);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleInputChange = e => {
    switch (e.target.id) {
      case 'name':
        setName(e.target.value)
        break;
      case 'code':
        setCode(e.target.value.toUpperCase())
        break;

      default:
        break;
    }

  }

  const resetFields = () => {
    setId('');
    setName('');
    setCode('');
  }

  const handleSubmit = e => {

    e.preventDefault();

    let role = { code, id, name }

    id === '' ? dispatch(saveRole({role})) : dispatch(updateRoleAsync({role}))

    resetFields();
    setModalVisibility(false)

  }

  const addRole = () => {
    resetFields()
    setModalVisibility(true)
  }

  // const onPrivilege = (role) => {

  //   setId(role.id)
  //   setCode(role.code)
  //   setName(role.name)
  //   setModalVisibility(true)
  // }

  const onEdit = (role) => {

    setId(role.id)
    setCode(role.code)
    setName(role.name)
    setModalVisibility(true)
  }

  const onDelete = (role) => {
    dispatch(showPasswordModal({deleteItemId:role, deleteSource:'role'}))
  }
  const getNum = (id)=>{
    return roles?.findIndex((el)=>el.id === id)

  }
  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{getNum(row?.id) + 1}</span>
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: true,
    },
    {
      name: "Creation date",
      selector: 'creationDate',
      sortable: true,
      cell: (row, index, column, selector) => <Moment format='DD/MM/YYYY'>{row.creationDate}</Moment>
    },
    {
      cell: (row, index, column, selector) => <Link
        to={`/admin/securite/role-permission/${row.code}`}
        title="Permissions"
        className="btn btn-sm btn-secondary">P</Link>
    },
    {
      cell: (row, index, column, selector) => <button
        onClick={() => onEdit(row)}
        className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button>
    },
    {
      cell: (row, index, column, selector) => <button
        onClick={() => onDelete(row)}
        className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
    },
  ];

  const tableData = {
    columns,
    data: roles
  };


  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-list fa-lg mr-3"></i></span>ROLES LIST</h6>
              <h6 style={{ float: 'right' }} className="h2 d-inline-block text-uppercase mb-0"><button onClick={() => addRole()} style={{ backgroundColor: '#19a0c2', color: '#fff' }} className="btn btn-sm"><i className="fa fa-plus fa-lg"></i> </button></h6>
              <BackButton />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >

                 <DatatableCard tableData={tableData} />

              </div>
            </div>
          </div>
        </div>
        <Modal show={modalVisibility} centered onHide={() => setModalVisibility(false)}>
          <Modal.Header tabIndex='-1' aria-hidden closeButton>{id === '' ? 'New' : 'Edit'} role</Modal.Header>
          <Modal.Body>

            <form onSubmit={handleSubmit}>

              <div className="row">
                <InputTextLarge size={12} title="Name" name="name" id="name" onChange={handleInputChange} value={name} required />
              </div>

              <div className="row">
                <InputTextLarge size={12} title="Code" style={{ textTransform: 'uppercase' }} name="code" id="code" onChange={handleInputChange} value={code} required />
              </div>

              <div class="modal-footer pb-0 pt-0">
                <button
                  type="button"
                  class="btn"
                  onClick={() => setModalVisibility(false)}>
                  Close
              </button>
                <button type="submit" class="btn btn-primary">
                  Save
              </button>
              </div>
            </form>

          </Modal.Body>

        </Modal>
      </div>
    </div>
  );
}

export default Role