import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

// import { getClubs, savePlayerTransfer } from "../screens/admin/actions";

import { DropdownLarge, Loader } from "../../../components";
import ModalFooter from "../../../components/ModalFooter";
import { addPlayerTransferAsync } from "../../../store/players/playerSlice";

const PlayerTransferModal = (props) => {

  const {data:club} = useSelector((state) => state.club);
  const clubList = useMemo(()=>Object.values(club),[club]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [to, setTo] = useState("");

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case "to":
        setTo(e.target.value);
        break;

      default:
        break;
    }
  };
 const callback = (data)=>{
  // console.log(data)
  setLoading(false)
  props.onClose();
 }

  const transfer = () => {
    if(club?.[to]){
      let playerTransfer = {
        to,
        from: props.clubId,
        player: props.user.id,
      };
      setLoading(true)
      dispatch(addPlayerTransferAsync({data:playerTransfer,onStop:callback}));
    }
    
  };

  return (
    <Modal centered show={props.show}>
      <Modal.Body>
        <h1 className="mb-5 text-center">
          Transfert du joueur: {`${props.user.name} ${props.user.surname}`}
        </h1>
        

        <div className="row mb-3">
          <DropdownLarge
            title="De"
            options={clubList}
            dynamic={true}
            value={props.clubId}
            disabled
          />
          <DropdownLarge
            title="A"
            options={clubList}
            dynamic={true}
            name="to"
            id="to"
            onChange={handleInputChange}
            value={to}
          />
        </div>

        <div className="text-center">
          <button className="btn" onClick={props.onClose}>
            Close
          </button>
          <button
            style={{ backgroundColor: "#00387B", color: "#fff" }}
            className="btn"
            onClick={() => transfer()}
          >
            { loading ?  <Loader size="fa-2x" color="#fff" showText={false} /> : "Transférer" } 
          </button>
        </div>
      </Modal.Body>

      <ModalFooter />
    </Modal>
  );
};

export default PlayerTransferModal;
