/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import * as API from "../api/api";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    
    notifyDisplay: "none",
    notifyType: "success",
    notifyMessage: "",
    notifyIcon: "check",
    passwordModal: false,
    deleteItemId: null,
    deleteSource: null,
    sessionExpiredModal: false,
    sessionExpiredSource: "",
    roles: null,
    subRole:{},
    regions: null,
    department: null,
    district: null,
    permissions: null,
    rolePermissions:{},
    matriculeCodeList: null,
    loading: false,
  },

  reducers: {
    initCloseLoading: (state) => {
      state.loading = !state.loading;
    },
    showPasswordModal: (state, { payload }) => {
      const { deleteItemId, deleteSource } = payload;
      console.log(payload);
      state.passwordModal = true;
      state.deleteItemId = deleteItemId;
      state.deleteSource = deleteSource;
    },
    hidePasswordModal: (state, { payload }) => {
      state.passwordModal = false;
    },
    changeUiLoader: (state, { payload }) => {
      state = { ...state, ...payload };
      return state;
    },
    notifySuccess: (state, { payload }) => {
      state.notifyDisplay = "";
      state.notifyType = "success";
      state.notifyMessage = payload;
      state.notifyIcon = "check";
    },
    notifyError: (state, { payload }) => {
      state.notifyDisplay = "";
      state.notifyType = "danger";
      state.notifyMessage = payload || "Une erreur est survenue !";
      state.notifyIcon = "exclamation-triangle";
    },
    closeNotify: (state) => {
      state.notifyDisplay = "none";
      state.passwordModal = false;
    },
    closeSessionExpiredModal: (state) => {
      state.sessionExpiredModal = false;
      state.sessionExpiredSource = "";
    },
    openSessionExpiredModal: (state, { payload }) => {
      state.sessionExpiredModal = true;
      state.sessionExpiredSource = payload;
      state.loading = false;
    },

    initRegion: (state, { payload }) => {
      state.regions = payload;
    },
    initDepartment: (state, { payload }) => {
      // console.log("department", payload);
      if (!state.department) {
        state.department = { [payload?.regionId]: payload.data };
      } else {
        state.department[payload?.regionId] = payload.data;
      }
    },
    initArrondissment: (state, { payload }) => {
      // console.log("district", payload);
      if (!state.district) {
        state.district = { [payload?.departmentId]: payload.data };
      } else {
        state.district[payload?.departmentId] = payload.data;
      }
    },
    // role actions
    initRole: (state, { payload }) => {
      state.roles = payload;
    },
    initSubRole:(state, { payload }) => {
      state.subRole[payload.role] = payload.data;
    },
    addRole: (state, { payload }) => {
      state.roles = [...state.roles, payload];
    },
    updateRole: (state, { payload }) => {
      state.roles.forEach((role, i) => {
        if (role.id === payload.id) {
          state.roles[i] = payload;
        }
      });
    },
    deleteRole: (state, { payload }) => {
      state.roles = state.roles.filter((role) => role.id !== payload);
    },
    // end of roles actions

    // permissions actions
    initPermissions: (state, { payload }) => {
      state.permissions = payload;
    },
    addPermission: (state, { payload }) => {
      state.permissions = [payload, ...state.permissions];
    },
    updatePermission: (state, { payload }) => {
      state.permissions.forEach((perm, i) => {
        if (perm.id === payload.id) {
          state.permissions[i] = payload;
        }
      });
    },
    deletePermission: (state, { payload }) => {
      state.matriculeCodeList = state.permissions.filter(
        (role) => role.id !== payload
      );
    },
    initPermRole:(state, payload)=>{
      console.log("payload",payload)
      state.rolePermissions[payload?.roleCode] = payload.data
      return state
    },
    // end permissions actions
    // matricule code actions
    initMatriculeCode: (state, { payload }) => {
      state.matriculeCodeList = payload;
    },
    addMatriculeCode: (state, { payload }) => {
      state.matriculeCodeList = [payload, ...state.matriculeCodeList];
    },
    updateMatriculeCode: (state, { payload }) => {
      state.matriculeCodeList.forEach((perm, i) => {
        if (perm.id === payload.id) {
          state.matriculeCodeList[i] = payload;
        }
      });
    },
    deleteMatriculeCode: (state, { payload }) => {
      state.matriculeCodeList = state.matriculeCodeList.filter(
        (role) => role.id !== payload
      );
    },
    // end matricule code actions
  },
  extraReducers: (builder) => {},
});

const langReducer = uiSlice.reducer;
export const {
  showPasswordModal,
  hidePasswordModal,
  changeUiLoader,
  notifyError,
  notifySuccess,
  closeNotify,
  closeSessionExpiredModal,
  openSessionExpiredModal,
  initRole,
  initRegion,
  addRole,
  updateRole,
  deleteRole,
  initCloseLoading,
  initPermissions,
  addPermission,
  updatePermission,
  deletePermission,
  initMatriculeCode,
  addMatriculeCode,
  updateMatriculeCode,
  deleteMatriculeCode,
  initDepartment,
  initArrondissment,
  initPermRole,
  initSubRole
} = uiSlice.actions;

export default langReducer;

export const getRoles = (source) => async (dispatch, getState) => {
  if (!getState().ui.roles) {
    dispatch(initCloseLoading());
    try {
      const response = await API.getRoles();
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(initRole(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get role", err);
    } finally {
      dispatch(initCloseLoading());
    }
  } else {
    console.log("roles already loaded");
  }
};

export const getSubRole = ({role}) => async (dispatch, getState) => {
  
  if (!getState().ui.subRole?.[role]) {
    dispatch(initCloseLoading());
    try {
      const response = await API.getSubRoles(5);
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(initSubRole({role,data:response.data}));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get role", err);
    } finally {
      dispatch(initCloseLoading());
    }
  } else {
    console.log("roles already loaded");
  }
};



// to get region here
export const getRegions = (source) => async (dispatch, getState) => {
  if (!getState().ui.regions) {
    dispatch(initCloseLoading());
    try {
      const response = await API.getRegions();
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(initRegion(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get region", err);
    } finally {
      dispatch(initCloseLoading());
    }
  } else {
    console.log("regions already loaded");
  }
};

// to get department information
export const getDepartment =
  ({ regionId, callback = () => {} }) =>
  async (dispatch, getState) => {
    if (!getState().ui.department?.[regionId]) {
      try {
        const response = await API.getDepartements(regionId);
        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
        } else {
          if (response.status === 200) {
            dispatch(initDepartment({ regionId, data: response.data }));
          } else {
            dispatch(notifyError());
          }
        }
      } catch (err) {
        console.log("error when get deparment", err);
      } finally {
        callback();
      }
    } else {
      console.log("deparment already loaded");
    }
  };
// to get Arrondissment information
export const getArrondissment =
  ({ departmentId, callback = () => {} }) =>
  async (dispatch, getState) => {
    if (!getState().ui.district?.[departmentId]) {
      try {
        const response = await API.getArrondissements(departmentId);
        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
        } else {
          if (response.status === 200) {
            dispatch(initArrondissment({ departmentId, data: response.data }));
          } else {
            dispatch(notifyError());
          }
        }
      } catch (err) {
        console.log("error when get arrondissment", err);
      } finally {
        callback();
      }
    } else {
      console.log("arrondissment already loaded");
    }
  };

// to save role for admin

export const saveRole =
  ({ role, callback }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.saveRole(role);
      console.log("role", role);
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        console.log("401", response);
      } else {
        if (response.status === 201) {
          console.log("new role added here", response);
          dispatch(addRole(response?.data));
          dispatch(notifySuccess("Save Successful !"));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when save role", err);
    }
  };

export const deleteRoleAsync =
  ({ id, pwd }) =>
  async (dispatch, getState) => {
    try {
      let user = {
        pwd,
        accessToken: localStorage.getItem("fvAccessToken"),
      };
      console.log("delete role async", user);
      const checkPasswordResponse = await API.checkPassword(user);

      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === "401"
      ) {
        dispatch(openSessionExpiredModal());
        dispatch(notifyError("Email or password incorrect. "));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deleteRole(id);

          if (response.status === 200) {
            dispatch(notifySuccess("Delete Successful !"));
            dispatch(deleteRole(id));
            dispatch(hidePasswordModal());
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateRoleAsync =
  ({ role, callback }) =>
  async (dispatch, getState) => {
    console.log("role to update", role);
    try {
      const response = await API.updateRole(role);
      console.log("response when update role", response);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          console.log("update role ", response);

          dispatch(updateRole(response?.data));
          callback?.("role");

          dispatch(notifySuccess("Update Successful !"));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

// permissions sections here
export const getPermissionAsync = ({onStop=()=>{}}) => async (dispatch, getState) => {
  if (!getState().ui.permissions) {
    
    try {
      const response = await API.getPermission();

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(initPermissions(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when loading permissions", error);
    } finally {
      onStop()
    }
  }
};

export const getRolePermissionAsync = ({roleCode})=>async(dispatch , getState)=>{
  // console.log("role code", roleCode);
  if(!getState()?.ui?.permissions?.[roleCode]){
    console.log("role code", roleCode);
    try {
      const response = await API.getRolePermission(roleCode);
  
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          console.log({roleCode,data:response.data})
          dispatch(initPermRole({roleCode,data:response.data}));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when load permission of role",error);
    }

  }
}

export const deletePermissionAsync =
  ({ id, pwd }) =>
  async (dispatch, getState) => {
    try {
      let user = {
        pwd,
        accessToken: localStorage.getItem("fvAccessToken"),
      };

      const checkPasswordResponse = await API.checkPassword(user);
      console.log("delete permission ", { id, pwd });
      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === "401"
      ) {
        dispatch(openSessionExpiredModal());
        dispatch(notifyError("Email or password incorrect. "));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deletePermission(id);

          if (response.status === 200) {
            dispatch(notifySuccess("Delete Successful !"));
            dispatch(deletePermission(id));
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updatePermissionAsync =
  ({ permission }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.updatePermission(permission);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        // yield put(authResult());
      } else {
        if (response.status === 200) {
          // yield put(authResult());
          // payload.callback('permission')
          dispatch(updatePermission(response.data));
          dispatch(notifySuccess("Update Successful !"));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      // yield put(authResult());
      console.log(error);
    }
  };

export const savePermissionAsync =
  ({ permission }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.savePermission(permission);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        // yield put(authResult());
      } else {
        if (response.status === 201) {
          // yield put(authResult());
          // yield put(actions.getPermissions());
          // payload.callback('permission')
          dispatch(addPermission(response.data));
          dispatch(notifySuccess("Save Successful !"));
        } else {
          // yield put(authResult());
          dispatch(notifyError());
        }
      }
    } catch (error) {
      // yield put(authResult());
      console.log("error whwn add permission here", error);
    }
  };

// matricule code here

export const getMatriculeCodeAsync = () => async (dispatch, getState) => {
  if (!getState().ui.matriculeCodeList) {
    try {
      const response = await API.getMatriculeCode();

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(initMatriculeCode(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when get matricule code list", error);
    }
  }
};

export const saveMatriculeCodeAsync =
  ({ matriculeCode }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.saveMatriculeCode(matriculeCode);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        // dispatch(authResult());
      } else {
        if (response.status === 201) {
          dispatch(addMatriculeCode(response.data));
          // yield put(authResult());
          // yield put(actions.getMatriculeCodes());
          // payload.callback('matriculeCode')
          dispatch(notifySuccess("Save Successfully !"));
        } else {
          // yield put(authResult());
          dispatch(notifyError());
        }
      }
    } catch (error) {
      // yield put(authResult());
      console.log("error when add matricule code ", error);
    }
  };

export const deleteMatriculeCodeAsync =
  ({ pwd, id }) =>
  async (dispatch, getState) => {
    try {
      let user = {
        pwd,
        accessToken: localStorage.getItem("fvAccessToken"),
      };

      const checkPasswordResponse = await API.checkPassword(user);

      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === "401"
      ) {
        dispatch(openSessionExpiredModal());
        dispatch(notifyError("Email or password incorrect. "));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deleteMatriculeCode(id);

          if (response.status === 200) {
            dispatch(notifySuccess("Delete Successful !"));
            dispatch(deleteMatriculeCode(id));
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when delete matricule code", error);
    }
  };

export const updateMatriculeCodeAsync =
  ({ matriculeCode }) =>
  async (dispatch, getState) => {
    try {
      const response = await API.updateMatriculeCode(matriculeCode);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        
      } else {
        if (response.status === 200) {
         
          dispatch(updateMatriculeCode(response.data));
          dispatch(notifySuccess("Update Successful !"));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      
      console.log("error when update matricule", error);
    }
  };
