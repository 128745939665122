import React from "react";
import { Modal } from "react-bootstrap";
import useLang from "../../../hook/useLang";

const EExitModal = ({ handleClose, show, onExit }) => {
  const lang = useLang();
  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
      <Modal.Body>
        <div className="d-block justify-content-center text-center w-100">
          <img
            src="/assets/images/exit.png"
            alt="scored"
            width={120}
            height={120}
          />
          <h3 className="mt-3">{lang?.AreYouSureYouWantToExit}</h3>
          <small>{lang?.AllTheCurrentDataWillBeLost}</small>
        </div>

        <div className="d-flex mt-3">
          <button className="btn btn-sm btn-danger w-50" onClick={onExit}>
            {lang?.exit}
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            {lang?.close}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EExitModal;
