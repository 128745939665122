export const authEn = {
  sign_in: "Sign In",
  connect: "Log In",
  sign_up: "Sign Up",
  iSignUp: "Sign Up Now",
  password: "password",
  email: "Email",
  name: "Name",
  surname: "Surname",
  dateOfBirth: "Date Of Birth",
  placeOfBirth: "Place of birth",
  gender: "Gender",
  maritalStatus: "Marital Status",
  profession: "Profession",
  league: "league",
  termsAndConditions: "Terms And Conditions",
  phone: "Phone",
  schoolLevel: "Educational Level",
  next: "Next",
  previous: "Previous",
  region: "Region",
  department: "Department",
  district: "District",
  iAm: "I am",
  creationDate: "creationDate",
  observation: "Observation",
  dateObtained: "Date obtained",
  location: "Location",
  instructor: "Instructor",
  entraineur_niveau_0: "International Referee",
  entraineur_niveau_1: "National Referee",
  entraineur_niveau_2: "Regional Referee",
  entraineur_niveau_3: "Departmental Referee",
  save: "Save",
  close: "Close",
  professionalInfo: "Professional information",
  add_image: "Add Image",
};
