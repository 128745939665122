// export function* getUsersWorker(payload) {
//     try {
//       const ligue = localStorage.getItem("fvApp");

//       const roleResponse = yield call(API.getRole, payload.code);

//       let response;

//       if (
//         String(roleResponse).substring(String(roleResponse).length - 3) === "401"
//       ) {
//         yield put(actions.openSessionExpiredModal());
//       } else {
//         if (ligue === ADMIN_LOCALS) {
//           response = yield call(
//             API.getUsers,
//             roleResponse.data?.id,
//             payload?.page,
//             payload?.perPage
//           );
//         } else {
//           let loggedInResponse = yield call(
//             API.getLoggedInUser,
//             localStorage.getItem("fvAccessToken")
//           );
//           response = yield call(
//             API.getUsersByLigue,
//             roleResponse.data?.id,
//             loggedInResponse.data.league
//           );
//         }

//         if (String(response).substring(String(response).length - 3) === "401") {
//           yield put(actions.openSessionExpiredModal());
//         } else {
//           if (response.status !== 200) {
//             yield put(actions.notifyError());
//           } else {
//             switch (payload.code) {
//               case "ARBIT":
//                 yield put(actions.getRefereesSuccess(response.data));
//                 return;
//               case "COACH":
//                 yield put(actions.getCoachsSuccess(response.data));
//                 return;
//               case "JOUER":
//                 yield put(actions.getPlayersSuccess(response.data));
//                 return;
//               case "FEDER":
//                 yield put(actions.getFederationSuccess(response.data));
//                 return;
//               case "LEAGU":
//                 yield put(actions.getLeagueSuccess(response.data));
//                 return;
//               case "CLUB":
//                 yield put(actions.getClubsAdminSuccess(response.data));
//                 return;

//               default:
//                 break;
//             }
//           }
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
// }
// import { createSlice } from "@reduxjs/toolkit";

import { notifyError, notifySuccess, openSessionExpiredModal } from "../ui";
import { ADMIN_LOCALS } from "../../utilities/locals";
import * as API from "../../api/api";

// const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     data: {},
//     loaded: false,
//     loading: false,
//     sending: false,
//     count: 0,
//   },
//   reducers: {
//     initReferee: (state, { payload }) => {
//       // console.log("init referee", payload);
//       // eslint-disable-next-line no-unused-expressions
//       payload?.forEach((match) => {
//         state.data[match?.id] = match;
//       });
//       state.count = payload.length;
//       state.loaded = true;
//       state.loading = false;
//     },
//     initStopLoading: (state) => {
//       state.loading = !state.loading;
//     },
//   },
//   extraReducers: (builder) => {},
// });

// const userReducer = userSlice.reducer;

// export const { initReferee, initStopLoading, initCount, changeStatLoaded } =
//   userSlice.actions;
// export default userReducer;

// ici code représente le type de user que tu veux recupérer
// page and perPage isn't required

export const getUsersAsync =
  ({ code, page, perPage, callback=()=>{}, onSuccess=()=>{},onStop=()=>{} }) =>
  async (dispatch, getState) => {
    try {
      const ligue = localStorage.getItem("fvApp");
      const user = getState().auth.user;

      const roleResponse = await API.getRole(code);

      let response;

      if (
        String(roleResponse).substring(String(roleResponse).length - 3) ===
        "401"
      ) {
        dispatch(openSessionExpiredModal());
      } else {
        if (ligue !== ADMIN_LOCALS) {
          response = await API.getUsers(roleResponse.data?.id, page, perPage);
        } else {
          response = await API.getUsersByLigue(
            roleResponse.data?.id,
            user?.league
          );
        }

        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
        } else {
          if (response.status !== 200) {
            dispatch(notifyError());
          } else {
            onSuccess(response.data)
            callback(response.data);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }finally{
      onStop()
    }
  };

export const getUserInfoAsync =
  ({ userId, onSuccess=()=>{},onStop=()=>{} }) =>
  async (dispatch, getState) => {
    
      try {
        // dispatch(initStopLoading());
        let response = await API.getUser(userId);

        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
        } else {
          if (response.status === 200) {
            
            onSuccess(response.data);
          } else {
           
            dispatch(notifyError());
          }
        }
        // console.log("response",response)
      } catch (error) {
       
        console.log(error);
      }finally{
        onStop()
      }
  };

  // to save user form db
export const saveUserAsync =
({ user, onSuccess = () => {},onStop=()=>{} }) =>
async (dispatch, getState) => {
  
  delete user?.picture;
  delete user?.signature;
  try {
    // dispatch(initStopSending());

    const checkDuplication = await API.checkDuplicateUser({
      name: user?.name,
      surname: user?.surname,
      profession: user?.profession,
    });
    //console.log("user to save", user);

    if (checkDuplication.data?.length > 0) {
      dispatch(notifyError(`User already exist !`));
     
      //
    } else {
      const response = await API.saveUser(user);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
        
      } else {
        if (response.status === 201) {
          // dispatch(addUser(response.data));
          onSuccess(response.data)
          // callback(true);
          dispatch(notifySuccess("Save Successful !"));
        } else {
          dispatch(notifyError());
          
        }
      }
    }
  } catch (error) {
    console.log("error when save user async", error);
    dispatch(notifyError());
    
    // callback(false);
  }finally{
    onStop()
  }
};
// update user from db

export const updateUserAsync =
({ user, source = "",onSuccess = () => {},onStop=()=>{} }) =>
async (dispatch, getState) => {
  delete user?.picture;
  delete user?.signature;
  console.log("user to update", user);
  try {
    let response;
    // dispatch(initStopSending());
    if (source === "inscrit") {
      response = await API.enableUser(user);
    } else {
      console.log("user to update", user);
      response = await API.updateUser(user);
    }

    if (String(response).substring(String(response).length - 3) === "401") {
      dispatch(openSessionExpiredModal());
     
    } else {
      if (response.status === 200) {
        // dispatch(addUser(response.data));
        onSuccess(response.data);
        dispatch(notifySuccess("Update Successful !"));
        
      } else {
        console.log("response", response);
        dispatch(notifyError());
        
      }
    }
  } catch (error) {
    console.log("error when save user", error);
    
  }finally{
    onStop()
  }
};

export const deleteUserAsync =
  ({ id, pwd, onSuccess, onStop=()=>{} }) =>
  async (dispatch, getState) => {
    try {
      
      let user = {
        pwd: pwd,
        accessToken: localStorage.getItem("fvAccessToken"),
      };

      const checkPasswordResponse = await API.checkPassword(user);
      console.log("user to delete", user)

      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === "401"
      ) {
        dispatch(openSessionExpiredModal())
        dispatch(notifyError("Email or password incorrect. "));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deleteUser(id);

          if (response.status === 200) {
            dispatch(notifySuccess("Delete Successful !"));
            onSuccess(id)
            // dispatch(getClubs());
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }finally{
      onStop()
    }
  };