import React from "react"
import { useNavigate } from "react-router-dom";
import useLang from "../../../hook/useLang";
export default function Options({row,}){
  const lang = useLang()
    const navigate = useNavigate()
    const onEdit = (tournois) => {
        navigate("/admin/tournois-form", { state: tournois });
      };
    
      const onGenerateCalenda = (tournois) => {
        navigate(`/admin/tournois-calenda`, { state: tournois });
      };
    
      const navigateToDetails = (tournois) => {
        navigate("/admin/tournois-details", { state: tournois });
      };
    
    return(
        <div className="dropdown">
          <button
            className="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <button
              className="dropdown-item"
              onClick={() => navigateToDetails(row)}
            >
              <span>
                <i className="fa fa-list mr-1"></i> {lang.t("detail")}
              </span>
            </button>

            <div className="dropdown-divider"></div>
            <button
              className="dropdown-item"
              onClick={() => onGenerateCalenda(row)}
            >
              <span className="text">
                <i className="fa fa-calendar mr-1"></i>
                {lang.t("Generate calenda")}
              </span>
            </button>

            <div className="dropdown-divider"></div>
            <button className="dropdown-item">
              <span className="text-danger">
                <i className="fa fa-trash mr-1"></i> {lang.t("delete")}
              </span>
            </button>
          </div>
        
        </div>
    )
}