import { createSlice } from "@reduxjs/toolkit";
import en from "../i18n/en";
import fr from "../i18n/fr";
//import { appApi, UserResponse } from "../../api/api";
const getLocale = () => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  const fbvLang = sessionStorage.getItem("fbvLang");
  if (fbvLang) {
    return fbvLang;
  } else {
    const locale = userLocale === "en-US" || userLocale === "en" ? "en" : "fr";
    sessionStorage.setItem("fbvLang", locale);
    return locale;
  }
};

const langSlice = createSlice({
  name: "lang",
  initialState: {
    curr: getLocale(),
    fr,
    en,
  },
  reducers: {
    setLang: (state, { payload }) => {
      state.curr = payload?.curr;
      sessionStorage.setItem("fbvLang", payload?.curr);
    },
  },
  extraReducers: (builder) => {},
});

const langReducer = langSlice.reducer;
export const { setLang } = langSlice.actions;
export const currLangSelector = (state) => state?.lang?.curr;
export const langSelector = (state) =>
  state?.lang?.curr === "en" ? state?.lang.en : state?.lang.fr;
export default langReducer;
