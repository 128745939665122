import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackButton, DatatableCard, Loader } from "../../../components";

import { getStaffClubAsync } from "../../../store/clubs/clubSlice";
import { showPasswordModal } from "../../../store/ui";

const ClubAdmin = (props) => {
  const { staff, loading } = useSelector((state) => state.club);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const club = location.state;
  
  const staffOfClub = useMemo(() => {

    return Object.values(staff).filter(
      (staff) => {
        // console.log(staff?.clubs?.[0]?.id === club?.id)
        return staff?.clubs?.[0]?.id === club?.id
      }
    );
  }, [club.id, staff]);


  useEffect(() => {
    dispatch(getStaffClubAsync());
  }, [dispatch]);

  const onDelete = (user) => {
    dispatch(showPasswordModal({ deleteItemId: user, deleteSource: "user" }));
  };

  const navigateToClubAdmin = () => {
    // console.log("hre in club admin js", club);
    navigate("/admin/club-admin-form", {
      state: { userId: null, club: club.id },
    });
  };

  const columns = [
    {
      name: "Nº",
      width: "50px",
      cell: (row, index, column, id) => <span>{index + 1}</span>,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Surname",
      selector: "surname",
      sortable: true,
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "Matricule",
      selector: "matricule",
      sortable: true,
    },
    {
      name: "Profession",
      selector: "profession",
      sortable: true,
    },
    {
      cell: (row, index, column, selector) => (
        <div className="dropdown">
          <button
            className="btn btn-sm"
            style={{ backgroundColor: "#19a0c2", color: "#fff" }}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-ellipsis-h"></i>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton">
            <Link
              to={{
                pathname: `/admin/club-admin-form`,
              }}
              state={{ userId: row.id, club: club?.id }}
              className="dropdown-item">
              <span>
                <i className="fa fa-edit mr-1"></i> Edit
              </span>
            </Link>

            <div className="dropdown-divider"></div>
            <Link
              to={{
                pathname: "/admin/club-admin-detail",
              }}
              state={{ userId: row.id }}
              className="dropdown-item">
              <span>
                {" "}
                <i className="fa fa-list mr-1"></i> Details
              </span>
            </Link>
            <div className="dropdown-divider"></div>

            <button
              onClick={() => onDelete(row)}
              className="dropdown-item text-danger">
              <span>
                <i className="fa fa-trash mr-1"></i> Delete
              </span>
            </button>
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: staffOfClub,
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                STAFF DU CLUB {club ? club?.name?.toUpperCase() : null}
              </h6>

              <h6
                style={{ float: "right" }}
                className="h2 d-inline-block text-uppercase mb-0">
                <button
                  onClick={() => navigateToClubAdmin()}
                  style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                  className="btn btn-sm">
                  <i className="fa fa-plus fa-lg"></i>{" "}
                </button>
              </h6>
              <div className="dropdown float-right">
                <button
                  className="btn btn-sm"
                  style={{ backgroundColor: "#343a3f", color: "#fff" }}
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i className="fa fa-print"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item">
                    <span>
                      {" "}
                      <i className="fa fa-eye mr-1"></i> Print preview
                    </span>
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item">
                    <span>
                      {" "}
                      <i className="fa fa-print mr-1"></i> Print
                    </span>
                  </button>
                </div>
              </div>
              <BackButton />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? <Loader /> : <DatatableCard tableData={tableData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubAdmin;
