import React from "react";
import { Modal } from "react-bootstrap";
import useLang from "../../../hook/useLang";

const EAssignPointModal = ({
  handleClose,
  show,
  team,
  onAssignPoint,
  time,
}) => {
  const lang = useLang();

  return (
    <Modal show={show} centered size="sm" onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>{lang?.assignPoint?.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-block justify-content-center text-center w-100">
          <img
            src="/assets/images/volleyball-net.png"
            alt="scored"
            width={120}
            height={120}
          />
          <h3 className="mt-3">Gooaaalllll!!!!</h3>
          <hr />
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-0">{lang?.Team}:</p>
            <h1>{team.name}</h1>
          </div>
          <div>
            <p className="mb-0">{lang?.time}:</p>
            <h1>{time}</h1>
          </div>
        </div>

        <div className="d-flex">
          <button
            className="btn btn-sm w-50"
            onClick={onAssignPoint}
            style={{ backgroundColor: "#21517c", color: "white" }}
          >
            {lang?.assignPoint}
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            {lang?.close}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EAssignPointModal;
