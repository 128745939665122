import React from "react";
import { FormButton, InputTextSmall } from ".";
import useLang from "../hook/useLang";

const SignupStepThree = ({
  values,
  handleChange,
  errors,
  left,
  touched,
  handleBlur,
  ...rest
}) => {
  const lang = useLang();
  return (
    <>
      <h4 class="font-weight-bold mb-4">{lang?.professionalInfo}</h4>

      <h6 class="h6">
        <b>{rest.titleName1}</b>
      </h6>
      <div class="row">
        <InputTextSmall
          title={lang?.dateObtained}
          type="date"
          name="adDateObtention"
          id="adDateObtention"
          value={values.adDateObtention}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.adDateObtention}
        />

        <InputTextSmall
          title={lang?.location}
          name="adLieu"
          id="adLieu"
          value={values.adLieu}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.adLieu}
        />

        <InputTextSmall
          title={lang?.instructor}
          name="adFormateur"
          id="adFormateur"
          value={values.adFormateur}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.adFormateur}
        />

        <InputTextSmall
          title={lang?.observation}
          name="adObservation"
          id="adObservation"
          value={values.adObservation}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.adObservation}
        />
      </div>
      <h6 class="h6">
        <b>{rest.titleName2}</b>
      </h6>
      <div class="row">
        <InputTextSmall
          title={lang?.dateObtained}
          type="date"
          name="arDateObtention"
          id="arDateObtention"
          value={values.arDateObtention}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.arDateObtention}
        />

        <InputTextSmall
          title={lang?.location}
          name="arLieu"
          id="arLieu"
          value={values.arLieu}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.arLieu}
        />

        <InputTextSmall
          title={lang?.instructor}
          name="arFormateur"
          id="arFormateur"
          value={values.arFormateur}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.arFormateur}
        />

        <InputTextSmall
          title={lang?.observation}
          name="arObservation"
          id="arObservation"
          value={values.arObservation}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.arObservation}
        />
      </div>
      <h6 class="h6">
        <b>{rest.titleName3}</b>
      </h6>
      <div class="row">
        <InputTextSmall
          title={lang?.dateObtained}
          type="date"
          name="anDateObtention"
          id="anDateObtention"
          value={values.anDateObtention}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.anDateObtention}
        />

        <InputTextSmall
          title={lang?.location}
          name="anLieu"
          id="anLieu"
          value={values.anLieu}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.anLieu}
        />

        <InputTextSmall
          title={lang?.instructor}
          name="anFormateur"
          id="anFormateur"
          value={values.anFormateur}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.anFormateur}
        />

        <InputTextSmall
          title={lang?.observation}
          name="anObservation"
          id="anObservation"
          value={values.anObservation}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.anObservation}
        />
      </div>
      <h6 class="h6">
        <b>{rest.titleName4}</b>
      </h6>
      <div class="row">
        <InputTextSmall
          title={lang?.dateObtained}
          type="date"
          name="aiDateObtention"
          id="aiDateObtention"
          value={values.aiDateObtention}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.aiDateObtention}
        />

        <InputTextSmall
          title={lang?.location}
          name="aiLieu"
          id="aiLieu"
          value={values.aiLieu}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.aiLieu}
        />

        <InputTextSmall
          title={lang?.instructor}
          name="aiFormateur"
          id="aiFormateur"
          value={values.aiFormateur}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.aiFormateur}
        />

        <InputTextSmall
          title={lang?.observation}
          name="aiObservation"
          id="aiObservation"
          value={values.aiObservation}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.aiObservation}
        />
      </div>

      <div className="d-flex justify-content-between mt-5">
        <FormButton
          title={lang?.previous}
          iconLeft="chevron-left"
          onClick={left}
        />
        <button
          style={{ backgroundColor: "#00387B", color: "#fff" }}
          type="submit"
          class="btn rounded "
        >
          {lang?.iSignUp}
        </button>
      </div>
    </>
  );
};

export default SignupStepThree;
