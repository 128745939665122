import React from "react";
import { FileBase64 } from ".";

const InputPicture = ({
  title = "Title",
  placeholder = "Ajouter Une Image",
  ...other_props
}) => {
  return (
    <div class="row">
      <div class="col-12">
        {/* <p>Photo<span class="text-danger ml-1">*</span></p> */}
        <div class="md-form">
          <div class="file-field">
            <a class="btn-floating blue-gradient mt-0 float-left">
              <i class="fas fa-paperclip" aria-hidden="true"></i>
              <FileBase64 {...other_props} />
            </a>

            <div class="file-path-wrapper">
              <input
                type="text"
                class="file-path validate"
                name="picture"
                placeholder={placeholder}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputPicture;
