import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_LOCALS } from "../../utilities/locals";
import * as API from "../../api/api";
import { notifyError, openSessionExpiredModal } from "../ui";

const leagueSlice = createSlice({
  name: "league",
  initialState: {
    data:{},
    user:{},
    roles:{},
    loaded: false,
    loading: false,
    sending: false,
    statLoaded: false,
  },
  reducers: {
    initLeagueType: (state, { payload }) => {
      state.data[payload.type] = {...(state.data[payload.type] || {})}
      // eslint-disable-next-line no-unused-expressions
      payload?.data?.forEach((user) => {
        state.data[payload.type][user?.id]= user
      });
    },
    addUserLeague: (state, { payload }) => {

      if(!state.data[payload?.type]){
        state.data[payload?.type] = {}
      }
      state.data[payload?.type][payload?.data?.id]={
        ...(state.data?.[payload?.type]?.[payload?.data?.id] || {}),
        ...payload.data
      } 
    },
    // to delete user 
    deleteUserLeague:(state,payload)=>{
      delete state.data?.[payload?.type]?.[payload?.id]
    },
    initUserLeagueInfo:(state, {payload})=>{
      state.user[payload?.id] = {...payload}
    },
    initStopLoading: (state) => {
      state.loading = !state.loading
    },
    initCount: (state, { payload }) => {
      state.count = payload;
    },
    addSubRole: (state, { payload })=>{
      state.roles[payload.leagueType] = payload?.data
    },
    changeStatLoaded: (state) => {
      state.statLoaded = true;
    },
  },
  extraReducers: (builder) => {},
});

const leagueReducer = leagueSlice.reducer;

export const { 
  initLeagueType,
  initStopLoading, 
  initCount, 
  changeStatLoaded , 
  initUserLeagueInfo , 
  addSubRole , 
  addUserLeague,
  deleteUserLeague, 
} = leagueSlice.actions;
export default leagueReducer;

export const initLeagueCount = () => async (dispatch, getState) => {
  if (!getState().league.statLoaded) {
    const ligue = localStorage.getItem("fvApp");
    const refereeRoleResponse = await API.getRole("LEAGU");

    let refereeResponse;
    if (ligue !== ADMIN_LOCALS) {
      refereeResponse = await API.getUsersCount(refereeRoleResponse.data.id);
      refereeResponse?.status === 200
        ? dispatch(initCount(refereeResponse?.data))
        : dispatch(notifyError(""));
    }
    dispatch(changeStatLoaded());
  }
};

export const  getUserByLeagueAsync = ({leagueType})=> async(dispatch , getState) => {
  // console.log("league type", getState().league?.data?.[leagueType]);
  if(!getState().league?.data?.[leagueType]){
    // console.log("league type", leagueType);
    try {
      
      dispatch(initStopLoading())
      const response = await API.getLigueUsers(leagueType)
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          // console.log(response?.data)
          dispatch(initLeagueType({type:leagueType, data:response.data}))
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when get user of league",error);
    }finally{
      dispatch(initStopLoading())
    }  
  }
}

export const  getSubRolesAsync = ({leagueType})=>async(dispatch , getState)=>{
  try {
    const role =  {LIGUE_RÉGIONALE:7,LIGUE_DÉPARTEMENTAL:8}
    const league = leagueType?.replaceAll(" ","_")
    if(!getState().league?.roles?.[league]){
      const crole = role[league] || 9
      // 9 => "LIGUE D'ARRONDISSEMENT"
      const response = await API.getSubRoles(crole)
  
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          // console.log("response sub role",league,response?.data)
          dispatch(addSubRole({leagueType:league,data:response.data}))
          // yield put(actions.getSubRolesSuccess(response.data));
        } else {
          dispatch(notifyError());
        }
      }

    }else{
      console.log(league , "already loaded")
    }
  } catch (error) {
    console.log(error);
  }
}