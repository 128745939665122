/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { SERVER } from "../../utilities";
import axios from "axios";
import {
  closeSessionExpiredModal,
  notifyError,
  notifySuccess,
  openSessionExpiredModal,
} from "../ui";

const accessToken = localStorage.getItem("fvAccessToken");
const localUser = sessionStorage.getItem("cybexai-fcvb-authUser");
const connectedUser = localUser ? JSON.parse(localUser) : null;
console.log({connectedUser})

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: connectedUser,
    loading: false,
    accessToken: accessToken || null,
  },
  reducers: {
    changeLoading: (state) => {
      state.loading = !state.loading;
    },
    initUser: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    authResult: (state) => {
      localStorage.removeItem("fvAccessToken");
    },
    logout: (state) => {
      state.user = null
    }
  },
  extraReducers: (builder) => {},
});

const authReducer = authSlice.reducer;
export const { changeLoading, initUser, setUser, authResult,logout } =
  authSlice.actions;

export default authReducer;

export const signin =
  ({ user, callback, source, history }) =>
  (dispatch, getState) => {
    dispatch(changeLoading());
    axios
      .post(`${SERVER}auth/signin`, user)
      .then((response) => {
        localStorage.setItem("fvAccessToken", response.data?.accessToken);

        if (response?.data !== []) {
          localStorage.setItem("fvUser", response.data?.name);
          if (response.data?._role?.code === "ADMIN") {
            localStorage.setItem("fvApp", "15sdf#4%$#sd#@_)54");
          } else {
            localStorage.setItem("fvApp", "pk((^%*kj$%&hg81+_)(&");
          }
          dispatch(initUser(response?.data));
        }

        callback?.();

        dispatch(closeSessionExpiredModal());
      })
      .catch((err) => {
        console.log("error when sign in ", err);
        dispatch(notifyError("E-mail ou mot de passe incorrect. "));
        dispatch(openSessionExpiredModal());
      })
      .finally(() => {
        dispatch(changeLoading());
      });
  };

export const getLoggedInUser =
  ({ callback = () => {} }) =>
  (dispatch, getState) => {
    
    if (!getState().auth.user?.isAuth) {
      dispatch(changeLoading());
      const accessToken = getState().auth?.accessToken;
      axios
        .post(
          SERVER + "users/find/",
          { accessToken },
          { headers: { "cybexai-fcvb-token": accessToken } }
        )
        .then((response) => {
          sessionStorage.setItem(
            "cybexai-fcvb-authUser",
            JSON.stringify(response.data)
          );
          dispatch(setUser({...response.data, isAuth:true}));
          callback?.();
        })
        .catch((err) => {
          console.log("error when logging user", err);
        }).finally(()=>{
          dispatch(changeLoading());
        })
    }
  };

export const signup = (user, callback) => (dispatch, getState) => {
  dispatch(changeLoading());
  axios
    .post(SERVER + "users/check", user, {
      headers: {
        "cybexai-fcvb-token": localStorage.getItem("fvAccessToken"),
      },
    })
    .then((response) => {
      if (response?.data.length > 0) {
        dispatch(notifyError(`Cet Utilisateur existe déjà !`));
      } else {
        axios
          .post(`${SERVER}auth/signup`, user)
          .then((response) => {
            dispatch(notifySuccess("Enregistré avec Succès !"));
            dispatch(setUser(response.data));
            // return response
          })
          .catch((err) => {
            dispatch(notifyError("" + err?.message));
          });
        callback?.();
      }
    })
    .catch((err) => {
      dispatch(notifyError("" + err?.message));
    })
    .finally(() => dispatch(changeLoading()));
};

export const checkPassword = (user) => (dispatch, getState) => {
  axios
    .post(`${SERVER}auth/check-password`, user)
    .then((response) => {
      return response;
    })
    .catch((response) => {
      return response;
    });
};
