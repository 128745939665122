import React from 'react';
export default function MatchReportHeader({
  pictureTeamA,
  nameTeamA,
  pictureTeamB,
  nameTeamB,
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        height: 80,
        border: '1px solid',
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{ width: '40%', borderRight: '1px solid', p: '20px' }}
      >
        <img
          className="rounded-circle"
          // src={matchDetails ? matchDetails._participantOne.picture : ''}
          src={pictureTeamA}
          style={{ height: 50, width: 50, backgroundColor: 'grey' }}
        />
        <h4 className="ml-4">
          {
            //   matchDetails._participantOne?.name
          }{' '}
          {nameTeamA}{' '}
        </h4>
      </div>
      <div className="d-flex align-items-center" style={{ width: '20%' }}>
        <h4
          className="d-flex justify-content-center"
          style={{ width: '50%', borderRight: '1px solid' }}
        >
          A
        </h4>
        <h4 className="d-flex justify-content-center" style={{ width: '50%' }}>
          B
        </h4>
      </div>
      <div
        className="d-flex align-items-center"
        style={{ width: '40%', borderLeft: '1px solid' }}
      >
        <img
          className="rounded-circle"
          // src={matchDetails._participantTwo.picture}
          src={pictureTeamB}
          style={{ height: 50, width: 50 }}
        />
        <h4 className="ml-4">
          {/* {matchDetails._participantTwo?.name}  */}
          {nameTeamB}
        </h4>
      </div>
    </div>
  );
}
