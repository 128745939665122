import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SecuriteButton } from '../../components';

const Securite = (props) => {
  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-lock fa-lg mr-3"></i></span>CONFIGURATIONS</h6> */}
            </div>
          </div>

          <SecuriteButton title="Roles" icon="barcode" link="role" />
          <SecuriteButton
            title="Permissions"
            icon="barcode"
            link="permission"
          />
          <SecuriteButton
            title="Matricule Codes"
            icon="barcode"
            link="matricule-code"
          />
        </div>
      </div>
    </div>
  );
};

export default Securite;
