import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLang from '../../../hook/useLang';
import { hasPermission } from '../../../utilities/auth';
import { showPasswordModal } from '../../../store/ui';
import { useDispatch } from 'react-redux';

export default function Options({ row, user }) {
  const lang = useLang();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onEdit = (match) => {
    navigate('/admin/match-form', { state: match });
  };

  const onDetail = (match) => {
    navigate('/admin/match-detail', { state: match });
  };

  const onDelete = (match) => {
    dispatch(showPasswordModal({deleteItemId:match, deleteSource:'match'}));
  };
  const onGenerateReport = (match) => {
    navigate('/admin/match-report', { state: match });
  };
  const onAddResult = (id) =>
    navigate('/admin/match-result', { state: { id } });

  return (
    <div className="dropdown">
      <button
        className="btn btn-sm"
        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fa fa-ellipsis-h"></i>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        {user
          ? hasPermission(user, ['VIEW_MATCH']) && (
              <button onClick={() => onDetail(row)} className="dropdown-item">
                <span>
                  <i className="fa fa-list mr-1"></i> {lang.t('detail')}
                </span>
              </button>
            )
          : null}

        <div className="dropdown-divider"></div>
        {user
          ? hasPermission(user, ['EDIT_MATCH']) && (
              <button className="dropdown-item" onClick={() => onEdit(row)}>
                <span>
                  <i className="fa fa-edit mr-1"></i> {lang.t('edit')}
                </span>
              </button>
            )
          : null}
        <div className="dropdown-divider"></div>
        {user
          ? hasPermission(user, ['EDIT_MATCH']) && (
              <button
                className="dropdown-item"
                onClick={() => onAddResult(row?.id)}
              >
                <span>
                  <i className="fa fa-circle mr-1"></i>{' '}
                  {lang.t('Entrée manuel')}
                </span>
              </button>
            )
          : null}
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" onClick={() => onGenerateReport(row)}>
          <span>
            <i className="fa fa-file mr-1"></i> {lang.t('generate_report')}
          </span>
        </button>
        <div className="dropdown-divider"></div>
        <button className="dropdown-item" onClick={() => onDelete(row)}>
          <span className="text-danger">
            <i className="fa fa-trash mr-1"></i> {lang.t('delete')}
          </span>
        </button>
      </div>
    </div>
  );
}
