const EScoreSheetEn = {
  technicalTimeout: "TECHNICAL TIMEOUT",
  match: "MATCH",
  exit: "Exit",
  assignPoint: "Assign Point",
  updateCaptaimTeam: "Updated Captain on court for team",
  manualChange: "MANUAL CHANGES",
  timeout: "TIMEOUT",
  team: "Team",
  Time: "Time",
  timeoutHistory: "Timeout history",
  startTimeout: "Start Timeout",
  endTime: "End Time",
  startTime: "start Time",
  AreYouSureYouWantToExit: "Are you sure you want to exit?",
  AllTheCurrentDataWillBeLost: "All the current data will be lost",
  player: "Player",
  substitute: "Substitute",
  replaceWith: "Replaced with",
  saveInjury: "Save Injury",
  injuryHistory: "Injury history",
  changeLibero: "Change Libero",
  liberoHistory: "Libero History",
  addLibero: "ADD LIBERO",
  tournament: "TOURNAMENT",
  captain: "Captain",
  changeCaptainInCour: "change of captain on court",
  referees: "referees",
  popupForLibero: "Libero must exit",
  checkAcciddent: "Check Accidents",
  reportCaseAccident: "report cases of accidents or injuries",
  timeouts: "Timeouts",
  playWithTechnicalTimaeout: "play with technical timeouts",
  numberOfPlayerAuthorize: "Number of players authorize",
  scoreTimer: "Score timer",
  enable15sTimer: "enable 15s timer between 2 points",
  listOfShortcuts: "List of shortcuts to perform different actions",
  SHORTCUTS: "shortcuts",
  shareData: "SHARE DATA",
  ShareInformationWithOtherFederations:
    "Share information with other federations (FIVB included)",
  generate_report: "Generate Report",

  filter_by_tournament: "Filter By Tournament",
  match_list: "MATCH LIST",
  delete: "Delete",
  hide_preview: "Hide Preview",
};
export default EScoreSheetEn;
