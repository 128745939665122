import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import useLang from '../../hook/useLang';
import { showPasswordModal } from '../../store/ui';
import { hasPermission } from '../../utilities/auth';
import { DatatableCard, Dropdown, Loader } from '../../components';
import PlayerTransferModal from './components/PlayerTransferModal';
import { getUsersAsync } from '../../store/users/userSlice';
import { initPlayer } from '../../store/players/playerSlice';
import PlayerMenu from './components/PlayerMenu';

const Player = (props) => {
  const [loading, setLoading] = useState(false);
  const { data, loaded } = useSelector((state) => state.player);
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { data: clubList } = useSelector((state) => state.club);
  const location = useLocation();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(false);
  const [tag, setTag] = useState(location.state?.clubId || '');
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [clubId, setClubId] = useState(location.state?.clubId || '');
  const [user, setUser] = useState('');
  const refToPrint = useRef();
  const transferModal = (id, user) => {
    setShowTransferModal(true);
    setClubId(id);
    setUser(user);
  };

  const players = useMemo(() => {
    return Object.values(data);
  }, [data]);

  const clubs = useMemo(() => Object.values(clubList), [clubList]);

  useEffect(() => {
    if (!loaded) {
      setLoading(true);
      dispatch(
        getUsersAsync({
          code: 'JOUER',
          onSuccess: (data) => {
            setLoading(false);
            dispatch(initPlayer(data));
          },
          onStop: () => {
            setLoading(false);
          },
        })
      );
    }
  }, [dispatch, loaded]);

  const lang = useLang();

  const onDelete = (user) => {
    dispatch(showPasswordModal({ deleteItemId: user, deleteSource: 'player' }));
  };

  const columnsToPrint = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row) => <span>{row?.rowNumber + 1}</span>,
    },
    {
      name: lang?.name ?? 'Name',
      cell: (row) => (
        <span>
          {row.name} {row.surname}
        </span>
      ),
    },
    {
      name: lang?.gender ?? 'Gender',
      selector: 'gender',
      sortable: false,
      cell: (row) => <span> {row?.gender?.[0]} </span>,
    },
    {
      name: 'Matricule',
      selector: 'matricule',
      sortable: false,
    },
    {
      name: 'Club',
      selector: 'clubs[0].name',
      sortable: false,
    },
    {
      name: 'Category',
      selector: 'categorie',
      sortable: true,
    },
  ];

  const columns = [
    ...columnsToPrint,
    {
      cell: (row) => (
        <PlayerMenu
          row={row}
          onDelete={() => onDelete(row)}
          onTransfer={() => transferModal(row.clubs?.[0].id, row)}
        />
      ),
    },
  ];

  const tableData = useMemo(() => {
    if (tag !== '' && tag !== 'show All') {
      const data = players.filter((player) =>
        player.clubs.map((club) => club.id).includes(tag)
      );
      return {
        columns,
        data: data.map((el, rowNumber) => ({ ...el, rowNumber })),
      };
    }
    return {
      columns,
      data: players.map((el, rowNumber) => ({ ...el, rowNumber })),
    };
  }, [players, columns, tag]);

  const handleFilterChange = (e) => {
    setTag(e.target.value);
  };

  const r = useReactToPrint({
    content: () => refToPrint.current,
  });

  const handlePrint = async () => {
    await setPreview(true);
    await r();
    setPreview(false);
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.players_list}
              </h6> */}

              {loggedInUser
                ? hasPermission(loggedInUser, ['ADD_PLAYER']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/player-form"
                        state={{ userId: null }}
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg "></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser
                ? hasPermission(loggedInUser, ['PRINT_PLAYER']) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          class="dropdown-item"
                          type="button"
                          onClick={() => {
                            setPreview(true);
                          }}
                        >
                          <span>
                            {' '}
                            <i class="fa fa-eye mr-1"></i> {lang?.preview_print}
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item" onClick={handlePrint}>
                          <span>
                            {' '}
                            <i class="fa fa-print mr-1"></i> {lang?.print}
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>
          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {clubs.length !== 0 ? (
              <Dropdown
                title="Filter"
                options={clubs}
                dynamic={true}
                onChange={handleFilterChange}
                value={tag}
                showAll
              />
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats" ref={refToPrint}>
                {loading ? (
                  <Loader />
                ) : (
                  <DatatableCard
                    tableData={{
                      ...tableData,
                      columns: preview ? columnsToPrint : tableData.columns,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PlayerTransferModal
        show={showTransferModal}
        clubId={clubId}
        onClose={() => setShowTransferModal(false)}
        user={user}
      />
    </div>
  );
};

export default Player;
