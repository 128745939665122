import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ModalAddTeams = ({
  title,
  teams,
  clubs,
  showTeams,
  setshowTeams,
  setTeams,
  ...other_props
}) => {
  const [searchText, setSearchText] = useState("");
  const addTeam = (team) => {
    if (!teams.includes(team)) {
      return setTeams([...teams, team]);
    } else {
      return setTeams(teams.filter((t) => t?.id !== team?.id));
    }
  };

  return (
    <div
      class="col-lg-6"
      style={{ background: "white", zIndex: 99, position: "relative" }}
    >
      <div class="form-group">
        <label for={title}>{title}</label>
        <div {...other_props}>
          <input
            type="text"
            className="form-control"
            value={
              teams !== null
                ? teams.length + " selected teams"
                : "0 selected teams"
            }
            onClick={() => setshowTeams(!showTeams)}
          />
        </div>
        <Modal
          show={showTeams}
          size="md"
          onHide={() => setshowTeams(false)}
          className="matchDayList"
        >
          <Modal.Body>
            <div
              style={{
                backgroundColor: "#fff",
                paddingTop: "2rem",
                paddingBottom: "2rem",
                width: "50vw",
              }}
              className="flex-col-center"
            >
              <div class="flex-row-between mb-3" style={{ width: "100%" }}>
                <div class="input-group px-3" style={{ width: "60%" }}>
                  <span class="input-group-text" id="basic-addon1">
                    @
                  </span>
                  <input
                    onChange={(ev) => setSearchText(ev?.target?.value)}
                    type="text"
                    class="form-control"
                    placeholder="enter name to search clubs"
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-light"
                  onClick={() => setshowTeams(false)}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>

              <div style={{ height: "70vh", overflowY: "auto", width: "100%" }}>
                {clubs
                  ?.filter((el) =>
                    el?.name?.toLowerCase()?.includes(searchText)
                  )
                  .map((team, i) => (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 5px",
                        cursor: "pointer",
                        padding: "0.5rem 1rem 0.5rem 1rem",
                        backgroundColor: teams?.map((el) => el?.id)?.includes(team?.id)
                          ? "silver"
                          : "#fff",
                      }}
                      key={team?.id + i}
                      onClick={() => addTeam(team)}
                    >
                      <input
                        type="checkbox"
                        value={team.name}
                        name={team.name}
                        checked={teams?.map((el) => el?.id)?.includes(team?.id)}
                        style={{
                          opacity: 1,
                          position: "static",
                          marginRight: "10px",
                          pointerEvents: "all",
                        }}
                      />
                      {team.name}
                    </p>
                  ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ModalAddTeams
