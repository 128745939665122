import { authFr } from "./authFr";
import EScoreSheetFr from "./EScoreSheetFr";
//import { playersFr } from "./playersLang";

const fr = {
  ...authFr,
  ...EScoreSheetFr,
  //...playersFr,
  inProgress: "Traitement en cours...",
  dashboard: "Tableau De Bord",
  players: "JOUEURS",
  profil: "Mon Profil",
  settings: "Paramètres",
  logOut: "Déconnexion",
  referee_detail: "INFORMATIONS SUR L'ARBITRES",
  professional_info: "Informations Professionelles",
  entraîneur_niveau_1: "Entraineur Niveau 1",
  entraîneur_niveau_2: "Entraineur Niveau 2",
  entraîneur_niveau_3: "Entraineur Niveau 3",
  club_list: "LISTE DES CLUBS",
  filter: "Filtre",
  filter_by_league: "Filtrer Par Ligue ",
  coach_list: "LISTES DES ENTRAINEURS",
  coaches: "ENTRAINEURS",
  referees: "ARBITRES",
  enrolled: "INSCRITS",
  myProfile: "Mon Profil",
  edit:"modifier",
  logout: "Deconnexion",
  referee: "Arbitre",
  numberReferee: "Nombre d'Arbitres",
  coach: "Entraineur",
  numberCoaches: "Nombre d'Entraineurs",
  player: "Joueur",
  numberPlayers: "Nombre de Joueurs",
  federation: "Fédération",
  numberFederation: "Membres de la Fédération",
  league: "Ligue",
  leagues: "Ligues",
  numberLeague: "Membres de la Ligue",
  club: "Équipe",
  numberClub: "Nombre d'équipes",
  numberEnrolled: "Nombre de pré-inscrits",
  back: "Retour",

  role: "Rôle",
  roles: "Rôles",
  listOfRoles: "Liste des rôles",
  Names : "Nom",
  code: "Code",
  creationDate: "Date de création",
  Admin: "Administrateur",
  description: "Description",
  permission: "Permissions",
  listOfPermissions: "Liste des Permissions",
  matriculeCodes: "Code Matricule",
  listOfMatricules: "Liste des codes matricule",

  playerList: " Liste de joueurs",
  filterByClub: "Filtrer par équipe",
  gender: "Genre",
  matricule: "Matricule",
  shirtNum: "Dossard",
  edit: "Modiifer",
  details: "Details",
  transfer: "Transfert",
  delete: "Supprimer",
  addPlayer: "Ajouter un joueur",
  save: "Enregistrer",
  generalInfo: "Informations générales",
  surname: "Prénom",
  dob: "Date de naissance",
  pob: "Lieu de naissance",
  normalOrAux: "Joueur Normal ou Auxiliaire",
  normal: "Normal",
  auxiliary: "Auxiliaire",
  photo: "Photo",
  chooseAPicture: "Choisissez une photo",
  anotherPicture: "Choisissez une autre photo",
  region: "Région",
  east: "Est",
  department: "Département",
  district: "Arrondissement",
  choose_an_option: "Choisissez une option",
  maritalStatus: "Statut Matrimonial",
  profession: "Profession",
  educational_level: "Niveau d'étude",
  phone: "Téléphone",
  email: "Email",
  boardMember: "Membre du Conseil d'Administration",
  commissionMember: "Membre d'une Commission",

  refereeList: "Liste des Arbitres",
  filterTable: "Filtrer la table",
  profesionalInfo: "Information Professionelles",
  grade: "Rang",
  departmental_referee: "Arbitre Departemental",
  dateObtained: "Date d'obtention",
  location: "Lieu",
  instructor: "Instructeur",
  observation: "Observation",
  regional_referee: "Arbitre Regional",
  national_referee: "Arbitre National",
  international_referee: "Arbitre International",
  previewPrint: "Aperçu de l'impression",
  print: "Imprimer",
  edit_referee:"METTRE A JOUR UN ARBITRE",
  deleteRef: "Pour des motifs de sécurité veuillez saisir votre mot de passe pour confirmer la suppression de: ",
  confirmDel: "Confirmer la suppression",
  ref_details: "DETAILS DE L'ARBITRE",
  addReferee: "AJOUTER UN ARBITRE",

  addCoach: "Ajouter un Entraineur",
  coach_pas: "Entraineur PAS",
  volleyball_school: "École de Volley",
  coach_lvl_1: "Entraineur Niveau 1",
  coach_lvl_2: "Entraineur Niveau 2",
  coach_lvl_3: "Entraineur Niveau 3",

  clubList: "Liste des Équipes",
  filterByLeague: "Filtrer par ligue",
  logo: "Logo",
  type: "Type",
  staff: "Staff",
  
  listofEnrolled: "Liste des pré-enregistrés",

  listOfTournaments: "Liste des Tournois",
  hall: "Salle",
  startDate: "Date de début",
  endDate: "Date de fin",
  organizer: "Organisateur",
  normalOrAux_Tournament: "Tournoi Normal or Auxiliaire",

  fedMember_list: "Liste des membres de la Fédération",
  commission: "Commission",
  boardOf_Directors: "Conseil d'Administration",
  edit_federation_member: "Modifier un membre de la Fédération",

  listOfLeagueMembers: "Liste des membres de Ligue",
  
  edit_league_member: "Modifier un membre de Ligue",
  leagueType: "Type de Ligue",
  
  matchList: "Liste des Matches",
  match: "Match",
  filterByTournament: "Filtrer par Tournoi",
  team_A: "Équipe A",
  team_B: "Équipe B",
  game_status: "Statut",
  score: "Score",
  formation: "Formation",

  roster: "Composition d'équipe",
  tournament: "Tournoi",
  team1: "Équipe 1",
  team2: "Équipe 2",
  dayNum: "Jour N°",
  phase: "Phase",
  matchDate: "Date du Match",
  matchTime: "Heure du Match",
  Country: "Pays",
  town: "Ville",
  numSpectators: "Nombre de spectateurs",
  countryCode: "Code du Pays",
  division: "Division",
  category: "Categorie",
  first: "Premier",
  second: "Second",
  scorer: "Marqueur",
  assistant_scorer: "Marqueur Assistant",
  supervisor: "Superviseur",
  judge1: "Juge 1",
  judge2: "Juge 2",
  judge3: "Juge 3",
  judge4: "Juge 4",
  setCaptainLibero: "Définir Capitaine & Libero",
  officials: "Officiels",
  teamColors: "Couleur des équipes",
  kit: "Kit",
  captain: "Capitaine",
  therapist: "Kinésitherapeute",
  medicalDoctor: "Médecin",
  notPlayed: "En attente",
  played: "Joué",
  generateReport: "Générer Feuille de match",
  startMatch: "Lancer le match",

  substitution: "Remplacement",
  assignPoint: "Point",
  playerOnBench: "Joueurs sur le Banc",
  sanction: "Sanctions",
  timeout: "Temps-mort",
  cancel: "Annuler",
  undo: "Annuler",
  addLiberos: "Changement Liberos",
  exit: "Sortir",
  setTime: "Durée Set",
  onCourt: "Sur le terrain",
  injury: "Blessures",
  manualChanges: "Changements manuels",
  set: "Set",
  technicalTimeout: "Temps-mort Technique",
  time: "Heure",
  sanctionHistory: "Historique des sanctions",
  emptyList: "Celle liste est vide",
  delayWarning: "Avertissement de retard de jeu",
  delayPenalty: "Penalité de retard de jeu",
  warning: "Avertissement",
  penalty: "Penalité",
  expulsion: "Expulsion",
  disqualification: "Disqualification",
  subsRemaining: "Remplacements restants",
  substitute: "Remplacer",
  timeoutHistory: "Historique temps mort",
  startTimeout: "Demarrer temps mort",
  liberoHistory: "Historique Libero",
  changeLibero: "Changement Libero",
  exitMessage: "Êtes-vous sûr de vouloir quitter?",
  exitMessage2: "Toutes les données actuelles seront perdues",


  west: "Ouest",
  farNorth: "Extrême-Nord",
  north: "Nord",
  adamawa: "Adamaoua",
  centre: "Centre",
  south: "Sud",
  littoral: "Littoral",
  southWest: "Sud-Ouest",
  northWest: "Nord-Ouest",
  fianced: "Fiancé(e)",
  divorced: "Divorcé(e)",
  married: "Marié(e)",
  bachelor: "Célibataire",
  complex: "Compliqué",
  enrollDetails: "Details du pré-enregistré",

  auTotal: "au total"

};

export default fr;
