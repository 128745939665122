import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import { BackButton, DatatableCard, InputTextLarge, Loader } from '../../components';
import { getPermissionAsync, savePermissionAsync, showPasswordModal, updatePermissionAsync } from '../../store/ui';



const Permission = (props) => {
  const [loading , setLoading] = useState(false)
  const {permissions} = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!permissions){
      setLoading(true)
      dispatch(getPermissionAsync({onStop:()=>setLoading(false)}));
    }
  }, [dispatch, permissions]);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleInputChange = e => {
    switch (e.target.id) {
      case 'name':
        setName(e.target.value.toUpperCase())
        break;
      case 'description':
        setDescription(e.target.value)
        break;

      default:
        break;
    }

  }

  const resetFields = () => {
    setId('');
    setName('');
    setDescription('');
  }

  const handleSubmit = e => {

    e.preventDefault();

    let permission = { name, id, description }
  
    id === '' ? dispatch(savePermissionAsync({permission})) : dispatch(updatePermissionAsync({permission}))

    resetFields();
    setModalVisibility(false)

  }

  const addPermission = () => {
    resetFields()
    setModalVisibility(true)
  }

  const onEdit = (permission) => {

    setId(permission.id)
    setName(permission.name)
    setDescription(permission.description)
    setModalVisibility(true)
  }

  const onDelete = (permission) => {
    dispatch(showPasswordModal({deleteItemId:permission, deleteSource:'permission'}))
  }
  const getNum = (id)=>{
    return permissions?.findIndex((el)=>el.id === id)

  }

  const columns = [
    {
      name: 'Nº',
      cell: (row, index, column, id) => <p>{getNum(row?.id) + 1}</p>
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: "Date d'Ajout",
      selector: 'creationDate',
      sortable: true,
      cell: (row) => <Moment format='DD/MM/YYYY'>{row.creationDate}</Moment>
    },
    {
      cell: (row) => <button
        onClick={() => onEdit(row)}
        className="btn btn-sm btn-primary"><i className="fa fa-edit"></i></button>
    },
    {
      cell: (row) => <button
        onClick={() => onDelete(row)}
        className="btn btn-sm btn-danger"><i className="fa fa-trash"></i></button>
    },
  ];

  const tableData = {
    columns,
    data: permissions || [],
  };

  if(loading){
    return <Loader />;
  }


  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span className=""><i className="fa fa-list fa-lg mr-3"></i></span>LISTE DES PERMISSIONS</h6>
              <h6 style={{ float: 'right' }} className="h2 d-inline-block text-uppercase mb-0"><button onClick={() => addPermission()} style={{ backgroundColor: '#19a0c2', color: '#fff' }} className="btn btn-sm"><i className="fa fa-plus fa-lg"></i> </button></h6>
              <BackButton />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card card-stats" >

                {loading ?

                  <Loader /> : <DatatableCard tableData={tableData} />}

              </div>
            </div>
          </div>
        </div>
        <Modal show={modalVisibility} centered onHide={() => setModalVisibility(false)}>
        <Modal.Header tabIndex='-1' aria-hidden closeButton>{id === '' ? 'Nouvelle' : 'Edit'} permission</Modal.Header>
        <Modal.Body>

          <form onSubmit={handleSubmit}>

          <div className="row">
          <InputTextLarge size={12} title="Name" style={{ textTransform: 'uppercase' }} name="name" id="name" onChange={handleInputChange} value={name} required />
          </div>

          <div className="row">
          <InputTextLarge size={12} title="Description" name="description" id="description" onChange={handleInputChange} value={description} required />
          </div>

            <div class="modal-footer pb-0 pt-0">
              <button
                type="button"
                class="btn"
                onClick={() => setModalVisibility(false)}>
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save
              </button>
            </div>
          </form>

        </Modal.Body>

      </Modal>
      </div>
    </div>
  );
}

export default Permission