import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  DropdownLarge,
  InputPicture,
  InputTextLarge,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants, locals } from "../../../utilities";
import { getArrondissment, getDepartment } from "../../../store/ui";
import { saveClubAsync, updateClubAsync } from "../../../store/clubs/clubSlice";


const ClubFormAdmin = (props) => {
  const { loading } = useSelector((state) => state.club);
  const [sending , setSending] = useState(false)
  const regions = useSelector((state) => Object.values(state.ui.regions || {}));
  const tournois = useSelector((state) => Object.values(state.tournois.data));
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState({
    name: "",
    league: "",
    code: "",
    typeClub: "",
    _region: "",
    _departement: "",
    _arrondissement: "",
    auxiliaire: false,
    tournois: null,
  });
  const [picture, setPicture] = useState([]);

  const departmentList = useSelector((state) => state.ui.department?.[data._region] || []);
  const arrondissmentList = useSelector((state) => state.ui.district?.[data._departement] || []);

  useEffect(() => {
    if (location.state) {
      const ligue = localStorage.getItem("fvApp");
      setData((prev) => ({
        ...prev,
        ...location.state,
        league: user?.league,
        _region: location.state._region?.id || "",
        _departement: location.state._departement?.id || "",
        _arrondissement: location.state._arrondissement?.id || "",
      }));

      if (ligue !== locals.ADMIN_LOCALS) {
        setDisabled(true);
      }
    }
  }, [location.state, user.league]);

  useEffect(() => {
    if (data._region !== "") {
      dispatch(getDepartment({ regionId: data._region }));
    }
  }, [data._region, dispatch]);

  useEffect(() => {
    if (data._departement !== "") {
      console.log("departmentid",data._departement);
      dispatch(getArrondissment({ departmentId: data._departement }));
    }
  }, [data._departement, dispatch]);

  const handleInputChange = (e) => {
    setData((prev) => {
      if (["auxiliaire", "normal"].includes(e.target.id)) {
        if (e.target.id === "auxiliaire") {
          console.log("auxiliaire check");
          return { ...prev, auxiliaire: true };
        } else {
          console.log("normal check");
          return { ...prev, auxiliaire: false };
        }
      } else {
        return {
          ...prev,
          [e.target.id]: e.target.value,
        };
      }
    });
  };

  const callback = (saved) => {
    setSending(false)
    if(saved){
      navigate(-1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const club = location.state
    let club = data;

    if (picture?.base64 === undefined) {
      club = {
        ...club,
        picture: picture,
      };
    } else {
      club = {
        ...club,
        picture: picture?.base64,
      };
    }

    if (picture?.length === 0) {
      alert("Please add a picture");
      return;
    }

    if (data?.league === undefined) {
      alert("The field League is empty !");
    } else {
   
      setSending(true)
      data?.id
        ? dispatch(updateClubAsync({club, callback}))
        : dispatch(saveClubAsync({club, callback}))
    }
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!location.state ? "ADD" : "EDIT"} A CLUB
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={data.name}
                        required
                      />
                      {data.picture !== null ? (
                        <div className="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span className="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture.length !== 0 && (
                                <div className="col">
                                  {picture.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div
                                className="col-md-10"
                                style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <InputTextLarge
                        title="Code"
                        name="code"
                        id="code"
                        onChange={handleInputChange}
                        style={{ textTransform: "uppercase" }}
                        value={data.code}
                        required
                      />
                      <DropdownLarge
                        title="Type"
                        options={DropdownConstants.CLUB_TYPE}
                        name="typeClub"
                        id="typeClub"
                        onChange={handleInputChange}
                        value={data.typeClub}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="Region"
                        options={regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={data._region}
                        required
                      />
                      <DropdownLarge
                        title="Departement"
                        options={departmentList}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={data._departement}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="District"
                        options={arrondissmentList}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={data._arrondissement}
                        required
                      />
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="test">
                            {"Normal or Auxiliary Club ?"}
                          </label>{" "}
                          <span className="text-danger ml-1">*</span>
                          <br />
                          <Checkbox
                            title="Normal"
                            id="normal"
                            onChange={handleInputChange}
                            checked={!data.auxiliaire}
                          />
                          <Checkbox
                            title={"Auxiliary"}
                            id="auxiliaire"
                            onChange={handleInputChange}
                            checked={data.auxiliaire}
                          />
                        </div>
                      </div>

                      {data.auxiliaire ? (
                        <DropdownLarge
                          title="Auxiliary Tournament"
                          options={tournois}
                          dynamic={true}
                          name="tournois"
                          id="tournois"
                          onChange={handleInputChange}
                          value={data._tournois}
                        />
                      ) : null}

                      {!data.auxiliaire ? (
                        <DropdownLarge
                          title="League"
                          options={DropdownConstants.LEAGUE}
                          name="league"
                          id="league"
                          onChange={handleInputChange}
                          disabled={disabled}
                          value={data.league}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="float-right"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="1000">
              {!sending ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClubFormAdmin;
