import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTournois } from '../store/tournois/tournoisSlice';
import {
  getMatriculeCodeAsync,
  getPermissionAsync,
  getRegions,
  getRoles,
} from '../store/ui';
import { getClubs, getStaffClubAsync } from '../store/clubs/clubSlice';
import { getRefereeAsync } from '../store/referee/refereeSlice';
import {
  AdminHeader,
  AdminSidebar,
  PasswordModal,
  SessionExpiredModal,
  SignatureModal,
} from '../components';
import { Navigate, Outlet } from 'react-router-dom';

const MainLayout = (props) => {
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedInUser) {
      dispatch(getTournois());
      dispatch(getRoles());
      dispatch(getClubs());
      dispatch(getStaffClubAsync());
      dispatch(getRefereeAsync());
      dispatch(getRegions());
      dispatch(getMatriculeCodeAsync());
      dispatch(getPermissionAsync({ onStop: () => {} }));
    }
  }, [dispatch, loggedInUser]);

  if (!loggedInUser) {
    return <Navigate to="/signin" />;
  }

  return (
    <React.Fragment>
      {/* {adminState.loggedInUser.length !== 0 ? ( */}
      <AdminSidebar user={loggedInUser} />
      {/* // ) : null} */}
      <div className="main-content" id="panel" style={{ height: '100vh' }}>
        <AdminHeader />
        {/* <Notify /> */}

        <Outlet />
      </div>
      <PasswordModal />
      <SessionExpiredModal />
      <SignatureModal />
    </React.Fragment>
  );
};

export default MainLayout;
