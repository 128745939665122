import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {
  deleteMatch,
  deleteTournois,
  deleteUser,
} from '../screens/admin/actions';
import ModalFooter from './ModalFooter';
import {
  deleteMatriculeCodeAsync,
  deletePermissionAsync,
  deleteRoleAsync,
  hidePasswordModal,
} from '../store/ui';
import { deleteClubAsync } from '../store/clubs/clubSlice';
import { deleteUserAsync } from '../store/users/userSlice';
import { deleteReferee } from '../store/referee/refereeSlice';
import { deleteCoach } from '../store/coach/coachSlice';
import { deletePlayer } from '../store/players/playerSlice';
import Loader from './Loader';
import { deleteEnrolled } from '../store/enrolled/enrolledSlice';
import { deleteUserLeague } from '../store/league/leagueSlice';
import { deleteUserFederation } from '../store/federation/federationSlice';
import { deleteMatchAsync } from '../store/matchs/matchSlice';

const PasswordModal = (props) => {
  const { deleteItemId, deleteSource, passwordModal } = useSelector(
    (state) => state.ui
  );
  console.log({ deleteItemId, deleteSource, passwordModal });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pwd, setPwd] = useState('');

  const close = () => {
    dispatch(hidePasswordModal());
    setPwd('');
  };
  const onStop = () => setLoading(false);

  const deleteSelected = () => {
    switch (deleteSource) {
      case 'club':
        dispatch(deleteClubAsync({ id: deleteItemId.id, pwd }));
        break;
      case 'referee':
        setLoading(true);
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deleteReferee(id));
              close();
            },
            onStop,
          })
        );
        break;
      case 'coach':
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deleteCoach(id));
              close();
            },
            onStop,
          })
        );
        break;
      case 'player':
        setLoading(true);
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deletePlayer(id));
              close();
            },
            onStop,
          })
        );
        break;
      case 'inscrits':
        setLoading(true);
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deleteEnrolled(id));
              close();
            },
            onStop,
          })
        );
        break;
      case 'user-league':
        setLoading(true);
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deleteUserLeague({ id, type: deleteItemId?.type }));
              close();
            },
            onStop,
          })
        );
        break;
      case 'federation':
        setLoading(true);
        dispatch(
          deleteUserAsync({
            id: deleteItemId.id,
            pwd,
            onSuccess: (id) => {
              dispatch(deleteUserFederation(id));
              close();
            },
            onStop,
          })
        );
        break;
      case 'tournois':
        dispatch(deleteTournois(deleteItemId.id, pwd));
        break;
      case 'match':
        console.log('delete of match', deleteItemId.id, pwd);
        dispatch(deleteMatchAsync({ id: deleteItemId.id, pwd }));
        break;
      case 'role':
        dispatch(deleteRoleAsync({ id: deleteItemId.id, pwd }));
        break;
      case 'permission':
        dispatch(deletePermissionAsync({ id: deleteItemId.id, pwd }));
        break;
      case 'matriculeCode':
        dispatch(deleteMatriculeCodeAsync({ id: deleteItemId.id, pwd }));
        break;
      default:
        break;
    }

    setPwd('');
    // dispatch(hidePasswordModal())
  };

  const handleInputChange = (e) => {
    switch (e.target.id) {
      case 'p_text':
        setPwd(e.target.value);
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      centered
      show={passwordModal}
      onHide={() => dispatch(hidePasswordModal())}
    >
      <Modal.Body style={{ textAlign: 'center' }}>
        <p>
          Pour des raisons de sécurité veuillez saisir votre mot de passe pour
          confirmer la suppression de:{' '}
          <b style={{ color: 'firebrick', fontWeight: 'bold' }}>
            {deleteItemId?.name}
          </b>
        </p>

        <input
          placeholder="Mot de passe"
          type="password"
          id="p_text"
          onChange={handleInputChange}
          className="form-control"
          value={pwd}
        />

        <div style={{ marginTop: 10 }}>
          {loading ? (
            <Loader />
          ) : (
            <button
              style={{ backgroundColor: 'firebrick', color: '#fff' }}
              disabled={pwd === '' ? true : false}
              onClick={deleteSelected}
              class="btn"
            >
              Confirmer la suppression
            </button>
          )}
          <button class="btn" onClick={() => close()}>
            Close
          </button>
        </div>
      </Modal.Body>

      <ModalFooter />
    </Modal>
  );
};

export default PasswordModal;
