import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardCard } from '../../components';
import useLang from '../../hook/useLang';
import { hasPermission } from '../../utilities/auth';

import useGetDashboardStat from '../../hook/useGetDasboardStat';

const Dashboard = (props) => {
  const { user: loggedInUser } = useSelector((state) => state.auth);

  const {
    refereeCount,
    coachCount,
    playerCount,
    federationCount,
    leagueCount,
    clubCount,
    enrolledCount,
  } = useGetDashboardStat();
  const lang = useLang();

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-desktop fa-lg mr-3"></i>
                </span>
                {lang?.dashboard}
              </h6> */}
            </div>
          </div>

          <div className="row">
            {hasPermission(loggedInUser, ['VIEW_REFEREE']) && (
              <DashboardCard
                title={lang?.referees?.toUpperCase()}
                data={refereeCount || 0}
                icon="user"
                color="purple"
                route="referee"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_COACH']) && (
              <DashboardCard
                title={lang?.coaches?.toUpperCase()}
                data={coachCount || 0}
                icon="user"
                color="info"
                route="coach"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_PLAYER']) && (
              <DashboardCard
                title={lang?.players?.toUpperCase()}
                data={playerCount || 0}
                icon="user"
                color="orange"
                route="player"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_FEDERATION_MEMBER']) && (
              <DashboardCard
                title="Federation"
                data={federationCount || 0}
                icon="user"
                color="blue"
                route="federation"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_LEAGUE_MEMBER']) && (
              <DashboardCard
                title="Leagues"
                data={leagueCount || 0}
                icon="user"
                color="blue"
                route="ligue"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_TEAM']) && (
              <DashboardCard
                title="Clubs"
                data={clubCount || 0}
                color="green"
                route="club"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_REGISTRATION']) && (
              <DashboardCard
                title={lang?.enrolled}
                data={enrolledCount || 0}
                color="green"
                route="inscrit"
                icon="user-plus"
              />
            )}

            {hasPermission(loggedInUser, ['VIEW_NOTIFICATIONS']) && (
              <DashboardCard
                title="Notifications"
                data={enrolledCount || 0}
                color="green"
                icon="bell"
                route="notification"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
