import * as constants from '../constants';

export function signin(user, callback, source) {
    return {
        type: constants.SIGNIN,
        user, 
        callback, 
        source
    }
}

export function signup(user, callback) {
    return {
        type: constants.SIGNUP,
        user, callback
    }
}

export function authResult() {
    return {
        type: constants.AUTH_RESULT
    }
}
