import React, {useMemo, useState } from "react";

const ESubstitutionTableRow = ({
  onPlayerSelect,
  clubID,
  players,
  item,
  playerSelection,
  onDeselect,
  onBenchPlayers,
  captainSelection,
  index
}) => {

  const [selectedPlayer, setSelectedPlayer] = useState("");
  // console.log(players)

  const onValueChange = (value) => {
    // const player = players.filter(player=>player.id===value).shift()
    onPlayerSelect({playerIn: value,playerOut:item.player.id, position: item.position,playerInPlaceId:selectedPlayer?.length !== 0 ? selectedPlayer :null})
    // onPlayerSelect({
    //   player: { id: player.id, name: `${player.name} ${player.surname}` },
    //   position,
    //   clubID
    // });

    setSelectedPlayer(value)
  };

  const onDeselectPlayer=()=>{
    onDeselect({playerIn:selectedPlayer});
    setSelectedPlayer("");
  }
  const bench = useMemo(()=>{
      return onBenchPlayers?.filter((el)=>!captainSelection[el.player.id]?.role?.toLowerCase().includes('libero'))
  },[captainSelection, onBenchPlayers])

  const disabled = (player)=>{
   
    const cond1 = Object.keys(playerSelection || "{}").includes(player.id)
    const cond2 = captainSelection[player.id]?.role?.toLowerCase()?.includes("libero") && ![1,5,6].includes(item?.position)
    const cond3 = Object.values(playerSelection ?? {}).find((el)=>captainSelection[el.playerIn]?.role?.toLowerCase()?.includes("libero")) && captainSelection[player.id]?.role?.toLowerCase()?.includes("libero")
    return cond1 || cond2 || cond3
  }

  return (
    <tr>
      <td>
        {item.position}
        {captainSelection[item.player.id]?.role}
      </td>
      <td>
        {`(${item.kit}) ${item.player.name}`}
      </td>
      <td>
        <select
          onChange={(event) => onValueChange(event.target.value)}
          className="form-control form-control-sm"
          value={selectedPlayer}
        >
          <option></option>
          {bench?.map(({player}) => (
            <option key={player.id} value={player.id} disabled={disabled(player)}>
              {`${captainSelection[player.id]?.role ? `(${captainSelection[player.id]?.role.charAt(0)})` : ''} ${player.name}`}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button className="btn btn-primary btn-sm"  disabled={!selectedPlayer} onClick={onDeselectPlayer}><i className="fa fa-redo"></i></button>
      </td>
    </tr>
  );
};

export default ESubstitutionTableRow;
