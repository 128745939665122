import { createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/api";
import { ADMIN_LOCALS } from "../../utilities/locals";
import { notifyError } from "../ui";

const coachSlice = createSlice({
  name: "coach",
  initialState: {
    data:null,
    
    loading:false,
    sending:false,
    statLoaded:false,
  },
  reducers: {
    initCoach: (state, { payload }) => {
        state.data = {}
        // eslint-disable-next-line no-unused-expressions
        payload?.forEach((coach)=>{
            state.data[coach?.id] = coach
        })
        
    },
    initCaochInfo:(state, {payload})=>{
      // console.log("init of coach info", payload)
      if(!state.data){
        state.data = {}
      }
      state.data[payload?.id] = {...state.data[payload?.id],...payload}
    },
    initLoading:(state)=>{
        state.loading = true
    },
    initCount:(state,{payload})=>{
      state.count = payload
    },
    changeStatLoaded:(state)=>{
      state.statLoaded = true
    },
    deleteCoach:(state,{payload})=>{
      delete state.data[payload]
      return state
    }
  },
  extraReducers: (builder) => {},
});

const coachReducer = coachSlice.reducer;

export const { initCoach , initLoading , initCount , changeStatLoaded , initCaochInfo, deleteCoach} = coachSlice.actions;
export default coachReducer;

export const initCoachCount = ()=>async(dispatch , getState)=>{
  if(!getState().coach.statLoaded){
    const ligue = localStorage.getItem("fvApp");
    const coachRoleResponse = await API.getRole("COACH");
  
    let dataResponse
    if (ligue !== ADMIN_LOCALS) {
      dataResponse = await API.getUsersCount(coachRoleResponse.data?.id)
      
      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.data))
        : dispatch(notifyError(""))
    }else{
  
      dataResponse = await API.getUsersByLigue(coachRoleResponse.data?.id,getState().auth?.user?.league)
      
      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.length))
        : dispatch(notifyError(""))
    }
    dispatch(changeStatLoaded())
  }
}