import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DatatableCard, Dropdown, Loader } from '../../components';

import { DropdownConstants } from '../../utilities';
import { hasPermission } from '../../utilities/auth';
import useLang from '../../hook/useLang';
import { useReactToPrint } from 'react-to-print';
import Options from './components/Options';

const ClubList = (props) => {
  const userRole = localStorage.getItem('fvApp');
  // const dispatch = useDispatch();
  const lang = useLang();
  const { data: clubs, loading, staff } = useSelector((state) => state.club);
  const { user: loggedInUser } = useSelector((state) => state.auth);

  const [filter, setFilter] = useState({ first: 'Afficher Tout', second: '' });
  const [applyFilter, setApplyFilter] = useState(false);

  const clubList = useMemo(() => {
    // console.log("filter", filter)
    if (applyFilter) {
      let data = [];
      if (filter.first === 'Afficher Normal') {
        data = Object.values(clubs).filter((club) => !club?.auxiliaire);
      }
      if (filter.first === 'Afficher Auxiliaire') {
        data = Object.values(clubs).filter((club) => club?.auxiliaire);
      }
      if (filter.first === 'Afficher Tout') {
        data = Object.values(clubs);
      }
      if (filter.second !== '') {
        return data.filter((club) => club?.league === filter.second);
      }
      console.log(data.length);
      return data;
    } else {
      return Object.values(clubs);
    }
  }, [applyFilter, clubs, filter.first, filter.second]);

  const getStaffNumber = useCallback(
    (clubId) => {
      const data = Object.values(staff || {});
      const findData = data.filter(
        (member) => member?.clubs?.[0]?.id === clubId
      );
      if (findData.length > 0) {
        return findData?.length;
      }
      return 'no staff';
    },
    [staff]
  );

  const columnsPrint = [
    {
      name: 'Nº',
      width: '50px',
      sortable: false,
      cell: (row) => <span> {row?.number}</span>,
    },
    {
      name: lang?.name,
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Logo',
      cell: (row) => (
        <img
          src={row.picture || '/assets/images/no-image.png'}
          alt="logo"
          className="rounded"
          width="50"
          height="50"
        />
      ),
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Type',
      selector: 'typeClub',
      sortable: false,
    },
    {
      name: 'Region',
      selector: '_region.name',
      sortable: false,
    },
  ];

  const columns = [
    ...columnsPrint,
    {
      name: 'Staff',
      cell: (row) => <span>{getStaffNumber(row?.id)}</span>,
    },
    {
      cell: (row) => <Options row={row} />,
    },
  ];

  const handleFilterChange = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const tableData = useMemo(
    () => ({
      columns,
      data: clubList
        ?.sort((a, b) => {
          const l = [a?.name, b?.name].sort();
          return l.indexOf(a?.name) - l.indexOf(b?.name);
        })
        .map((el, i) => ({ ...el, number: i + 1 })),
    }),
    [clubList, columns]
  );

  const refToPrint = useRef();
  const r = useReactToPrint({
    content: () => refToPrint.current,
  });

  const [preview, setPreview] = useState(false);

  const handlePrint = async () => {
    setPreview(true);
    r();
    setPreview(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.club_list}
              </h6> */}

              {loggedInUser
                ? hasPermission(loggedInUser, ['ADD_TEAM']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/club-form"
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser
                ? hasPermission(loggedInUser, ['PRINT_TEAM']) && (
                    <div class="dropdown float-right">
                      <button
                        class="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-print"></i>
                      </button>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => setPreview((prev) => !prev)}
                        >
                          <span>
                            <i className="fa fa-eye mr-1"></i>{' '}
                            {preview
                              ? lang?.hide_preview ?? 'Hide PReview'
                              : lang?.preview_print ?? 'Preview print'}
                          </span>
                        </button>
                        <div class="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={handlePrint}>
                          <span>
                            <i className="fa fa-print mr-1"></i> {lang?.print}
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Dropdown
              title={lang?.filter}
              options={DropdownConstants.AUXILIAIRE_FILTER}
              id="first"
              onChange={handleFilterChange}
            />
            {userRole !== '15sdf#4%$#sd#@_)54' ? (
              <Dropdown
                title={lang?.filter_by_league}
                options={DropdownConstants.LEAGUE}
                id="second"
                onChange={handleFilterChange}
              />
            ) : null}
            <div>
              <button
                className="btn btn-sm btn-info"
                type="button"
                style={{ height: 42, marginTop: '1.8rem' }}
                onClick={() => setApplyFilter((prev) => !prev)}
              >
                {applyFilter ? 'Annuler' : 'Filter'}
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                <div ref={refToPrint}>
                  <DatatableCard
                    tableData={{
                      ...tableData,
                      columns: preview ? columnsPrint : columns,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubList;
