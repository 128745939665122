import React from "react";
import { useLocation } from "react-router-dom";
// import $ from 'jquery';
import { BackButton, EmptyDetailCard } from "../../components";

const MatchDetail = (props) => {
  const location = useLocation();
  // useEffect(() => {

  //   $(window).bind('beforeunload', function (e) {
  //     e.preventDefault();
  //     return "Le rechargement de cette page peut perdre des données. Qu'est-ce que tu veux faire..."
  //   });

  // }, []);

  const match = location.state;

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span>
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                MATCH DETAILS
              </h6>
              <BackButton />
            </div>
          </div>

          {match !== undefined ? (
            <div className="row">
              <div className="col-md-12">
                <div className="card card-stats">
                  <div className="card-body" style={{ textAlign: "center" }}>
                    <div className="row" style={{ margin: 20 }}>
                      {match._participantOne !== null ? (
                        <div className="col-lg-4">
                          <h6>Team A: </h6>
                          <b>{match._participantOne.name}</b>
                        </div>
                      ) : null}
                      {match._participantTwo !== null ? (
                        <div className="col-lg-4">
                          <h6>Team B: </h6>
                          <b>{match._participantTwo.name}</b>
                        </div>
                      ) : null}
                      <div className="col-lg-4">
                        <h6>Heure de début: </h6>
                        <b>{match.start}</b>
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Heure de fin: </h6>
                        <b>{match.end}</b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Salle:</h6>
                        <b>{match._tournois.salle}</b>
                      </div>
                      {match._refereePremier !== null ? (
                        <div className="col-lg-4">
                          <h6>Referee (Premier): </h6>
                          <b>
                            {match._refereePremier.name}{" "}
                            {match._refereePremier.surname}
                          </b>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      {match._refereeSecond !== null ? (
                        <div className="col-lg-4">
                          <h6>Referee (Second): </h6>
                          <b>
                            {match._refereeSecond.name}{" "}
                            {match._refereeSecond.surname}
                          </b>
                        </div>
                      ) : null}
                      {match._refereeScorer !== null ? (
                        <div className="col-lg-4">
                          <h6>Referee (Scorer): </h6>
                          <b>
                            {match._refereeScorer.name}{" "}
                            {match._refereeScorer.surname}
                          </b>
                        </div>
                      ) : null}
                      {match._refereeScorerAssistant !== null ? (
                        <div className="col-lg-4">
                          <h6>Referee (Assistant Scorer): </h6>
                          <b>
                            {match._refereeScorerAssistant.name}{" "}
                            {match._refereeScorerAssistant.surname}
                          </b>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      {match._jugeOne !== null ? (
                        <div className="col-lg-4">
                          <h6>Judge 1: </h6>
                          <b>
                            {match._jugeOne.name} {match._jugeOne.surname}
                          </b>
                        </div>
                      ) : null}
                      {match._jugeTwo !== null ? (
                        <div className="col-lg-4">
                          <h6>Judge 2: </h6>
                          <b>
                            {match._jugeTwo.name} {match._jugeTwo.surname}
                          </b>
                        </div>
                      ) : null}
                      {match._jugeThree !== null ? (
                        <div className="col-lg-4">
                          <h6>Judge 3: </h6>
                          <b>
                            {match._jugeThree.name} {match._jugeThree.surname}
                          </b>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      {match._jugeFour !== null ? (
                        <div className="col-lg-4">
                          <h6>Judge 4: </h6>
                          <b>
                            {match._jugeFour.name} {match._jugeFour.surname}
                          </b>
                        </div>
                      ) : null}
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Vainqueur: </h6>
                        <b>
                          {match._winner !== null ? match._winner.name : ""}{" "}
                        </b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Score: </h6>
                        <b>{match.loserScore} </b>
                      </div>
                    </div>
                    <hr />
                    <div className="row" style={{ margin: 20 }}>
                      <div className="col-lg-4">
                        <h6>Perdant: </h6>
                        <b>{match._loser !== null ? match._loser.name : ""} </b>
                      </div>
                      <div className="col-lg-4">
                        <h6>Score: </h6>
                        <b>{match.winnerScore} </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EmptyDetailCard />
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchDetail;
