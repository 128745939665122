import React, { useEffect, useState } from "react";
import { getUser } from "../../../screens/admin/apis";

const EFieldCard = ({ captain, position, playerID, teamColors, clubID }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser(playerID).then((response) => {
      setUser(response.data);
    });
  }, [playerID]);

  return (
    <div className="player">
      <div
        className="player-avatar"
        style={{ border: `3px solid ${teamColors[clubID]}` }}
      >
        <img
          src={user?.picture || "/assets/images/profile.png"}
          style={{ backgroundColor: "white" }}
          alt="avatar"
        />
      </div>
      <p>
        <b style={{ fontSize: 13 }}>{position}</b>
      </p>
      {captain ? (
        <span>
          <b
            style={{
              backgroundColor:
                String(captain).charAt(0) === "C" ? "#25634c" : "#fa9931",
              borderRadius: 100,
              fontSize: 8,
              padding: 3,
            }}
          >
            {String(captain).charAt(0)}
          </b>
        </span>
      ) : null}
    </div>
  );
};

export default EFieldCard;
