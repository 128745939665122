import { createSlice } from "@reduxjs/toolkit";

import { notifyError, openSessionExpiredModal } from "../ui";
import { ADMIN_LOCALS } from "../../utilities/locals";
import * as API from "../../api/api";

const refereeSlice = createSlice({
  name: "referee",
  initialState: {
    data: {},
    loaded: false,
    loading: false,
    sending: false,
    count: 0,
  },
  reducers: {
    initReferee: (state, { payload }) => {
      // eslint-disable-next-line no-unused-expressions
      payload?.forEach((match) => {state.data[match?.id] = match});
      state.count = payload.length;
      state.loaded = true;
    },
    initRefereeInfo:(state, {payload})=>{
      state.data[payload?.id] = {...state.data[payload?.id],...payload}
    },
    initStopLoading: (state) => {
      state.loading = !state.loading;
    },
    initCount: (state, { payload }) => {
      state.count = payload;
    },
    changeStatLoaded: (state) => {
      state.statLoaded = true;
    },
    deleteReferee:(state,{payload})=>{
      const obj = {...state.data}
      delete obj[payload]
      state.data = obj
    }
  },
  extraReducers: (builder) => {},
});

const refereeReducer = refereeSlice.reducer;

export const { initReferee, initStopLoading, initCount, changeStatLoaded , initRefereeInfo, deleteReferee} =
  refereeSlice.actions;
export default refereeReducer;

export const initRefereeCount =
  (code = "ARBIT") =>
  async (dispatch, getState) => {
    if (!getState().referee.statLoaded) {
      const ligue = localStorage.getItem("fvApp");
      const refereeRoleResponse = await API.getRole("ARBIT");

      let refereeResponse;
      if (ligue !== ADMIN_LOCALS) {
        refereeResponse = await API.getUsersCount(refereeRoleResponse.data.id);
        refereeResponse?.status === 200
          ? dispatch(initCount(refereeResponse?.data))
          : dispatch(notifyError(""));
      } else {
        refereeResponse = await API.getUsersByLigue(
          refereeRoleResponse.data.id,
          getState().auth?.user?.league
        );
        refereeResponse?.status === 200
          ? dispatch(initCount(refereeResponse?.length))
          : dispatch(notifyError(""));
      }
      dispatch(changeStatLoaded());
    }
  };

export const getRefereeAsync = () => async (dispatch, getState) => {
  if (!getState().referee.loaded) {
    try {
      dispatch(initStopLoading());
      const refereeRoleResponse = await API.getRole("ARBIT");
      const userGroupId = refereeRoleResponse?.data?.id;
      const response = await API.getUsers(userGroupId);
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          // yield put(actions.getClubsSuccess(response.data))
          dispatch(initReferee(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get arbit", err);
      dispatch(notifyError());
    } finally {
      dispatch(initStopLoading());
    }
  }
};

