import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { EmptyTable } from "../../../components";

const ESanctionModal = ({
  handleClose,
  show,
  team,
  players,
  time,
  onSanction,
  data,
  staff,
  onBench,
  score
}) => {
  const [playerID, setPlayerID] = useState("");
  const [type, setType] = useState("onCourt");
  const [sanction, setSanction] = useState("Delay warning");

  const sanctions = [
    { id: 1, title: "Delay warning" },
    { id: 2, title: "Delay penalty" },
    { id: 3, title: "Warning" },
    { id: 4, title: "Penalty" },
    { id: 5, title: "Expulsion" },
    { id: 6, title: "Disqualification" },
  ];

  const onSave = () => {
    const toSave = {
      time,
      player: playerID,
      playerType: type,
      sanction,
      score,
    };
    onSanction(toSave);
  };

  return (
    <Modal show={show} centered size={"lg"} onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>SANCTION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div
              className="btn-group d-flex mb-4 justify-content-center align-item-center"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className="btn btn-default-outlined btn-sm"
                style={{
                  backgroundColor: type === "onCourt" ? "#17bab8" : "white",
                  color: type === "onCourt" ? "white" : "black",
                }}
                onClick={() => setType("onCourt")}
              >
                On Court
              </button>
              <button
                type="button"
                className="btn btn-default-outlined btn-sm"
                style={{
                  backgroundColor: type === "onBench" ? "#17bab8" : "white",
                  color: type === "onBench" ? "white" : "black",
                }}
                onClick={() => setType("onBench")}
              >
                On Bench
              </button>
              <button
                type="button"
                className="btn btn-default-outlined btn-sm"
                style={{
                  backgroundColor: type === "staff" ? "#17bab8" : "white",
                  color: type === "staff" ? "white" : "black",
                }}
                onClick={() => setType("staff")}
              >
                Staff
              </button>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Team:</p>
                <h1>{team.name}</h1>
              </div>
              <div>
                <p className="mb-0">Time:</p>
                <h1>{time}</h1>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">Score:</p>
                <h1>{team.score}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0">Sanction:</p>
              <select
                value={sanction}
                onChange={({ target }) => setSanction(target.value)}
                className="form-control form-control-sm"
              >
                {sanctions.map((sanction) => (
                  <option key={sanction.id} value={sanction.title}>
                    {sanction.title}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">
                {type === "staff" ? "Staff" : "Player"}:
              </p>
              {type !== "staff" ? (
                <select
                  disabled={
                    sanction === "Delay warning" || sanction === "Delay penalty"
                  }
                  onChange={({ target }) => setPlayerID(target.value)}
                  className="form-control form-control-sm"
                  value={playerID}
                >
                  <option></option>
                  {(type === "onBench" ? onBench : players)?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {val.player.name}
                    </option>
                  ))}
                </select>
              ) : staff?.length ? null : <small>No staff member</small>}
            </div>
          </div>
          <div className="col-md-6 border-left">
            <h4 className="text-center">
              <b>Sanction history</b>
            </h4>
            {data.length ? (
              data
                .filter((dat) => dat.team === team.id)
                .map((history) => (
                  <div
                    className="border px-2"
                    style={{ backgroundColor: "#c2c2c220" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div style={{width: '40%'}}>
                        <small className="mb-0">Player:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {(history.playerType === "onBench" ? onBench : players).filter(
                            ({ player }) => player.id === history.player
                          )[0]?.player?.name || "Team Sanction"}
                        </h6>
                      </div>
                      <div style={{width: '30%'}}>
                        <small className="mb-0">Time:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {history.time}
                        </h6>
                      </div>
                      <div style={{width: '30%'}}>
                        <small className="mb-0">Sanction:</small>
                        <h6 style={{ fontWeight: 600, fontSize: 13 }}>
                          {history.sanction}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <EmptyTable
                titleStyle={{ fontSize: 14 }}
                imageStyle={{ height: 70, width: 70 }}
                rootStyle={{ marginTop: 60 }}
              />
            )}
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#E56969", color: "white" }}
          >
            Sanction
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ESanctionModal;
