import React from 'react';
import {  useSelector } from 'react-redux';
import Moment from 'react-moment';
import { CardRow, EmptyDetailCard } from '../../components';

import { stringReduceAddDots } from '../../utilities';


const Profile = () => {
  const loggedInUser = useSelector((state) => state.auth.user);
  if(!loggedInUser) return <></>
  
  return (

    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0"><span><i className="fa fa-user fa-lg mr-3"></i></span>MY PROFILE</h6>

            </div>
          </div>

          {loggedInUser !== 0 ?
            <div class="row gutters-sm">
              <div class="col-md-4 mb-3">
                <div class="card shadow mb-25 bg-white rounded my-3" >
                  <div class="card-body">
                    <div class="d-flex flex-column align-items-center text-center">
                      <img src={loggedInUser.picture || '/assets/images/profile.png'} alt="Admin" class="rounded-circle" width="150" height="150" />
                      <div class="mt-3">
                        <h6>{`${loggedInUser.name} ${loggedInUser.surname}`}</h6>

                        <p class="font-size-sm mb-0" style={{fontSize: 12}}>{loggedInUser.matricule === null ? '' : loggedInUser.matricule}</p>
                        <p class="font-size-sm" style={{fontSize: 12}}>{loggedInUser._role === null ? '' : loggedInUser._role.name}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card shadow mb-25 bg-white rounded my-3" >
                  <div class="card-body">
                    <CardRow title='League' value={loggedInUser.league} title2='Region' value2={loggedInUser._region === null ? '' : loggedInUser._region.name} />
                    <CardRow title="Departement" value={loggedInUser._departement === null ? '' : loggedInUser._departement.name} title2={stringReduceAddDots('District',10)} value2={loggedInUser._arrondissement === null ? '' : loggedInUser._arrondissement.name} showHR={false} />
                  </div>
                </div>

              </div>
              <div class="col-md-8">
                <div class="card mb-3 shadow bg-white rounded my-3" >
                  <div class="card-body">

                    <CardRow title='Name' value={loggedInUser.name} title2='Surname' value2={loggedInUser.surname} />
                    <CardRow title='Date of birth' value={loggedInUser.dob} title2='Place of birth' value2={loggedInUser.pob} />
                    <CardRow title='Marital status' value={loggedInUser.familyStatus} title2='Gender' value2={loggedInUser.gender} />
                    <CardRow title='Email' value={loggedInUser.email} showHR={false} title2="Phone number" value2={loggedInUser.phone} />

                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="card mb-3 shadow bg-white rounded my-3" >
                      <div class="card-body">
                        <CardRow title="Profession" value={loggedInUser.profession} title2='Role' value2={loggedInUser._role.name} />
                        <CardRow title="Matricule" value={loggedInUser.matricule} title2="Education level" value2={loggedInUser.schoolLevel} />
                        <CardRow title="Creation date" value={<Moment format='DD/MM/YYYY'>{loggedInUser.creationDate}</Moment>} showHR={false} />
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

            : <EmptyDetailCard />}

        </div>
      </div>
    </div>
  );
}

export default Profile