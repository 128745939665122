import React, { useEffect, useState } from "react";

const MatchPlayerRow = ({
  position,
  onPlayerSelect,
  players,
  playerSelection,
  onDeselect,
  type,
  playerID = "",
  captainSelection,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const onValueChange = (value) => {
    const item = JSON.parse(value);
    onPlayerSelect({ ...item, type, position },selectedPlayer);
    setSelectedPlayer(value,);
  };

  const onDeselectPlayer = () => {
    onDeselect({
      player: { id: JSON.parse(selectedPlayer || "{}").player.id },
    });
    setSelectedPlayer("");
  };

  useEffect(() => {
    setSelectedPlayer(playerID);
  }, [playerID]);

  return (
    <tr
      style={{
        backgroundColor: position > 8 ? "#dd3d4550" : "white",
      }}
    >
      <td>
        {position}{" "}
        {Object.keys(captainSelection).includes(
          JSON.parse(selectedPlayer || "{}")?.player?.id
        ) ? (
          <b className="font-weight-700">
            {
              captainSelection[JSON.parse(selectedPlayer || "{}").player?.id]
                ?.role
            }
          </b>
        ) : null}
      </td>
      <td>
        {playerID !== "" ? (
          <input
            value={JSON.parse(playerID)?.player?.name}
            className="form-control form-control-sm"
          />
        ) : (
          <div className="dropdown">
            <button
              className="btn btn-sm"
              style={{
                backgroundColor: JSON.parse(selectedPlayer || "{}")?.player
                  ?.name
                  ? "#c2c2c270"
                  : "transparent",
                boxShadow: "none",
                margin: 0,
              }}
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {JSON.parse(selectedPlayer || "{}")?.player?.name ||
                "Select a player"}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <ul style={{ paddingLeft: 0 }}>
                {players
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) !== "L"
                  )
                  .map((value, index) => (
                    <li
                      key={index}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        display:
                          Object.keys(playerSelection || {}).includes(
                            value.player.id
                          ) ||
                          captainSelection[value.player.id]?.role?.charAt(0) ===
                            "L"
                            ? "none"
                            : "block",
                      }}
                      onClick={() => onValueChange(JSON.stringify(value))}
                    >
                      {`(${value.kit}) ${value.player.name} ${
                        captainSelection[value.player.id]?.role
                          ? `(${captainSelection[value.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </li>
                  ))}
              </ul>
              {/* <div className="dropdown-divider"></div>
              <button className="dropdown-item">
                <span>
                  <i className="fa fa-file mr-1"></i> Generate report
                </span>
              </button>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item">
                <span className="text-danger">
                  <i className="fa fa-trash mr-1"></i> Delete
                </span>
              </button> */}
            </div>
          </div>
        )}
      </td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          style={{ padding: 5, margin: 0, border: "none" }}
          disabled={!selectedPlayer}
          onClick={onDeselectPlayer}
        >
          <i className="fa fa-redo"></i>
        </button>
      </td>
    </tr>
  );
};

export default MatchPlayerRow;
