import React from 'react';
export default function FooterSection({ matchDetails, teamA, teamB }) {
  return (
    <div style={{ width: '100%' }}>
      <div className="row mb-3 align-items-center justify-content-around py-4">
        <p className="text-uppercase font-weight-bold align-items-center justify-content-center py-4">
          Referee 1: {matchDetails._refereePremier.name}
        </p>
        <p className="text-uppercase font-weight-bold align-items-center justify-content-center py-4">
          Referee 2: {matchDetails._refereeSecond.name}
        </p>
      </div>

      <div className="row">
        <div className="col-6">
          <h4 style={{ width: '100%' }}>
            {matchDetails._participantOne.name}
          </h4>
          <table class="table">
            <thead></thead>
            <tbody>
              <tr>
                <th scope="row">No</th>
                <td>Name</td>
                <td>Pos</td>
              </tr>
              {teamA
                ? teamA.map((team, index) => (
                    <tr>
                      <th scope="row">{team.kit}</th>
                      <td>{team.name}</td>
                      <td>{team.kit}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>

        <div className="col-6">
          <h4 className="h4 d-inline-block text-uppercase mb-0">
            {matchDetails._participantTwo.name}
          </h4>

          <table class="table">
            <thead></thead>
            <tbody>
              <tr>
                <th scope="row">No</th>
                <td>Name</td>
                <td>Pos</td>
              </tr>
              {teamB
                ? teamB.map((team, index) => (
                    <tr>
                      <th scope="row">{team.kit}</th>
                      <td>{team.name}</td>
                      <td>{team.kit}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
