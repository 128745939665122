import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  HomeHeader,
  InputTextLarge,
  Loader,
  Notify,
} from "../../components";

import { yupValidation } from "../../utilities";
import useLang from "../../hook/useLang";
import { getLoggedInUser, signin } from "../../store/auth/authSlice";

const Signin = () => {
  const navigate = useNavigate();
  const lang = useLang();
  const callback = () => {
    dispatch(getLoggedInUser({
      callback:()=> {
        navigate("/admin/dashboard")
        console.log("callback")
      }
    }));
   
  };

  const {loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      email: "",
      pwd: "",
    },
    validationSchema: Yup.object().shape(yupValidation.signinYup),
    onSubmit(values) {
      //console.log(values);
      //rak1hwBn
      // google-site-verification=TYJiyG35v7FyPHaudz14oSYfTboKb2NHmsBe-lWNUjI
      
      dispatch(signin({user:values, callback}));
    },
  });

  return (
    <div
      style={{
        backgroundImage: `url(./assets/images/bg.jpg)`,
        height: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflow: "auto",
      }}
    >
      <HomeHeader />
      <div style={{ backgroundColor: "rgba( 0, 0, 0, 0.5 )" }}>
        <section class="pt-5">
          <div
            class="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div class="card">
              <div
                class="card-header"
                style={{ padding: 10, backgroundColor: "#212629" }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    letterSpacing: 2,
                  }}
                >
                  {lang?.connect}
                </h1>
              </div>
              <div class="card-body" style={{ padding: 10 }}>
                <form onSubmit={handleSubmit}>
                  <InputTextLarge
                    size="12"
                    title={lang?.email}
                    id="email"
                    error={touched.email && errors.email}
                    {...getFieldProps("email")}
                  />
                  <InputTextLarge
                    size="12"
                    title={lang?.password}
                    type="password"
                    id="pwd"
                    error={touched.pwd && errors.pwd}
                    {...getFieldProps("pwd")}
                  />

                  <Notify showTextOnly />

                  {!loading ? (
                    <div style={{ textAlign: "center" }}>
                      <button
                        style={{ backgroundColor: "#00387B", color: "#fff" }}
                        type="submit"
                        class="btn rounded "
                      >
                        {lang?.sign_in}
                      </button>
                      <Link to="/signup" class="btn rounded ">
                        {lang?.sign_up}
                      </Link>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <button
                          style={{ backgroundColor: "#00387B", color: "#fff" }}
                          type="button"
                          class="btn btn-sm"
                        >
                          <Loader size="fa-2x" color="#fff" showText={false} />
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
              <div class="card-footer">
                <div className="row">
                  <div className="col-md-3">
                    <a
                      href="https://cybexai.com"
                      class="font-weight-bold mr-5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/images/cybex_logo.png"
                        height="30"
                        alt="cybexai"
                      />
                    </a>
                  </div>

                  <div className="col-md-9">
                    <a style={{ float: "right" }} className="text" href="/">
                      {lang?.termsAndConditions}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Signin;
