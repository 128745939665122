import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import LangSelect from './micro/LangSelect';
import useLang from '../hook/useLang';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/auth/authSlice';

const AdminHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const lang = useLang();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const routeName = useMemo(() => {
    if (pathname?.includes('dashboard')) {
      return lang?.dashboard?.toUpperCase();
    }
    if (pathname?.includes('securite')) {
      return lang?.settings?.toUpperCase();
    }
    if (pathname?.includes('match')) {
      return 'MATCHS';
    }
    if (pathname?.includes('player')) {
      return lang?.players?.toUpperCase();
    }
    if (pathname?.includes('referee')) {
      return lang?.referees?.toUpperCase();
    }
    if (pathname?.includes('coach')) {
      return 'COACHS';
    }
    if (pathname?.includes('club')) {
      return 'CLUBS';
    }
    if (pathname?.includes('inscrit')) {
      return lang?.enrolled?.toUpperCase();
    }
    if (pathname?.includes('tournois')) {
      return lang?.tournament?.toUpperCase();
    }
    if (pathname?.includes('federation')) {
      return 'FEDERATION';
    }
    if (pathname?.includes('ligue')) {
      return 'LEAGUE';
    }
  }, [pathname, lang]);

  useEffect(() => {
    var script = document.createElement('script');
    script.onload = function () {
      //do stuff with the script
      script.src = 'assets/admin/js/argon.js';
    };
    script.src = 'assets/admin/js/argon.js';

    document.head.appendChild(script);
  }, []);

  const [toggle, setToggle] = useState(true);

  const toggleSidebar = () => {
    if (toggle) {
      $('.sidenav-toggler').addClass('active');
      $('.sidenav-toggler').data('action', 'sidenav-unpin');
      $('body')
        .removeClass('g-sidenav-hidden')
        .addClass('g-sidenav-show g-sidenav-pinned');
      $('body').append(
        '<div className="backdrop d-xl-none" data-action="sidenav-unpin" data-target=' +
          $('#sidenav-main').data('target') +
          ' />'
      );
      setToggle(false);
    } else {
      $('.sidenav-toggler').removeClass('active');
      $('.sidenav-toggler').data('action', 'sidenav-pin');
      $('body').removeClass('g-sidenav-pinned').addClass('g-sidenav-hidden');
      $('body').find('.backdrop').remove();
      setToggle(true);
    }
  };

  const signout = () => {
    localStorage.removeItem('fvApp');
    localStorage.removeItem('fvAccessToken');
    localStorage.removeItem('fvUser');
    sessionStorage.removeItem('cybexai-fcvb-authUser');
    dispatch(logout());
    navigate('/signin');
  };

  return (
    <nav
      className="navbar navbar-top navbar-expand"
      style={{ height: '60px', boxShadow: 'none' }}
    >
      <div className="container-fluid">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <h6 className="h2 d-inline-block text-uppercase mb-0">{routeName}</h6>
          <ul className="navbar-nav align-items-center ml-auto">
            <li style={{ left: 10, position: 'absolute' }}>
              <div className="navbar-brand">
                <span style={{ color: '#000' }}>
                  <img
                    src="/assets/images/fecavolley.png"
                    height="40"
                    alt="Logo"
                  />
                  <i className="mr-2"></i>
                </span>
                <span style={{ color: '#000' }}>SLIVE</span>
              </div>
            </li>
            <li className="nav-item d-xl-none">
              <div
                className="pr-3 sidenav-toggler sidenav-toggler-dark"
                onClick={() => toggleSidebar()}
                data-action="sidenav-pin"
                data-target="#sidenav-main"
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-dark"></i>
                  <i className="sidenav-toggler-line bg-dark"></i>
                  <i className="sidenav-toggler-line bg-dark"></i>
                </div>
              </div>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li>
              <Link to="/admin/notification">
                <i className="fa fa-bell"></i>
                <span
                  className="numberCircle"
                  style={{
                    background: '#FFD600',
                    position: 'relative',
                    top: '-8px',
                    left: '-7px',
                    borderRadius: '50%',
                    padding: 5,
                  }}
                ></span>
              </Link>
            </li>
          </ul>
          <ul className="navbar-nav align-items-center ml-sm-0 ">
            <li className="nav-item dropdown">
              <a
                className="nav-link pr-0"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="media align-items-center">
                  <i className="fas fa-caret-down mr-2"></i>
                  <i className="fa fa-user-circle"></i>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user?.name}
                    </span>
                  </div>
                </div>
              </a>
              <div className="dropdown-menu  dropdown-menu-right ">
                <Link to="/admin/profile" className="dropdown-item">
                  <i className="fa fa-user"></i>
                  <span> {lang?.profil} </span>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/admin/settings" className="dropdown-item">
                  <i className="fa fa-cog"></i>
                  <span>{lang?.settings} </span>
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onClick={() => signout()}>
                  <i className="fa fa-power-off"></i>
                  <span> {lang?.logOut} </span>
                </a>
              </div>
            </li>
          </ul>

          <LangSelect />
        </div>
      </div>
    </nav>
  );
};

export default AdminHeader;
