import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_LOCALS } from "../../utilities/locals";
import * as API from "../../api/api";
import { notifyError, openSessionExpiredModal } from "../ui";

const enrolledSlice = createSlice({
  name: "enrolled",
  initialState: {
    data:null,
    
    loading:false,
    sending:false,
    statLoaded:false,
  },
  reducers: {
    initEnrolled: (state, { payload }) => {
        // console.log("init enrolled",payload)
        // eslint-disable-next-line no-unused-expressions
        state.data = {}
        // eslint-disable-next-line no-unused-expressions
        payload?.forEach((match)=>{
            state.data[match?.id] = match
        })
    },
    deleteEnrolled:(state, { payload }) => {
      delete state.data?.[payload];
      return state;
    },
    initEnrolledInfo: (state, { payload }) => {
      if(!state.data){
        state.data = {}
      }
      state.data[payload?.id] = {...state.data[payload?.id],...payload}
    },
    initStopLoading:(state)=>{
        state.loading = !state.loading
    },
    initCount:(state,{payload})=>{
      state.count = payload
    },
    changeStatLoaded:(state)=>{
      state.statLoaded = true
    },
  },
  extraReducers: (builder) => {},
});

const enrolledReducer = enrolledSlice.reducer;

export const { initEnrolled , initStopLoading , initCount , changeStatLoaded , initEnrolledInfo , deleteEnrolled } = enrolledSlice.actions;
export default enrolledReducer;

export const initEnrolledCount = ()=>async(dispatch , getState)=>{
  if(!getState().enrolled?.statLoaded){
    const ligue = localStorage.getItem("fvApp");
    let dataResponse
    if (ligue !== ADMIN_LOCALS) {
      dataResponse = await API.getInscritCount();
      
      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.data))
        : dispatch(notifyError(""))
    }else{
      dataResponse = await API.getInscritByLigue(getState().auth?.user?.league)
      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.length))
        : dispatch(notifyError(""))
    }
    dispatch(changeStatLoaded())
  }
}

export const getEnrolledAsync = ()=>async(dispatch, getState)=>{
  try {
    const ligue = localStorage.getItem("fvApp");
    let loggedInUser = getState().auth.user
    let response;
    dispatch(initStopLoading())
    if (ligue !== ADMIN_LOCALS) {
      response = await API.getInscrit()
    } else {
      response =  await API.getInscritByLigue(loggedInUser.league)
      
    }

    if (String(response).substring(String(response).length - 3) === "401") {
      dispatch(openSessionExpiredModal("/admin/inscrit"));
    } else {
      response.status === 200
        ? dispatch(initEnrolled(response.data))
        : dispatch(notifyError());
    }
  } catch (error) {
    console.log("error when loaded enrolled",error);
  }finally{
    dispatch(initStopLoading())
  }
}