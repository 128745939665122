export const playersEn = {
  players_list: "players list",
  filter_by: "Filter_by",
  choose_option: "choose and Option",
  filter_table: "",
  matricule: "matricule",
  preview_print: "Preview Print",
  print: "Print",
  aad_player: "Add Player",
  edit: "Edit",
  detail: "Details",
  transfer: "Transfer",
  Delete: "Delete",
  showAll: "show All",
  player_detail: "PLAYERS DETAIL",
  referee_list: "REFEREE LIST",
  coach_list: "COACH LIST",
};
export const playersFr = {
  player_detail: "INFORMATION DU JOUEUR",
  players_list: "LISTE DE JOUEURS",
  filter_by: "Filter Par Club",
  choose_option: "choisir une option",
  filter_table: "Filtrer",
  matricule: "matricule",
  preview_print: "Aperçu De L'impression",
  print: "Imprimer",
  aad_player: "Ajouter Un Joueur",
  edit: "Modifier",
  detail: "Details",
  transfer: "Transfert",
  Delete: "Supprimer",
  showAll: "La Liste complete",
  referee_list: "Liste Des Arbitres",
  coach_list: "LISTE DES COACHS",
};
