import React, { Suspense, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {  useDispatch, useSelector } from "react-redux";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "./index.css";

import { closeNotify } from "./store/ui";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MatchReport from "./components/matchReport/matchReport";
import Tournament from "./views/tournaments/Tournament";
import TournoisForm from "./views/tournaments/components/TournoisForm";
import TournoisDetails from "./views/tournaments/components/TournoisDetails";
import MatchCalenda from "./views/tournaments/components/matchCalenda";
import Match from "./views/matchs/Match";
import MatchDetail from "./views/matchs/MatchDetail";
import MatchForm from "./views/matchs/components/MatchForm";
import ClubList from "./views/clubs/ClubList";
import ClubAdmin from "./views/clubs/components/ClubAdmin";
import ClubForm from "./views/clubs/components/ClubForm";
import ClubAdminForm from "./views/clubs/components/ClubAdminForm";
import ClubAdminDetail from "./views/clubs/components/ClubAdminDetail";
import Referee from "./views/referee/Referee";
import Ligue from "./views/league/Ligue";
import LigueDetail from "./views/league/LigueDetail"
import LigueForm from "./views/league/components/LigueForm"
import Federation from "./views/federations/Federation";
import FederationForm from "./views/federations/components/FederationForm"
import FederationDetail from "./views/federations/FederationDetail";
import RefereeFormAdmin from "./views/referee/components/RefereeFormAdmin";
import RefereeDetail from "./views/referee/components/RefereeDetail";
import Coach from "./views/coach/Coach";
import CoachDetail from "./views/coach/components/CoachDetail";
import CoachFormAdmin from "./views/coach/components/CoachFormAdmin";
import Inscrit from "./views/enrolled/Inscrit";
import InscritDetail from "./views/enrolled/components/InscritDetail";
import Player from "./views/players/Player";
import PlayerDetail from "./views/players/components/PlayerDetail";
import PlayerFormAdmin from "./views/players/components/PlayerFormAdmin";
import Dashboard from "./views/dashboard/Dashboard";
import MainLayout from "./layouts/MainLayout";
import Signin from "./views/auth/Signin";
import Signup from "./views/auth/Signup";
import EScoreSheet from "./views/eScoreSheet/EScoreSheet";
import Profile from "./views/settings/Profile";
import Notification from "./views/settings/Notification";
import Settings from "./views/settings/Settings";
import Securite from "./views/settings/Securite";
import Role from "./views/settings/Role";
import MatriculeCode from "./views/settings/MatriculeCode";
import Permission from "./views/settings/Permission";
import RolePermission from "./views/settings/RolePermission";
import NotFound from "./views/error/NotFound";
import MatchResultForm from "./views/matchs/components/MatchResultForm";

const App = () => {
  const {notifyDisplay , notifyMessage , notifyType } = useSelector((state) => state.ui);
  const dispatch = useDispatch()
    useEffect(() => {
      
      if (notifyDisplay !== "none") {
        Store.addNotification({
          message:notifyMessage,
          type:notifyType,
          insert: "bottom",
          container: "bottom-center",
          dismiss: {
            duration: 5000,
          },
        });
      }
      return () => {
        dispatch(closeNotify());
      };

    }, [
      notifyDisplay,
      notifyMessage,
      notifyType,
      dispatch,
    ]);
  return (
      <Suspense fallback={<Spinner animation="border" role="status" />}>
        <ReactNotifications />
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Signin />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/e-score-sheet" element={<EScoreSheet />} />
            <Route
              path={"/admin"}
              element={<MainLayout />}>
              <React.Fragment>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/referee" element={<Referee />} />
                <Route path="/admin/referee-detail" element={<RefereeDetail />} />
                <Route path="/admin/referee-form" element={<RefereeFormAdmin />} />
                <Route path="/admin/coach" element={<Coach />} />
                <Route path="/admin/coach-detail" element={<CoachDetail />} />
                <Route path="/admin/coach-form" element={<CoachFormAdmin />} />
                <Route path="/admin/player" element={<Player />} />
                <Route path="/admin/player-form" element={<PlayerFormAdmin />} />
                <Route path="/admin/player-detail" element={<PlayerDetail />} />
                <Route path="/admin/ligue" element={<Ligue />} />
                <Route path="/admin/ligue-form" element={<LigueForm />} />
                <Route path="/admin/ligue-detail" element={<LigueDetail />} />
                <Route path="/admin/club" element={<ClubList />} />
                <Route path="/admin/club-admin" element={<ClubAdmin />} />
                <Route
                  path="/admin/club-admin-detail"
                  element={<ClubAdminDetail />}
                />
                <Route path="/admin/club-admin-form" element={<ClubAdminForm />} />
                <Route path="/admin/club-form" element={<ClubForm />} />
                <Route path="/admin/match" element={<Match />} />
                <Route path="/admin/match-form" element={<MatchForm />} />
                <Route path="/admin/match-detail" element={<MatchDetail />} />
                <Route path="/admin/match-result" element={<MatchResultForm />} />
                <Route path="/admin/match-report" element={<MatchReport />} />

                <Route path="/admin/tournois" element={<Tournament />} />
                <Route path="/admin/tournois-form" element={<TournoisForm />} />
                <Route path="/admin/tournois-calenda" element={<MatchCalenda />} />
                <Route
                  path="/admin/tournois-details"
                  element={<TournoisDetails />}
                />

                <Route path="/admin/inscrit" element={<Inscrit />} />
                <Route path="/admin/inscrit-detail" element={<InscritDetail />} />
                <Route path="/admin/profile" element={<Profile />} />
                <Route path="/admin/notification" element={<Notification />} />
                <Route path="/admin/settings" element={<Settings />} />
                {/* {restrictedRoutes()} */}

                {/* To be protected */}
                <Route path="/admin/securite" element={<Securite />} />
                <Route path="/admin/securite/role" element={<Role />} />
                <Route
                  path="/admin/securite/matricule-code"
                  element={<MatriculeCode />}
                />
                <Route path="/admin/securite/permission" element={<Permission />} />
                <Route
                  path="/admin/securite/role-permission/:roleCode"
                  element={<RolePermission />}
                />
                <Route path="/admin/federation" element={<Federation />} />
                <Route
                  path="/admin/federation-form/:userId"
                  element={<FederationForm />}
                />
                <Route
                  path="/admin/federation-detail/:userId"
                  element={<FederationDetail />}
                />

                {/* To be protected */}
              </React.Fragment>
            </Route>
            <Route element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
  );
};

export default App;
