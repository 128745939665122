/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import * as API from "../../api/api";
import { notifyError, notifySuccess, openSessionExpiredModal } from "../ui";
import { ADMIN_LOCALS } from "../../utilities/locals";

const clubSlice = createSlice({
  name: "club",
  initialState: {
    data: {},
    staff: {},
    loaded: false,
    loading: false,
    sending: false,
    stateLoaded: false,
    staffLoaded: false,
  },
  reducers: {
    initClub: (state, { payload }) => {
    
      payload?.forEach((match) => {
        state.data[match?.id] = match;
      });
      state.loaded = true;
    },
    updateStaff: (state, { payload }) => {
      // console.log("update of staff here", payload)
      state.staff[payload?.id] = {...(state.staff[payload?.id] || {}), ...payload}
    },
    initStaff: (state, { payload }) => {
      // console.log("init of staff here", payload);
      payload?.forEach((staff) => {
        state.staff[staff.id] = staff;
      });
      state.staffLoaded = true;
    },
    initStopLoading: (state) => {
      state.loading = !state.loading;
    },
    initCount: (state, { payload }) => {
      state.count = payload;
    },
    changeStatLoaded: (state) => {
      state.statLoaded = true;
    },
    initStopSending: (state) => {
      state.sending = !state.sending;
    },
  },
  extraReducers: (builder) => {},
});

const clubReducer = clubSlice.reducer;

export const {
  initClub,
  initStopLoading,
  initCount,
  changeStatLoaded,
  initStaff,
  updateStaff,
  initStopSending,
} = clubSlice.actions;
export default clubReducer;

export const initClubCount = () => async (dispatch, getState) => {
  if (!getState().club?.statLoaded) {
    const ligue = localStorage.getItem("fvApp");
    let dataResponse;
    if (ligue !== ADMIN_LOCALS) {
      dataResponse = await API.getClubCount();

      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.data))
        : dispatch(notifyError(""));
    } else {
      dataResponse = await API.getClubByLigue(getState().auth?.user?.league);

      dataResponse?.status === 200
        ? dispatch(initCount(dataResponse?.length))
        : dispatch(notifyError(""));
    }
    dispatch(changeStatLoaded());
  }
};

export const getClubs = () => async (dispatch, getState) => {
  if (!getState()?.club?.loaded) {
    try {
      dispatch(initStopLoading());
      const ligue = localStorage.getItem("fvApp");

      let response;

      if (ligue !== ADMIN_LOCALS) {
        response = await API.getClub();
      } else {
        let user = getState().auth?.user;
        response = await API.getClubByLigue(user.league);
      }

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal("/admin/club"));
      } else {
        if (response.status === 200) {
          // dispatch(getClubsSuccess(response.data))
          dispatch(initClub(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(initStopLoading());
    }
  }
};

// to get staff off all club

export const getStaffClubAsync = () => async (dispatch, getState) => {
  if (!getState().club.staffLoaded) {
    try {
      dispatch(initStopLoading());
      const refereeRoleResponse = await API.getRole("CLUB");
      const userGroupId = refereeRoleResponse.data.id;
      const response = await API.getUsers(userGroupId);
      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          // dispatch(getClubsSuccess(response.data))
          dispatch(initStaff(response.data));
        } else {
          dispatch(notifyError());
        }
      }
    } catch (err) {
      console.log("error when get arbit", err);
      dispatch(notifyError());
    } finally {
      dispatch(initStopLoading());
    }
  }
};

// to add club to the system
export const saveClubAsync =
  ({ club, callback = () => {} }) =>
  async (dispatch, getState) => {
    try {
      // console.log("save club async", club);
      delete club?.picture;
      const checkName = await API.getClubByName(club.name);
      const checkCode = await API.getClubByCode(club.code);

      if (checkName?.data?.length > 0 || checkCode?.data?.length > 0) {
        // console.log("`Ce Club existe déjà !`");
        callback(false);
        dispatch(notifyError(`Ce Club existe déjà !`));
      } else {
        const response = await API.saveClub(club);
        // console.log("response here", response);
        if (String(response).substring(String(response).length - 3) === "401") {
          dispatch(openSessionExpiredModal());
        } else {
          if (response.status === 201) {
            callback(true);
            dispatch(notifySuccess("Save Successful !"));
          } else {
            dispatch(notifyError());
          }
        }
      }
    } catch (error) {
      console.log("error when add club", error);
      dispatch(notifyError());
      callback(false);
    }
  };

// to update club here
export const updateClubAsync =
  ({ club, callback = () => {} }) =>
  async (dispatch, getState) => {
    try {
      delete club?.picture;
      const response = await API.updateClub(club);

      if (String(response).substring(String(response).length - 3) === "401") {
        dispatch(openSessionExpiredModal());
      } else {
        if (response.status === 200) {
          dispatch(notifySuccess("Update Successful !"));
          callback(true);
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log("error when update club", error);
      dispatch(notifyError());
    }
  };

export const deleteClubAsync =
  ({ id, pwd }) =>
  async (dispatch, getState) => {
    try {
      let user = {
        pwd: pwd,
        accessToken: localStorage.getItem("fvAccessToken"),
      };

      const checkPasswordResponse = await API.checkPassword(user);

      if (
        String(checkPasswordResponse).substring(
          String(checkPasswordResponse).length - 3
        ) === "401"
      ) {
        // yield dispatch(openSessionExpiredModal())
        dispatch(notifyError("Email or password incorrect. "));
      } else {
        if (checkPasswordResponse.status === 200) {
          const response = await API.deleteClub(id);

          if (response.status === 200) {
            dispatch(notifySuccess("Delete Successful !"));
            dispatch(getClubs());
          } else {
            dispatch(notifyError());
          }
        } else {
          dispatch(notifyError());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
