import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';
import * as Yup from 'yup';
import {
  BackButton,
  DropdownLarge,
  DropdownUser,
  InputTextLarge,
  Loader,
  MatchCaptainRow,
  MatchStaffRow,
  MatchTableRow,
  SaveButton,
  SaveSpinner,
} from '../../../components';

// import {
//   saveMatch,
//   updateMatch,
//   getTournois,
//   getClubs,
//   getMatchFormData,
// } from "../../screens/admin/actions";

import { yupValidation } from '../../../utilities';
import { Accordion, Modal, Table } from 'react-bootstrap';
import { Store } from 'react-notifications-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { playersNum } from '../../../utilities/constants';
// import RosterModal from './RosterModal';
import {
  getMatchAsync,
  saveMatchAsync,
  updateMatchAsync,
} from '../../../store/matchs/matchSlice';
import TeamHeader from './TeamHeader';
import usePlayers from '../../../hook/usePlayers';

const MatchForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  //const matchId = location.state;

  const clubs = useSelector((state) => Object.values(state.club?.data || {}));
  const { data: match, loading: loadingMatch } = useSelector(
    (state) => state.match
  );
  const { loading: loadingPlayer } = usePlayers();
  const tournois = useSelector((state) => Object.values(state.tournois.data));
  const { data: playerList, loading } = useSelector((state) => state.player);
  const players = useMemo(() => Object.values(playerList), [playerList]);
  const refereeList = useSelector((state) =>
    Object.values(state.referee.data || {})
  );
  // const clubAdmins = useSelector((state) => state.adminState.clubAdmins);
  const [playerSelection, setPlayerSelection] = useState({});
  const [captainSelection, setCaptainSelection] = useState({});
  const [staffSelection, setStaffSelection] = useState({});
  const [kitSelection, setKitSelection] = useState({});
  const [teamAColor, setTeamAColor] = useState('');
  const [teamBColor, setTeamBColor] = useState('');

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  // const clubList = clubs.reduce((a, b) => ({ ...a, [b.id]: b }), {});

  const handleClose = () => {
    setShow(false);
    setPlayerSelection({});
    setCaptainSelection({});
    setStaffSelection({});
    setKitSelection({});
  };

  useEffect(() => {
    // dispatch(getMatchFormData());
    // if (props.location.data !== undefined)
    // matchInitializer(props.location.data);

    dispatch(getMatchAsync({ callback: () => {} }));
    // dispatch(getPl)
  }, [dispatch]);

  // const initializeMatch = (match) => {
  //   setValues(match);

  //   // setFieldValue('_region', match._region.id || '')
  // };

  const onOpenRoster = () => {
    if (values._participantOne === '' || values._participantTwo === '') {
      Store.addNotification({
        message: 'Select Both Teams first!',
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-center',
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      setShow(true);
    }
  };

  const saveRoster = () => {
    setShow(false);
  };
  const onSuccess = () => {
    setPending(false);
    navigate(-1);
  };
  const onStop = () => {
    setPending(false);
  };
  const onPlayerSelect = (data) => {
    if (Object.keys(playerSelection || {})?.includes(data)) {
      const result = Object.values(playerSelection || {}).filter(
        (res) => res.player.id !== data
      );
      const rest = result.reduce((a, b) => ({ ...a, [b.player.id]: b }), {});
      setPlayerSelection(rest);
    } else {
      setPlayerSelection((prev) => ({ ...prev, [data.player.id]: data }));
    }
  };

  const onCaptainSelect = (data) => {
    if (Object.keys(captainSelection || {})?.includes(data.id)) {
      const result = Object.values(captainSelection || {}).filter(
        (res) => res.id !== data.id
      );
      const rest = result.reduce((a, b) => ({ ...a, [b.id]: b }), {});
      setCaptainSelection(rest);
    } else {
      setCaptainSelection((prev) => ({ ...prev, [data.id]: data }));
    }
  };

  const onStaffSelect = (data) => {
    if (Object.keys(staffSelection || {})?.includes(data)) {
      const result = Object.values(staffSelection || {}).filter(
        (res) => res.id !== data
      );
      const rest = result.reduce((a, b) => ({ ...a, [b.id]: b }), {});
      setStaffSelection(rest);
    } else {
      setStaffSelection((prev) => ({ ...prev, [data.id]: data }));
    }
  };

  const onKitSelect = (value) => {
    setKitSelection((prev) => ({ ...prev, [value.id]: value }));
  };

  const handleChangeComplete = (color, team) => {
    if (team === 'A') {
      setTeamAColor(color.hex);
    } else {
      setTeamBColor(color.hex);
    }
  };

  const {
    values,
    handleSubmit,
    getFieldProps,
    setValues,
    errors,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      id: '',
      state: true,
      _tournois: null,
      _participantOne: null,
      _participantTwo: null,
      dayNum: 0,
      matchNum: 0,
      phase: null,
      dateMatch: null,
      timeMatch: null,
      country: null,
      town: null,
      hall: null,
      numSpectators: 0,
      countryCode: null,
      division: null,
      category: null,
      _refereePremier: null,
      _refereeSecond: null,
      _refereeScorer: null,
      _refereeScorerAssistant: null,
      _supervisor: null,
      _jugeOne: null,
      _jugeTwo: null,
      _jugeThree: null,
      _jugeFour: null,
    },

    validationSchema: Yup.object().shape(yupValidation.matchYup),

    onSubmit(values) {
      // const {
      //   playerSelection,
      //   captainSelection,
      //   kitSelection,
      //   staffSelection,
      //   teamAColor,
      //   teamBColor,
      // } = matchParticipant;
      if (
        !Object.values(playerSelection).length ||
        !Object.values(kitSelection).length ||
        !Object.values(captainSelection).length
      ) {
        Store.addNotification({
          message: 'Roster incomplete!',
          type: 'danger',
          insert: 'bottom',
          container: 'bottom-center',
          dismiss: {
            duration: 5000,
          },
        });
        return;
      }

      let tempPlayerSelection = [];
      Object.keys(kitSelection).map((val) =>
        Object.values(playerSelection).forEach((pla) => {
          if (pla.player.id === val) {
            let temp = { ...pla, kit: kitSelection[val].kit };
            tempPlayerSelection.push(temp);
          }
        })
      );

      const match = {
        ...values,
        roster: JSON.stringify({
          playerSelection: tempPlayerSelection.reduce(
            (a, b) => ({
              ...a,
              [b.player.id]: {
                player: b.player,
                clubID: b.clubID,
                kit: b.kit,
              },
            }),
            {}
          ),
          captainSelection,
          staffSelection,
          teamAColor,
          teamBColor,
        }),
      };
      console.log(
        {
          playerSelection: tempPlayerSelection.reduce(
            (a, b) => ({
              ...a,
              [b.player.id]: {
                player: b.player,
                clubID: b.clubID,
                kit: b.kit,
              },
            }),
            {}
          ),
          captainSelection,
          staffSelection,
          teamAColor,
          teamBColor,
        }
      );

      setPending(true);
      if (values.id === '' || !values.id) {
        dispatch(saveMatchAsync({ match, onSuccess, onStop }));
      } else {
        dispatch(updateMatchAsync({ match, onSuccess, onStop }));
      }
    },
  });

  let selected = [
    values._refereePremier,
    values._refereeSecond,
    values._refereeScorer,
    values._refereeScorerAssistant,
    values._supervisor,
    values._jugeFour,
    values._jugeOne,
    values._jugeThree,
    values._jugeTwo,
  ];

  //console.log("match ", location.state)
  if (loadingMatch || loadingPlayer) {
    return <Loader />;
  }

  return (
    <div class="header pb-6">
      <div class="container-fluid">
        <form onSubmit={handleSubmit} class="header-body">
          <div class="row align-items-center py-4">
            <div class="col-lg-12">
              <h6 class="h2 d-inline-block text-uppercase mb-0">
                <span class="">
                  <i class="fa fa-plus fa-lg mr-3"></i>
                </span>
                {!location.state ? 'ADD' : 'EDIT'} A MATCH
              </h6>
              <BackButton />
              <h6 className="float-right">
                {!pending ? <SaveButton /> : <SaveSpinner />}
              </h6>
              <h6
                style={{ float: 'right' }}
                className="h2 d-inline-block text-uppercase mb-0"
              >
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={onOpenRoster}
                  style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                >
                  Roster
                </button>
              </h6>
              {/* <h6
                style={{ float: "right" }}
                class="h2 d-inline-block text-uppercase mb-0"
              >
                {" "}
                <Link
                  to="/admin/match"
                  className="btn btn-sm"
                  style={{ backgroundColor: "#19a0c2", color: "#fff" }}
                >
                  <i class="fa fa-list fa-lg mr-2"></i> LISTE DES MATCHS
                </Link>
              </h6> */}
            </div>
          </div>

          <>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <DropdownLarge
                        title="Tournaments"
                        options={tournois}
                        dynamic={true}
                        name="_tournois"
                        id="_tournois"
                        error={touched._tournois && errors._tournois}
                        {...getFieldProps('_tournois')}
                        required
                      />
                      {values._tournois === null ? (
                        <DropdownLarge
                          title="Team 1"
                          options={clubs?.filter(
                            (val) => val.id !== values._participantTwo
                          )}
                          dynamic={true}
                          name="_participantOne"
                          id="_participantOne"
                          error={
                            touched._participantOne && errors._participantOne
                          }
                          {...getFieldProps('_participantOne')}
                          required
                        />
                      ) : (
                        <DropdownLarge
                          title="Team 1"
                          options={(() => {
                            const t = tournois.filter(
                              (tournoi) => tournoi.id === values._tournois
                            );

                            if (t?.length) {
                              return JSON.parse(t[0].teams);
                            }
                          })()}
                          dynamic={true}
                          name="_participantOne"
                          id="_participantOne"
                          error={
                            touched._participantOne && errors._participantOne
                          }
                          {...getFieldProps('_participantOne')}
                          required
                        />
                      )}
                    </div>

                    <div class="row">
                      {values._tournois === null ? (
                        <DropdownLarge
                          title="Team 2"
                          options={clubs.filter(
                            (val) => val.id !== values._participantOne
                          )}
                          dynamic={true}
                          name="_participantTwo"
                          id="_participantTwo"
                          error={
                            touched._participantTwo && errors._participantTwo
                          }
                          {...getFieldProps('_participantTwo')}
                          required
                        />
                      ) : (
                        <DropdownLarge
                          title="Team 2"
                          options={(() => {
                            const t = tournois.filter(
                              (tournoi) => tournoi.id === values._tournois
                            );

                            if (t?.length) {
                              return JSON.parse(t[0].teams);
                            }
                          })()}
                          dynamic={true}
                          name="_participantTwo"
                          id="_participantTwo"
                          error={
                            touched._participantTwo && errors._participantTwo
                          }
                          {...getFieldProps('_participantTwo')}
                          required
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <InputTextLarge
                        title="Match N⁰"
                        name="matchNum"
                        id="matchNum"
                        error={touched.matchNum && errors.matchNum}
                        {...getFieldProps('matchNum')}
                      />
                      <InputTextLarge
                        title="Day N⁰"
                        name="dayNum"
                        id="dayNum"
                        error={touched.dayNum && errors.dayNum}
                        {...getFieldProps('dayNum')}
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Phase"
                        name="phase"
                        id="phase"
                        error={touched.phase && errors.phase}
                        {...getFieldProps('phase')}
                        required
                      />
                      <InputTextLarge
                        title="Date of match"
                        type="date"
                        name="dateMatch"
                        id="dateMatch"
                        error={touched.dateMatch && errors.dateMatch}
                        {...getFieldProps('dateMatch')}
                        required
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Time of the match"
                        type="time"
                        name="timeMatch"
                        id="timeMatch"
                        error={touched.timeMatch && errors.timeMatch}
                        {...getFieldProps('timeMatch')}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <InputTextLarge
                        title="Country"
                        name="country"
                        id="country"
                        error={touched.country && errors.country}
                        {...getFieldProps('country')}
                      />
                      <InputTextLarge
                        title="Town"
                        name="town"
                        id="town"
                        error={touched.town && errors.town}
                        {...getFieldProps('town')}
                      />
                    </div>

                    <div class="row">
                      <InputTextLarge
                        title="Hall"
                        name="hall"
                        id="hall"
                        error={touched.hall && errors.hall}
                        {...getFieldProps('hall')}
                        required
                      />
                      <InputTextLarge
                        title="Number of spectators"
                        type="number"
                        name="numSpectators"
                        id="numSpectators"
                        error={touched.numSpectators && errors.numSpectators}
                        {...getFieldProps('numSpectators')}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <InputTextLarge
                        title="Country code"
                        name="countryCode"
                        id="countryCode"
                        error={touched.countryCode && errors.countryCode}
                        {...getFieldProps('countryCode')}
                      />
                      <DropdownLarge
                        title="Division"
                        options={['Men', 'Women']}
                        name="division"
                        id="division"
                        error={touched.division && errors.division}
                        {...getFieldProps('division')}
                        required
                      />
                    </div>

                    <div class="row">
                      <DropdownLarge
                        title="Category"
                        options={['Senior', 'Junior', 'Youth']}
                        name="category"
                        id="category"
                        error={touched.category && errors.category}
                        {...getFieldProps('category')}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Referee (First)"
                          options={refereeList}
                          name="_refereePremier"
                          id="_refereePremier"
                          selected={selected}
                          error={
                            touched._refereePremier && errors._refereePremier
                          }
                          {...getFieldProps('_refereePremier')}
                          required
                        />
                      ) : null}
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Referee (Second)"
                          options={refereeList}
                          name="_refereeSecond"
                          id="_refereeSecond"
                          selected={selected}
                          error={
                            touched._refereeSecond && errors._refereeSecond
                          }
                          {...getFieldProps('_refereeSecond')}
                          required
                        />
                      ) : null}
                    </div>

                    <div class="row">
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Referee (Scorer)"
                          options={refereeList}
                          name="_refereeScorer"
                          id="_refereeScorer"
                          selected={selected}
                          error={
                            touched._refereeScorer && errors._refereeScorer
                          }
                          {...getFieldProps('_refereeScorer')}
                          required
                        />
                      ) : null}
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Referee (Assistant Scorer)"
                          options={refereeList}
                          name="_refereeScorerAssistant"
                          id="_refereeScorerAssistant"
                          selected={selected}
                          error={
                            touched._refereeScorerAssistant &&
                            errors._refereeScorerAssistant
                          }
                          {...getFieldProps('_refereeScorerAssistant')}
                        />
                      ) : null}
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Supervisor"
                          options={refereeList}
                          name="_supervisor"
                          id="_supervisor"
                          selected={selected}
                          error={touched._supervisor && errors._supervisor}
                          {...getFieldProps('_supervisor')}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Judge 1"
                          options={refereeList}
                          name="_jugeOne"
                          id="_jugeOne"
                          selected={selected}
                          error={touched._jugeOne && errors._jugeOne}
                          {...getFieldProps('_jugeOne')}
                          required
                        />
                      ) : null}
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Judge 2"
                          options={refereeList}
                          name="_jugeTwo"
                          id="_jugeTwo"
                          selected={selected}
                          error={touched._jugeTwo && errors._jugeTwo}
                          {...getFieldProps('_jugeTwo')}
                          required
                        />
                      ) : null}
                    </div>

                    <div class="row">
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Judge 3"
                          options={refereeList}
                          name="_jugeThree"
                          id="_jugeThree"
                          selected={selected}
                          error={touched._jugeThree && errors._jugeThree}
                          {...getFieldProps('_jugeThree')}
                        />
                      ) : null}
                      {refereeList !== 0 ? (
                        <DropdownUser
                          title="Judge 4"
                          options={refereeList}
                          name="_jugeFour"
                          id="_jugeFour"
                          selected={selected}
                          error={touched._jugeFour && errors._jugeFour}
                          {...getFieldProps('_jugeFour')}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="float-right">
              {!pending ? <SaveButton /> : <SaveSpinner />}
            </div>
            <div style={{ float: 'right' }}>
              <button
                className="btn btn-sm"
                onClick={onOpenRoster}
                type="button"
                style={{ backgroundColor: '#19a0c2', color: '#fff' }}
              >
                Roster
              </button>
            </div>
          </>

          {show ? (
            <Modal show={show} centered size="lg" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Roster</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Players</Accordion.Header>
                    <Accordion.Body style={{ margin: 0, padding: 0 }}>
                      <TeamHeader />
                      <div className="row">
                        <div className="col-md-6">
                          {/* <button type="button" onClick={()=>loadAll(values._participantOne)} className="btn btn-sm">Load All</button> */}
                          <Table striped bordered>
                            <thead>
                              <tr>
                                {/* <th>#</th> */}
                                <th>Player</th>
                                <th>Kit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playersNum.map((value, index) => (
                                <MatchTableRow
                                  key={index}
                                  // position={value}
                                  players={players.filter(
                                    ({ clubs }) =>
                                      clubs[0]?.id === values._participantOne
                                  )}
                                  type="onCourt"
                                  onDeselect={onPlayerSelect}
                                  playerSelection={playerSelection}
                                  onPlayerSelect={onPlayerSelect}
                                  clubID={values._participantOne}
                                  showDiff={false}
                                  showInput
                                  setKitSelection={onKitSelect}
                                  index={index + 1}
                                  kitSelection={kitSelection}
                                  teamLabel="A"
                                />
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-6">
                          {/* <button type="button" onClick={()=>loadAll(values._participantTwo)} className="btn btn-sm">Load All</button> */}
                          <Table striped bordered>
                            <thead>
                              <tr>
                                {/* <th>#</th> */}
                                <th>Player</th>
                                <th>Kit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {playersNum.map((value, index) => (
                                <MatchTableRow
                                  key={index}
                                  // position={value}
                                  players={players.filter(
                                    ({ clubs }) =>
                                      clubs[0].id === values._participantTwo
                                  )}
                                  type="onCourt"
                                  onDeselect={onPlayerSelect}
                                  playerSelection={playerSelection}
                                  onPlayerSelect={onPlayerSelect}
                                  clubID={values._participantTwo}
                                  showDiff={false}
                                  showInput
                                  setKitSelection={onKitSelect}
                                  index={index + 1}
                                  kitSelection={kitSelection}
                                  teamLabel="B"
                                />
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Set Captain & Libero</Accordion.Header>
                    <Accordion.Body style={{ margin: 0, padding: 0 }}>
                      <TeamHeader />
                      <div className="row">
                        <div className="col-md-6">
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Player</th>
                              </tr>
                            </thead>
                            <tbody>
                              {['Captain', 'Libero 1', 'Libero 2'].map(
                                (value, index) => (
                                  <MatchCaptainRow
                                    key={index}
                                    position={value}
                                    players={Object.values(
                                      playerSelection
                                    ).filter(
                                      ({ clubID, type }) =>
                                        clubID === values._participantOne
                                    )}
                                    onDeselect={onCaptainSelect}
                                    captainSelection={captainSelection}
                                    onCaptainSelect={onCaptainSelect}
                                  />
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-6">
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Player</th>
                              </tr>
                            </thead>
                            <tbody>
                              {['Captain', 'Libero 1', 'Libero 2'].map(
                                (value, index) => (
                                  <MatchCaptainRow
                                    key={index}
                                    position={value}
                                    players={Object.values(
                                      playerSelection
                                    ).filter(
                                      ({ clubID, type }) =>
                                        clubID === values._participantTwo
                                    )}
                                    onDeselect={onCaptainSelect}
                                    captainSelection={captainSelection}
                                    onCaptainSelect={onCaptainSelect}
                                  />
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Officials</Accordion.Header>
                    <Accordion.Body style={{ margin: 0, padding: 0 }}>
                      <TeamHeader />
                      <div className="row">
                        <div className="col-md-6">
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Staff</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                'Coach',
                                'Assistant Coach 1',
                                'Assistant Coach 2',
                                'Therapist',
                                'Medical Doctor',
                              ].map((value, index) => (
                                <MatchStaffRow
                                  key={index}
                                  position={value}
                                  staffs={clubs.filter(
                                    ({ club }) =>
                                      clubs?.[0]?.id === values._participantOne
                                  )}
                                  onDeselect={onStaffSelect}
                                  staffSelection={staffSelection}
                                  onStaffSelect={onStaffSelect}
                                  clubID={values._participantOne}
                                />
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-6">
                          <Table striped bordered>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Staff</th>
                              </tr>
                            </thead>
                            <tbody>
                              {[
                                'Coach',
                                'Assistant Coach 1',
                                'Assistant Coach 2',
                                'Therapist',
                                'Medical Doctor',
                              ].map((value, index) => (
                                <MatchStaffRow
                                  key={index}
                                  position={value}
                                  staffs={clubs.filter(
                                    ({ club }) =>
                                      club?.[0]?.id === values._participantTwo
                                  )}
                                  onDeselect={onStaffSelect}
                                  staffSelection={staffSelection}
                                  onStaffSelect={onStaffSelect}
                                  clubID={values._participantTwo}
                                />
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Team Colors</Accordion.Header>
                    <Accordion.Body style={{ margin: 0, padding: 0 }}>
                      <TeamHeader />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="p-3">
                            <CirclePicker
                              color={teamAColor}
                              width={'100%'}
                              onChangeComplete={(color) =>
                                handleChangeComplete(color, 'A')
                              }
                            />
                            <div
                              style={{
                                backgroundColor: teamAColor,
                                height: 30,
                              }}
                              className="w-100 mt-3 rounded"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="p-3">
                            <CirclePicker
                              color={teamBColor}
                              width={'100%'}
                              onChangeComplete={(color) =>
                                handleChangeComplete(color, 'B')
                              }
                            />
                            <div
                              style={{
                                backgroundColor: teamBColor,
                                height: 30,
                              }}
                              className="w-100 mt-3 rounded"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-sm"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  className="btn btn-sm"
                  onClick={saveRoster}
                  type="button"
                  // disabled={canStartMatch()}
                  style={{ backgroundColor: '#369688', color: 'white' }}
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default MatchForm;
