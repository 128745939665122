import React from 'react';
import useLang from '../../../hook/useLang';

const EBottomAction = ({
  side = 'left',
  timeout = 10,
  substitution,
  substitutionClick,
  timeoutClick,
  liberoClick,
  injuryClick,
}) => {
  const lang = useLang();
  return side === 'left' ? (
    <div class="bottom-actions left-actions">
      <div class="action time-out injury-left" onClick={injuryClick}>
        <div class="label">{lang?.t('injury')}</div>
      </div>
      <div class="action time-out time-out-left" onClick={timeoutClick}>
        <div class="label">{lang?.t('timeout')}</div>
        <div class="figures">{timeout}</div>
      </div>
      <div class="action substitution libero-left" onClick={liberoClick}>
        <div class="label">Libero</div>
      </div>
      <div
        class="action substitution substitution-left"
        onClick={substitutionClick}
      >
        <div class="label">{lang?.t('substitution')}</div>
        <div class="figures">{substitution}</div>
      </div>
    </div>
  ) : (
    <div class="bottom-actions right-actions">
      <div class="action time-out time-out-right" onClick={timeoutClick}>
        <div class="figures">{timeout}</div>
        <div class="label">{lang?.t('timeout')}</div>
      </div>
      <div class="action time-out injury-right" onClick={injuryClick}>
        <div class="label">{lang?.injury}</div>
      </div>
      <div
        class="action substitution substitution-right"
        onClick={substitutionClick}
      >
        <div class="figures">{substitution}</div>
        <div class="label">{lang?.substitution}</div>
      </div>
      <div class="action substitution libero-right" onClick={liberoClick}>
        <div class="label">Libero</div>
      </div>
    </div>
  );
};

export default EBottomAction;
