import React from "react";
import { Link } from "react-router-dom";
import useLang from "../../../hook/useLang";
import { hasPermission } from "../../../utilities/auth";
export default function Options({ user, row , onDelete }) {
  const lang = useLang();
  return (
    <div class="dropdown">
      <button
        class="btn btn-sm"
        style={{ backgroundColor: "#19a0c2", color: "#fff" }}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i class="fa fa-ellipsis-h"></i>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton">
        {user !== 0
          ? hasPermission(user, ["EDIT_REFEREE"]) && (
              <Link
                to={{ pathname: `/admin/referee-form/` }}
                className="dropdown-item"
                state={{userId:row.id}}>
                <span>
                  <i className="fa fa-edit mr-1"></i> {lang.t('edit')}
                </span>
              </Link>
            )
          : null}
        <div class="dropdown-divider"></div>
        <Link
          to={{
            pathname: "/admin/referee-detail/",
          }}
          state={row.id}
          className="dropdown-item">
          <span>
            <i class="fa fa-list mr-1"></i> {lang.t('detail')}
          </span>
        </Link>
        <div class="dropdown-divider"></div>
        {user
          ? hasPermission(user, ["DEACTIVATE_REFEREE"]) && (
              <button
                onClick={() => onDelete(row)}
                className="dropdown-item text-danger">
                <span>
                  <i className="fa fa-trash mr-1"></i> {lang.t('delete')}
                </span>
              </button>
            )
          : null}
      </div>
    </div>
  );
}
