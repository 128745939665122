import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackButton,
  Checkbox,
  Dropdown,
  DropdownLarge,
  InputPicture,
  InputText,
  InputTextLarge,
  Loader,
  SaveButton,
  SaveSpinner,
} from "../../../components";
import { DropdownConstants } from "../../../utilities";
import { DATE_VALIDATOR_LOCALS } from "../../../utilities/locals";
// import { updateUser, saveUser } from "../../../screens/admin/actions";

import { getArrondissment, getDepartment } from "../../../store/ui";
import {
  updateStaff,
} from "../../../store/clubs/clubSlice";
import MatriculeCode from "./MatriculeCode";
import { getUserInfoAsync, saveUserAsync, updateUserAsync } from "../../../store/users/userSlice";

const ClubAdminForm = (props) => {
  const location = useLocation();
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { userId, club } = location.state;
  const userInfoData = useSelector((state) => state.club.staff);

  const userInfo = useMemo(
    () => userInfoData?.[userId] || null,
    [userId, userInfoData]
  );
  // const {sending} = useSelector((state) => state.club)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    name: "",
    state: true,
    surname: "",
    dob: "",
    matricule: "",
    gender: "",
    pob: "",
    familyStatus: "",
    phone: "",
    email: "",
    profession: "",
    schoolLevel: "",
    league: "",
    _role: loggedInUser?._role?.code,
    _region: "",
    _departement: "",
    _arrondissement: "",
    _subRole: "",
    _club: null,
    matriculeCode: "",
    conseilAdministration: false,
    commission: false,
    signature: "",
  });

  const clubRoles = useSelector((state) =>
    state.ui.roles?.filter((r) => r.code === "CLUB")
  );

  const regions = useSelector((state) => Object.values(state.ui.regions || {}));
  const matriculeCodes = useSelector(
    (state) => state.ui.matriculeCodeList || []
  );
  const [sending, setSending] = useState(false)
  const [loading , setLoading] = useState(false)
  const {
    data: clubData,
  } = useSelector((state) => state.club);

  const clubs = useMemo(
    () => Object.values(clubData).filter((club) => club?.auxiliaire),
    [clubData]
  );

  const departmentList = useSelector(
    (state) => state.ui.department?.[data._region] || []
  );
  const arrondissmentList = useSelector(
    (state) => state.ui.district?.[data._departement] || []
  );

  const [validate, setValidate] = useState(false);
  const [validateMessage, setValidateMessage] = useState("");
  const [picture, setPicture] = useState([]);

  useEffect(() => {
    if (userInfo?.hasAllInfo) {
      const us = { ...userInfo };

      delete us?.clubs;
      delete us?.grade;
      delete us?.validate;

      setData((prev) => ({
        ...prev,
        ...us,
        _role: us?._role?.id,
        _subRole: us?._subRole?.id,
        _club: club,
        signature: us?.signature || "",
        _region: us?._region?.id || "",
        _departement: us?._departement?.id || "",
        _arrondissement: us?._arrondissement?.id || "",
      }));
      setPicture(us?.picture);
      setValidate(us?.validate);
    }
  }, [club, userInfo]);

  useEffect(() => {
    if (data._region !== "") {
      dispatch(getDepartment({ regionId: data._region }));
    }
  }, [data._region, dispatch]);

  useEffect(() => {
    if (data._departement !== "") {
      // console.log("departmentid", data._departement);
      dispatch(getArrondissment({ departmentId: data._departement }));
    }
  }, [data._departement, dispatch]);

  useEffect(() => {
    if (!userInfo?.hasAllInfo) {
      setLoading(true)
      dispatch(getUserInfoAsync({
        userId,
        onSuccess:(data)=>{
          dispatch(updateStaff({...data, hasAllInfo: true}))
        },
        onStop:()=>setLoading(false)
      }));
    }
  }, [dispatch, userId, userInfo]);

  const handleInputChange = (e) => {
  
    if (e.target.id === "dob") {
      let date = new Date(e.target.value);
      let newDate = new Date();
      let validAge = newDate.getFullYear() - date.getFullYear();
      if (validAge < 10 || validAge > 99) {
        setValidate(true);
        setValidateMessage(DATE_VALIDATOR_LOCALS);
        setData((prev) => ({ ...prev, [e.target.id]: "" }));
      } else {
        // setDob(e.target.value);
        setValidate(false);
        setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
      }
    } else {
      if (["commission", "conseilAdministration"].includes(e.target.id)) {
        
        setData((prev) => ({ ...prev, [e.target.id]: !!!prev[e.target.id] }));
        return 
      }

      return setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    }
    // });
  };

  const onSuccess =(data)=>{
    dispatch(updateStaff(data))
    navigate(-1)
  }

 

  const handleSubmit = (e) => {
    e.preventDefault();
    let user = data;

    if (picture?.base64 === undefined) {
      user = {
        ...user,
        picture: picture,
      };
    } else {
      user = {
        ...user,
        picture: picture?.base64,
      };
    }

    if (picture?.length === 0) {
      alert("Please add a picture");
    } else if (!data.name.trim() || !data.surname.trim()) {
      alert("Please enter a valid name");
    } else if (data.matriculeCode === "" && user.id === "") {
      alert("Please choose a MATRICULE CODE");
    } else {
      setSending(true)
      !user.id
        ? dispatch(saveUserAsync({ user, onSuccess,onStop:()=>setSending(false) }))
        : dispatch(updateUserAsync({ user, onSuccess,onStop:()=>setSending(false) }));
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-plus fa-lg mr-3"></i>
                </span>
                {typeof userId === "object" ? "ADD" : "EDIT"} A CLUB MEMBER{" "}
                {
                  Object.values(clubs).filter(({ id }) => id === data?._club)[0]
                    ?.name
                }
              </h6>
              <BackButton />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="font-weight-bold mb-4">
                      General information
                    </h4>
                    <div className="row">
                      <InputTextLarge
                        title="Name"
                        name="name"
                        id="name"
                        onChange={handleInputChange}
                        value={data.name}
                        required
                      />
                      <InputTextLarge
                        title="Surname"
                        name="surname"
                        id="surname"
                        onChange={handleInputChange}
                        value={data.surname}
                        required
                      />
                    </div>

                    <div className="row">
                      <InputTextLarge
                        validate={validate}
                        validateMessage={validateMessage}
                        title="Date of birth"
                        type="date"
                        name="dob"
                        id="dob"
                        onChange={handleInputChange}
                        value={data.dob}
                        required
                      />

                      <InputTextLarge
                        title="Place of birth"
                        name="pob"
                        id="pob"
                        onChange={handleInputChange}
                        value={data.pob}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="Gender"
                        options={DropdownConstants.GENDER}
                        name="gender"
                        id="gender"
                        onChange={handleInputChange}
                        value={data.gender}
                        required
                      />

                      <DropdownLarge
                        title="Marital status"
                        options={DropdownConstants.FAMILY_STATUS}
                        name="familyStatus"
                        id="familyStatus"
                        onChange={handleInputChange}
                        value={data.familyStatus}
                        required
                      />
                      
                      <DropdownLarge
                        title="Role"
                        options={clubRoles}
                        dynamic={true}
                        name="role"
                        id="role"
                        onChange={handleInputChange}
                        value={data._role}
                        required
                      />

                      {clubs.length !== 0 ? (
                        <>
                          {typeof userId === "object" ? (
                            <DropdownLarge
                              title="Club"
                              name="club"
                              id="club"
                              options={clubs}
                              dynamic={true}
                              onChange={handleInputChange}
                              value={data._club}
                              required
                            />
                          ) : (
                            <DropdownLarge
                              title="Club"
                              options={clubs}
                              dynamic={true}
                              name="club"
                              id="club"
                              onChange={handleInputChange}
                              value={data._club}
                              required
                            />
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <InputText
                        title="Profession"
                        name="profession"
                        id="profession"
                        onChange={handleInputChange}
                        value={data.profession}
                        required
                      />

                      <Dropdown
                        title="Education level"
                        options={DropdownConstants.SCHOOL_LEVEL}
                        name="schoolLevel"
                        id="schoolLevel"
                        onChange={handleInputChange}
                        value={data.schoolLevel}
                        required
                      />

                      <InputText
                        title="Phone"
                        type="number"
                        name="phone"
                        id="phone"
                        onChange={handleInputChange}
                        value={data.phone}
                        required
                      />
                    </div>

                    <div className="row">
                      <InputText
                        title="Email"
                        type="email"
                        name="email"
                        id="email"
                        onChange={handleInputChange}
                        value={data.email}
                        required
                      />

                      <Dropdown
                        title="League"
                        options={DropdownConstants.LEAGUE}
                        name="league"
                        id="league"
                        onChange={handleInputChange}
                        value={data.league}
                        required
                      />

                      {matriculeCodes.length !== 0 ? (
                        <MatriculeCode
                          data={data}
                          matriculeCodes={matriculeCodes}
                          handleInputChange={handleInputChange}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {picture !== null ? (
                        <div className="col-lg-6">
                          <div>
                            <label for={"Photo"}>{"Photo"}</label>{" "}
                            <span className="text-danger ml-1">*</span>
                            <br />
                            <div className="row">
                              {picture?.length !== 0 && (
                                <div className="col">
                                  {picture?.base64 === undefined ? (
                                    <img
                                      src={picture}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  ) : (
                                    <img
                                      src={picture?.base64}
                                      height={50}
                                      width={50}
                                      alt="pic"
                                    />
                                  )}
                                </div>
                              )}
                              <div
                                className="col-md-10"
                                style={{ marginTop: -19 }}>
                                <InputPicture
                                  placeholder="Choose another picture"
                                  onDone={setPicture.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6">
                          <InputPicture onDone={setPicture.bind(this)} />
                        </div>
                      )}

                      <DropdownLarge
                        title="Region"
                        options={regions}
                        dynamic={true}
                        name="_region"
                        id="_region"
                        onChange={handleInputChange}
                        value={data._region}
                        required
                      />
                    </div>

                    <div className="row">
                      <DropdownLarge
                        title="Departement"
                        options={departmentList}
                        dynamic={true}
                        name="_departement"
                        id="_departement"
                        onChange={handleInputChange}
                        value={data._departement}
                        required
                      />
                      <DropdownLarge
                        title="District"
                        options={arrondissmentList}
                        dynamic={true}
                        name="_arrondissement"
                        id="_arrondissement"
                        onChange={handleInputChange}
                        value={data._arrondissement}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {adminState.signature !== '' ? <SignaturePreview signature={adminState.signature} /> : null} */}

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      {/* <SignatureButton onClick={() => dispatch(showSignatureModal())} /> */}

                      <div className="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="conseilAdministration"
                          onChange={handleInputChange}
                          checked={data.conseilAdministration}
                        />
                        <label>Member of the Board</label>
                      </div>

                      <div className="col d-flex align-items-center">
                        <Checkbox
                          title=""
                          id="commission"
                          onChange={handleInputChange}
                          checked={data.commission}
                        />
                        <label>Member of the Commission</label>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="float-right">
              {!sending ? <SaveButton /> : <SaveSpinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClubAdminForm;
