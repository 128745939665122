/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { en, fr } from "../../assets/icons";
import { currLangSelector, setLang } from "../../store/langSlice";

const LangSelect = () => {
  const lang = useSelector(currLangSelector);
  const dispatch = useDispatch();
  const select = (curr) => dispatch(setLang({ curr }));

  return (
    <ul className="navbar-nav align-items-center ml-sm-0 ">
      <li className="nav-item dropdown">
        <a
          className="nav-link pr-0"
          href="#"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="media align-items-center">
            <i>
              <img
                src={lang === "fr" ? fr : en}
                style={{
                  height: "17px",
                  width: "15px",
                  marginInline: "0.5rem",
                }}
              />
            </i>
            <span className="">{lang}</span>
          </div>
        </a>
        <div className="dropdown-menu  dropdown-menu-right ">
          <a className="dropdown-item" onClick={() => select("en")}>
            <div className="media align-items-center">
              <i>
                <img
                  src={en}
                  style={{
                    height: "17px",
                    width: "15px",
                    marginInline: "0.5rem",
                  }}
                />
              </i>
              <span>En</span>
            </div>
          </a>
          <a className="dropdown-item" onClick={() => select("fr")}>
            <div className="media align-items-center">
              <i>
                <img
                  src={fr}
                  style={{
                    height: "17px",
                    width: "15px",
                    marginInline: "0.5rem",
                  }}
                />
              </i>
              <span>Fr</span>
            </div>
          </a>
        </div>
      </li>
    </ul>
  );
};
export default LangSelect;
