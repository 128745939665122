import React from "react";
export default function MatriculeCode({data,handleInputChange,matriculeCodes}){
    
    if(!data?.id){
        return(
            <div class="col-lg-4 mt-3">
              <div class="form-group">
                <label for="matriculeCode">Code Matricule</label>{" "}
                <span class="text-danger ml-1">*</span>
                <select
                  class="browser-default custom-select"
                  name="matriculeCode"
                  id="matriculeCode"
                  onChange={handleInputChange}
                  value={data.matriculeCode}>
                  <option value="" disabled selected>
                    Choose an option
                  </option>
                  {matriculeCodes.map((e,i) => {
                    return <option value={e.code} key={i}>{e.code}</option>;
                  })}
                </select>
              </div>
            </div>
        )
    }
    return <div></div>
}