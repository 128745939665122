import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermission } from '../../utilities/auth';
import { Link, useNavigate } from 'react-router-dom';
import { DatatableCard, Dropdown, Loader } from '../../components';
import { DropdownConstants } from '../../utilities';
import { getTournois } from '../../store/tournois/tournoisSlice';
import Options from './components/Options';

const initColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: false,
  },
  {
    name: 'Salle',
    selector: 'salle',
    sortable: false,
  },
  {
    name: 'Start date',
    selector: 'start',
    sortable: false,
  },
  {
    name: 'End date',
    selector: 'end',
    sortable: false,
  },
  {
    name: 'Organizer',
    selector: 'organizer',
    sortable: false,
  },
];

export default function Tournament() {
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { data: clubs } = useSelector((state) => state.club);
  const { data, loading } = useSelector((state) => state.tournois);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(null);

  const filterData = useMemo(() => {
    const d = Object.values(data || {});
    if (!filter || filter === 'Afficher Tout') {
      return d;
    }
    if (filter === 'Afficher Auxiliaire') {
      return d.filter((el) => el?.auxiliaire);
    }
    return d.filter((el) => !el?.auxiliaire);
  }, [data, filter]);

  const ids = useMemo(() => {
    return filterData.map((item) => item?.id);
  }, [filterData]);

  const onEdit = useCallback(
    (tournois) => {
      navigate('/admin/tournois-form', { state: tournois });
    },
    [navigate]
  );

  const getClubCount = useCallback(
    (tournamentId) => {
      const d = Object.values(clubs).filter(
        (club) => club?.tournois?.[0]?.id === tournamentId
      );
      if (d.length === 0) {
        return 'n';
      }
      return d.length;
    },
    [clubs]
  );

  const tableData = useMemo(() => {
    return {
      columns: [
        {
          name: 'Nº',
          width: '50px',
          cell: (row) => <span>{ids.indexOf(row?.id) + 1}</span>,
        },
        ...initColumns,
        {
          name: 'club',

          cell: (row) => (
            <span> {JSON.parse(row?.teams || '[]')?.length} </span>
          ),
        },
        {
          cell: (row) =>
            loggedInUser && hasPermission(loggedInUser, ['EDIT_TOURNAMENT']) ? (
              <button
                onClick={() => onEdit(row)}
                className="btn btn-sm btn-primary"
              >
                <i className="fa fa-edit"></i>
              </button>
            ) : null,
        },

        {
          cell: (row) => <Options row={row} />,
        },
      ],
      data: filterData,
    };
  }, [filterData, getClubCount, ids, loggedInUser, onEdit]);

  useEffect(() => {
    dispatch(getTournois());
  }, [dispatch]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                LIST OF TOURNAMENTS
              </h6> */}
              {loggedInUser
                ? hasPermission(loggedInUser, ['ADD_TOURNAMENT']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/tournois-form"
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser
                ? hasPermission(loggedInUser, ['PRINT_TOURNAMENT']) && (
                    <div className="dropdown float-right">
                      <button
                        className="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button className="dropdown-item">
                          <span>
                            {' '}
                            <i className="fa fa-eye mr-1"></i> Print preview
                          </span>
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item">
                          <span>
                            {' '}
                            <i className="fa fa-print mr-1"></i> Print
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <Dropdown
              title="Filtre"
              options={DropdownConstants.AUXILIAIRE_FILTER}
              onChange={handleFilterChange}
            />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {loading ? <Loader /> : <DatatableCard tableData={tableData} />}
              </div>
            </div>
          </div>
        </div>
        {/* <MatchCalenda /> */}
      </div>
    </div>
  );
}
