import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import useLang from "../../../hook/useLang";

const ELiberoStartModal = ({
  handleClose,
  show,
  teamA,
  teamB,
  teams,
  onLibero,
  captainSelection,
}) => {
  const [playerID1, setPlayerID1] = useState(null);
  const [playerID2, setPlayerID2] = useState(null);
  const [liberoID1, setLiberoID1] = useState(null);
  const [liberoID2, setLiberoID2] = useState(null);
  

  const onSave = () => {
    const list = []
    if(playerID1 && liberoID1){
      list.push({
        time:'00:00',
        team:teams[teamA[0]?.clubID],
        playerBack: playerID1,
        libero: liberoID1,
        active: true,
        
      })
    }
    if(playerID2 && liberoID2){
      list.push({
        time:'00:00',
        team:teams[teamB[0].clubID],
        playerBack: playerID2,
        libero: liberoID2,
        active: true,
        
      })
    }

    onLibero(list);
    handleClose()
  };
  const lang = useLang();

  return (
    <Modal show={show} centered size={"lg"} onHide={handleClose}>
      <Modal.Header closeButton style={{ borderBottom: "1px solid #c2c2c2" }}>
        <Modal.Title>{lang?.addLibero}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{lang?.team}:</p>
                <h1 style={{ fontSize: 16 }}>{teams[teamA[0].clubID]?.name}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">Libero:</p>
              <select
                onChange={({ target }) => setLiberoID1(target.value)}
                className="form-control form-control-sm"
                value={liberoID1}
              >
                <option></option>
                {teamA
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) === "L"
                  )
                  .map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">{lang?.player}:</p>
              <select
                onChange={({ target }) => setPlayerID1(target.value)}
                className="form-control form-control-sm"
                value={playerID1}
              >
                <option></option>
                {teamA
                  .filter((val) => [5, 6, 1].includes(val.position))
                  ?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">{lang?.team}:</p>
                <h1 style={{ fontSize: 16 }}>{teams[teamB[0].clubID]?.name}</h1>
              </div>
            </div>

            <div>
              <p className="mb-0 mt-3">Libero:</p>
              <select
                onChange={({ target }) => setLiberoID2(target.value)}
                className="form-control form-control-sm"
                value={liberoID2}
              >
                <option></option>
                {teamB
                  ?.filter(
                    (val) =>
                      captainSelection[val.player.id]?.role?.charAt(0) === "L"
                  )
                  .map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <p className="mb-0 mt-3">{lang?.player}:</p>
              <select
                onChange={({ target }) => setPlayerID2(target.value)}
                className="form-control form-control-sm"
                value={playerID2}
              >
                <option></option>
                {teamB
                  .filter((val) => [1, 6, 5].includes(val.position))
                  ?.map((val) => (
                    <option key={val.player.id} value={val.player.id}>
                      {`(${val.kit}) ${val.player.name} ${
                        captainSelection[val.player.id]?.role
                          ? `(${captainSelection[val.player.id]?.role?.charAt(
                              0
                            )})`
                          : ""
                      }`}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex mt-5">
          <button
            className="btn btn-sm w-50"
            onClick={onSave}
            style={{ backgroundColor: "#02c0ce", color: "white" }}
          >
            {lang?.addLibero}
          </button>
          <button className="btn btn-sm w-50" onClick={handleClose}>
            {lang?.close}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ELiberoStartModal;
