export const SCHOOL_LEVEL = ['CEP', 'BEPC', 'CAP', 'PROBATOIRE', 'BAC', 'BAC+1', 'BAC+2', 'LICENCE', 'MASTER', 'DOCTORAT', 'PROFESSORAT',
    'FSLC', 'GCE OL', 'GCE AL', 'POST DOCTORAT', 'AUTRE'];
export const FAMILY_STATUS = ['CÉLIBATAIRE', 'MARIÉ(E)', 'DIVORCÉ(E)', 'COMPLIQUÉ', 'FIANCÉ(E)'];
export const GENDER = ['HOMME', 'FEMME', 'AUTRE'];
export const LEAGUE = ['EXTRÊME-NORD', 'NORD', 'ADAMAOUA', 'CENTRE', 'EST', 'SUD', 'SUD-OUEST', 'OUEST', 'NORD-OUEST', 'LITTORAL'];
export const ROLE = ['ARBITRE', 'ENTRAÎNEUR'];
export const FEDERATION_ROLE = ['VICE-PRÉSIDENT', 'CONSEILLER', 'SECRÉTAIRE GÉNÉRAL', 'SECRETAIRE GÉNÉRAL ADJOINT',
    'CHEF DE DÉPARTEMENT DES FINANCES', 'CHEF ADJOINT DES FINANCES', 'COMMISSAIRES AUX COMPTES', 'CHAMBRE DES CONFLITS (PRÉSIDENT)',
    'CHAMBRE DES CONFLITS (MEMBRE)', 'CHAMBRE D’APPEL (PRÉSIDENT)', 'CHAMBRE D’APPEL (MEMBRE)', 'MEMBRE D’HONNEUR',
    'BUREAU EXECUTIF ( PRESIDENCE, SG, FINANCE)', 'PRÉSIDENTS DE LIGUES', 'PERSONNALITÉS', 'DIRECTION TECHNIQUE'];
export const CLUB_CATEGORIES = ['MINIMES', 'CADETS', 'JUNIORS', 'SENIORS', 'ÉCOLE'];
export const LIGUE_TYPE = ['LIGUE RÉGIONALE', 'LIGUE DÉPARTEMENTALE', "LIGUE D'ARRONDISSEMENT"];
export const CLUB_TYPE = ['ÉCOLE', 'CLUB', 'VÉTÉRAN'];
export const AUXILIAIRE_FILTER = ['Afficher Tout', 'Afficher Normal', 'Afficher Auxiliaire'];
//export const COMMISSION = [];