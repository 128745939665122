import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import 'react-data-table-component-extensions/dist/index.css';
import { Accordion, Modal, Table } from 'react-bootstrap';
import { DatatableCard, Dropdown, Loader } from '../../components';

import { hasPermission } from '../../utilities/auth';
import { useReactToPrint } from 'react-to-print';
import useLang from '../../hook/useLang';
import { getMatchAsync } from '../../store/matchs/matchSlice';
import Options from './components/Options';
import TeamHeader from './components/TeamHeader';
import MatchPlayerRow from './components/MatchPlayerRow';

const Match = (props) => {
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { data: tournois } = useSelector((state) => state.tournois);
  const { loading: matchLoading, data } = useSelector((state) => state.match);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useLang();
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState(false);
  const [tournamentId, setTournamentId] = useState();
  const [selectedMatch, setSelectedMatch] = useState({});
  const [playerSelection, setPlayerSelection] = useState({});
  const [captainSelection, setCaptainSelection] = useState({});
  const [teamLabel, setTeamLabel] = useState({});
  const [roster, setRoster] = useState({});
  const [teamA, setTeamA] = useState({});

  const matchList = useMemo(() => {
    if (tournamentId) {
      return Object.values(data || {}).filter(
        (match) => match._tournois.id === tournamentId
      );
    }
    return Object.values(data || {});
  }, [data, tournamentId]);

  const tournamentList = useMemo(
    () => Object.values(tournois || {}),
    [tournois]
  );

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setSelectedMatch(data);
    setRoster(JSON.parse(data.roster));
    setTeamLabel(data._participantOne);
    setTeamA(data._participantOne);
  };

  useEffect(() => {
    dispatch(getMatchAsync({ callback: () => {} }));
  }, [dispatch]);

  const onPlayerSelect = (data, prevSelected) => {
    delete playerSelection?.[JSON.parse(prevSelected || '{}')?.player?.id];
    if (Object.keys(playerSelection || {})?.includes(data.player.id)) {
      const result = Object.values(playerSelection || {}).filter(
        (res) => res.player.id !== data.player.id
      );
      const rest = result.reduce((a, b) => ({ ...a, [b.player.id]: b }), {});
      setPlayerSelection(rest);
    } else {
      setPlayerSelection((prev) => ({ ...prev, [data.player.id]: data }));
    }
  };

  const putAllBench = () => {
    const data = Object.values(roster.playerSelection || {})
      .filter(({ player }) => !Object.keys(playerSelection).includes(player.id))
      .reduce(
        (a, b) => ({
          ...a,
          [b.player.id]: { ...b, type: 'replacement' },
        }),
        {}
      );
    setPlayerSelection((prev) => ({ ...prev, ...data }));
  };

  const matchStatus = (status) => {
    switch (status) {
      case 'NOT_PLAYED':
        return { title: 'Not played', color: 'black' };
      case 'PLAYED':
        return { title: 'Played', color: '#369688' };
      case 'PAUSED':
        return { title: 'Paused', color: '#94393a' };

      default:
        return 'Not played';
    }
  };

  const startMatch = () => {
    localStorage.setItem(
      'fcvb',
      JSON.stringify({
        selectedMatch,
        playerSelection,
        kiffoffTeam: teamLabel?.id,
        teamA: teamA?.id,
        captainSelection,
      })
    );
    navigate('/e-score-sheet');
  };

  const canStartMatch = () => {
    if (Object.keys(playerSelection || {}).length < 12) {
      return true;
    }
    if (
      Object.values(playerSelection).filter(
        (player) =>
          player.clubID === selectedMatch._participantOne?.id &&
          player.type === 'replacement'
      ).length < 8
    ) {
      return true;
    }
    if (
      Object.values(playerSelection).filter(
        (player) =>
          player.clubID === selectedMatch._participantTwo?.id &&
          player.type === 'replacement'
      ).length < 8
    ) {
      return true;
    }
    return false;
  };

  const columnsPrint = [
    {
      name: 'Nº',
      width: '50px',
      cell: (row, index, column, id) => (
        <span>{matchList?.map((el) => el.id)?.indexOf(row?.id) + 1}</span>
      ),
    },
    {
      name: `${lang?.team} A`,
      selector: '_participantOne.name',
      sortable: false,
    },
    {
      name: `${lang?.team} B`,
      selector: '_participantTwo.name',
      sortable: false,
    },
    {
      name: 'Salle',
      selector: 'hall',
      sortable: false,
    },
    {
      name: 'Statut',
      cell: (row) => (
        <span style={{ color: matchStatus(row.status).color }}>
          {matchStatus(row.status).title}
        </span>
      ),
    },
    {
      name: 'Score',
      // selector: "_tournois.salle",
      sortable: false,
    },
    {
      name: 'Tournament',
      cell: (row) => (
        <span style={{ color: matchStatus(row.status).color }}>
          {row?._tournois?.name}
        </span>
      ),
    },
  ];

  const columns = [
    ...columnsPrint,
    {
      cell: (row, index, column, selector) =>
        loggedInUser
          ? hasPermission(loggedInUser, ['VIEW_MATCH']) && (
              <button
                onClick={() => handleShow(row)}
                className="btn btn-sm"
                style={{
                  border: '1px solid #369688',
                  color: '#369688',
                  backgroundColor: 'white',
                }}
              >
                Formation
              </button>
            )
          : null,
    },
    {
      cell: (row, index, column, selector) => (
        <Options row={row} user={loggedInUser} />
      ),
    },
  ];

  const tableData = {
    columns,
    data: matchList,
  };

  const handleFilterChange = (e) => {
    // dispatch(getMatchsByTournois(e.target.value));
    console.log(e.target.value);
    setTournamentId(e.target.value);
  };

  const refToPrint = useRef();
  const r = useReactToPrint({
    content: () => refToPrint.current,
  });

  const handlePrint = async () => {
    await setPreview(true);
    r();
    // setPreview(false);
  };

  return (
    <div className="header pb-6">
      <div className="container-fluid">
        <div className="header-body">
          <div className="row align-items-center py-4">
            <div className="col-lg-12">
              {/* <h6 className="h2 d-inline-block text-uppercase mb-0">
                <span className="">
                  <i className="fa fa-list fa-lg mr-3"></i>
                </span>
                {lang?.match_list}
              </h6> */}
              {loggedInUser
                ? hasPermission(loggedInUser, ['ADD_MATCH']) && (
                    <h6
                      style={{ float: 'right' }}
                      className="h2 d-inline-block text-uppercase mb-0"
                    >
                      <Link
                        to="/admin/match-form"
                        style={{ backgroundColor: '#19a0c2', color: '#fff' }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-plus fa-lg"></i>{' '}
                      </Link>
                    </h6>
                  )
                : null}
              {loggedInUser
                ? hasPermission(loggedInUser, ['PRINT_MATCH']) && (
                    <div className="dropdown float-right">
                      <button
                        className="btn btn-sm"
                        style={{ backgroundColor: '#343a3f', color: '#fff' }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-print"></i>
                      </button>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          onClick={() => setPreview((prev) => !prev)}
                        >
                          <span>
                            <i className="fa fa-eye mr-1"></i>{' '}
                            {preview
                              ? lang?.hide_preview ?? 'Hide PReview'
                              : lang?.preview_print ?? 'Preview print'}
                          </span>
                        </button>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={handlePrint}>
                          <span>
                            <i className="fa fa-print mr-1"></i> {lang?.print}
                          </span>
                        </button>
                      </div>
                    </div>
                  )
                : null}
            </div>
          </div>

          <div className="d-flex align-items-center">
            {tournamentList.length !== 0 ? (
              <Dropdown
                title={lang?.filter_by_tournament}
                options={tournamentList}
                dynamic={true}
                onChange={handleFilterChange}
              />
            ) : null}
            <button
              className="btn btn-sm"
              style={{ height: 40, marginTop: '30px' }}
              onClick={() => setTournamentId('')}
            >
              {' '}
              reset{' '}
            </button>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card card-stats">
                {matchLoading ? (
                  <Loader />
                ) : (
                  <div ref={refToPrint}>
                    <DatatableCard
                      tableData={{
                        ...tableData,
                        columns: preview ? columnsPrint : columns,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} centered size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Team Formation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Set Team A</Accordion.Header>
                <Accordion.Body>
                  <div className="row justify-content-between mb-3">
                    <div className="col-md-6">
                      <button
                        className="btn btn-default-outlined btn-sm w-100"
                        style={{
                          backgroundColor:
                            teamA?.id === selectedMatch._participantOne?.id
                              ? '#17bab8'
                              : 'white',
                          color:
                            teamA?.id === selectedMatch._participantOne?.id
                              ? 'white'
                              : 'black',
                        }}
                        onClick={() => setTeamA(selectedMatch._participantOne)}
                      >
                        {selectedMatch._participantOne?.name}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-default-outlined btn-sm w-100"
                        style={{
                          backgroundColor:
                            teamA?.id === selectedMatch._participantTwo?.id
                              ? '#17bab8'
                              : 'white',
                          color:
                            teamA?.id === selectedMatch._participantTwo?.id
                              ? 'white'
                              : 'black',
                        }}
                        onClick={() => setTeamA(selectedMatch._participantTwo)}
                      >
                        {selectedMatch._participantTwo?.name}
                      </button>
                    </div>
                  </div>
                  <small style={{ color: '#17bab8', marginLeft: 5 }}>
                    Nb:{' '}
                    <b>
                      {teamA?.id === selectedMatch._participantTwo?.id
                        ? selectedMatch._participantOne?.name
                        : selectedMatch._participantTwo?.name}
                    </b>{' '}
                    will start the match as TEAM B
                  </small>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Kick-off team</Accordion.Header>
                <Accordion.Body>
                  <div className="row justify-content-between mb-3">
                    <div className="col-md-6">
                      <button
                        className="btn btn-default-outlined btn-sm w-100"
                        style={{
                          backgroundColor:
                            teamLabel?.id === selectedMatch._participantOne?.id
                              ? '#17bab8'
                              : 'white',
                          color:
                            teamLabel?.id === selectedMatch._participantOne?.id
                              ? 'white'
                              : 'black',
                        }}
                        onClick={() =>
                          setTeamLabel(selectedMatch._participantOne)
                        }
                      >
                        {selectedMatch._participantOne?.name}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-default-outlined btn-sm w-100"
                        style={{
                          backgroundColor:
                            teamLabel?.id === selectedMatch._participantTwo?.id
                              ? '#17bab8'
                              : 'white',
                          color:
                            teamLabel?.id === selectedMatch._participantTwo?.id
                              ? 'white'
                              : 'black',
                        }}
                        onClick={() =>
                          setTeamLabel(selectedMatch._participantTwo)
                        }
                      >
                        {selectedMatch._participantTwo?.name}
                      </button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Players on court</Accordion.Header>
                <Accordion.Body style={{ margin: 0, padding: 0 }}>
                  <TeamHeader selectedMatch={selectedMatch} />
                  <div className="row">
                    <div className="col-md-6">
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>Position</th>
                            <th>Player</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[1, 2, 3, 4, 5, 6].map((value, index) => (
                            <MatchPlayerRow
                              key={index}
                              position={value}
                              players={Object.values(
                                roster.playerSelection || {}
                              ).filter(
                                ({ clubID }) =>
                                  clubID === selectedMatch._participantOne?.id
                              )}
                              type="onCourt"
                              onDeselect={onPlayerSelect}
                              playerSelection={playerSelection}
                              onPlayerSelect={onPlayerSelect}
                              captainSelection={roster?.captainSelection}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-md-6">
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>Position</th>
                            <th>Player</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[1, 2, 3, 4, 5, 6].map((value, index) => (
                            <MatchPlayerRow
                              key={index}
                              position={value}
                              players={Object.values(
                                roster.playerSelection || {}
                              ).filter(
                                ({ clubID }) =>
                                  clubID === selectedMatch._participantTwo?.id
                              )}
                              type="onCourt"
                              onDeselect={onPlayerSelect}
                              playerSelection={playerSelection}
                              onPlayerSelect={onPlayerSelect}
                              captainSelection={roster?.captainSelection}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>Players on bench</Accordion.Header>
                <Accordion.Body style={{ margin: 0, padding: 0 }}>
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <button
                      className="btn btn-sm btn-outline-primary"
                      onClick={putAllBench}
                    >
                      Put remaining players on bench
                    </button>
                  </div>
                  <TeamHeader selectedMatch={selectedMatch} />
                  <div className="row">
                    <div className="col-md-6">
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Player</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(playerSelection)
                            .filter(
                              (player) =>
                                player.clubID ===
                                  selectedMatch._participantOne?.id &&
                                player.type === 'replacement'
                            )
                            .map((value, index) => (
                              <MatchPlayerRow
                                key={index}
                                position={index + 1}
                                players={Object.values(
                                  roster.playerSelection || {}
                                ).filter(
                                  ({ clubID }) =>
                                    clubID === selectedMatch._participantOne?.id
                                )}
                                type="replacement"
                                onDeselect={onPlayerSelect}
                                playerID={JSON.stringify(value)}
                                playerSelection={playerSelection}
                                onPlayerSelect={onPlayerSelect}
                                captainSelection={roster?.captainSelection}
                              />
                            ))}
                        </tbody>
                      </Table>
                      {Object.values(playerSelection).filter(
                        (player) =>
                          player.clubID === selectedMatch._participantOne?.id &&
                          player.type === 'replacement'
                      ).length > 8 ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <small className="text-danger">
                            You can only have 8 players on bench
                          </small>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Player</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(playerSelection)
                            .filter(
                              (player) =>
                                player.clubID ===
                                  selectedMatch._participantTwo?.id &&
                                player.type === 'replacement'
                            )
                            .map((value, index) => (
                              <MatchPlayerRow
                                key={index}
                                position={index + 1}
                                players={Object.values(
                                  roster.playerSelection || {}
                                ).filter(
                                  ({ clubID }) =>
                                    clubID === selectedMatch._participantTwo?.id
                                )}
                                type="replacement"
                                onDeselect={onPlayerSelect}
                                playerID={JSON.stringify(value)}
                                playerSelection={playerSelection}
                                onPlayerSelect={onPlayerSelect}
                                captainSelection={roster?.captainSelection}
                              />
                            ))}
                        </tbody>
                      </Table>
                      {Object.values(playerSelection).filter(
                        (player) =>
                          player.clubID === selectedMatch._participantTwo?.id &&
                          player.type === 'replacement'
                      ).length > 8 ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <small className="text-danger">
                            You can only have 8 players on bench
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm" onClick={handleClose}>
              Close
            </button>
            <button
              className="btn btn-sm"
              onClick={startMatch}
              disabled={canStartMatch()}
              style={{ backgroundColor: '#369688', color: 'white' }}
            >
              Start match
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Match;
